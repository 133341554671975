import { useEffect, useState } from "react";
import Search from "../search";
import "./sideSearchBar.scss";
import SmallArrowUp from "../../icons/smallUpArrow";
import SmallArrowDown from "../../icons/smallDownArrow";
import { capitalizeWords } from "../../../helpers/capitalize";

const SideSearchBar = ({
  searchQuery,
  setSearchQuery,
  NavigationData,
  SetNavigationClicked,
  testDetails,
  testsNavigation,
  setTestNavigation,
  viewClassName,
}) => {
  const [filterSearchData, setFilterSearchData] = useState(NavigationData);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (searchQuery?.length) {
      // Filter data based on search query
      const filteredOptions = NavigationData?.filter((option) =>
        option?.toLowerCase()?.includes(searchQuery?.toLowerCase()),
      );
      setFilterSearchData(filteredOptions);
    } else {
      setFilterSearchData(NavigationData);
    }
  }, [searchQuery]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (testDetails) {
      let globalIndex = 1;

      // Extracting name, testNo, and adding global index
      const result = Object.keys(testDetails).map((key) => {
        return testDetails[key].map((item) => ({
          globalIndex: globalIndex++, // Assign global index and increment
          name: item.name,
        }));
      });

      const flattenedResult = result.flat();
      setTestNavigation(flattenedResult);
    }
  }, [testDetails]);

  return (
    <>
      <div
        className={`sidebar-container-mbr ${
          viewClassName ? viewClassName : ""
        }`}
      >
        <div className="side-bar-top-icon" onClick={toggleSidebar}>
          <span className="side-bar-top-inner-icon-mbr">
            <SmallArrowUp />
          </span>
        </div>
        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <div className="close-button" onClick={toggleSidebar}>
            <span>
              <SmallArrowDown />
            </span>{" "}
            {/* Icon to close the sidebar */}
          </div>
          <div className="sidebarSearch sidebar-content">
            <div className="navigation">Navigation</div>
            <div className="searchWrap">
              <Search
                placeholder="Search by Header"
                onSearchEmit={(option) => {
                  setSearchQuery(option);
                }}
                value={searchQuery}
              />
            </div>
            <div className="navigationWrap">
              {filterSearchData?.map((item) => {
                return (
                  <div>
                    <label
                      className="navigationName"
                      onClick={(e) => {
                        SetNavigationClicked(e.target.innerText);
                      }}
                    >
                      {item}
                    </label>
                  </div>
                );
              })}
              {testsNavigation?.map((item) => {
                return (
                  <div>
                    <label
                      className="navigationName"
                      onClick={(e) => {
                        SetNavigationClicked(e.target.innerText);
                      }}
                    >
                      Test No {item?.globalIndex + " "}
                      {capitalizeWords(item?.name)}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SideSearchBar;
