import FormHeading from "../../../atoms/FormHeading";
import VerifyOTPForm from "./Form";

const SurveyVerifyOTP = () => {
  return (
    <>
      <FormHeading
        title="2-Step Verification"
        description="Enter the verification code we sent you in your email. It will be valid for 5 minutes. If you need to resend a new code, please wait for 30 seconds."
      />
      <VerifyOTPForm />
    </>
  );
};
export default SurveyVerifyOTP;
