import "./style.scss";
const TextInput = ({
  label,
  icon,
  InputHeight = "small",
  info,
  customBlur,
  blurError,
  width = "100%",
  name,
  customholder,
  className = "",
  onChange,
  value,
  disabled,
}) => {
  return (
    <>
      <div style={{ width: width }} className={className}>
        {label && <label htmlFor={name}>{label}</label>}
        <div className={`d-flex align-center input-container input_icon_wrap`}>
          {icon && <div className={`input_icon`}>{icon}</div>}
          <input
            className={`${blurError ? "error-input " : ""}${
              InputHeight === "small" ? "primary-input-small" : "primary-input"
            } `}
            placeholder={customholder}
            name={name}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};
export default TextInput;
