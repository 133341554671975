import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "./index.module.scss";
import { dashboardRoute } from "../../../routes/routes";
import Sidebar from "../../molecules/Sidebar";

const DashboardLayout = () => {
  const [isActive, setIsActive] = useState(true);
  return (
    <div className={styles.dashboard_container}>
      <Sidebar isActive={isActive} setIsActive={setIsActive} />
      <div className={styles.main_content_container}>
        {/* <Navbar /> */}
        <div
          className={styles.content}
          style={{ width: isActive ? `calc(100vw - 270px)` : "auto" }}
        >
          <Outlet />
          {dashboardRoute}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
