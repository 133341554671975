import moment from "moment";
export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  // Function to determine the suffix (st, nd, rd, th) based on the day
  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // Handle 11th to 20th as 'th'
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Get the correct suffix for the day
  const suffix = getDaySuffix(day);

  // Return the formatted date as "12th September 2024"
  return `${day}${suffix} ${month} ${year}`;
};

export const convertDateToDDMMYYYY = (isoDate) => {
  const date = new Date(isoDate);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Return the formatted date as dd-mm-yyyy
  return `${day}-${month}-${year}`;
};

// Example usage
const isoDate = "2024-09-19T07:28:15.472Z";
console.log(convertDateToDDMMYYYY(isoDate)); // Output: 19-09-2024

export const formatDateToISO = (date) => {
  // Use the Date object to format the date to ISO string with milliseconds
  const formattedDate = new Date(date).toISOString();
  return formattedDate;
};

export const formatToDefault = (date) => {
  const formattedDate = moment(date).format("YYYY-MM-DD");
  return formattedDate;
};

export const formatToDateTime = (date) => {
  const formattedDate = moment(date).format("D MMM YYYY | h:mm A");
  return formattedDate;
};

export const formatTimeStamp = (date) => {
  const formattedTimestamp = moment(date).format("YYYY-MM-DDTHH:mm");
  return formattedTimestamp;
};
