import React, { useEffect, useState } from "react";
import Accordion from "../../../../molecules/accordion";
import Info from "../../../../icons/Info";
import VesselInformation from "./vesselInformation";
import Ship from "../../../../icons/Ship";
import Picture from "../../../../icons/Picture";
import Document from "../../../../icons/Document";
import Tools from "../../../../icons/Tools";
import Tree from "../../../../icons/Tree";
import Chip from "../../../../icons/Chip";
import VesselMachinery from "./vesselMachinery";
import DPControlSystem from "./dpControlSystems";
import RedundancyConcept from "./redundancyConcept";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import { getData, patchData, postData } from "../../../../../services";
import { useCookies } from "react-cookie";
import Disk from "../../../../icons/Disk";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import { useParams } from "react-router-dom";
import VesselTrials from "./VesselTrials";

const AddVessels = () => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [vesselData, setVesselData] = useState({});

  // State for Vessel Information
  const [vesselInformation, setVesselInformation] = useState({
    vesselName: "",
    ownerOperator: "",
    industrialMission: "",
    imoNumber: "",
    imoClass: "",
    classificationSociety: "",
    dpClass: "",
    flag: "",
    anniversaryDate: null,
    yearOfConstruction: "",
    lengthOverall: "",
    tonnage: "",
    draught: "",
  });

  // State for vessel DP Control System
  const [dPControlSystemRows, setDPControlSystemRows] = useState([
    {
      control_system: "",
      model: "",
    },
    {
      control_system: "",
      model: "",
    },
    {
      control_system: "",
      model: "",
    },
  ]);

  const [vesselMachineryRows, setVesselMachineryRows] = useState([
    {
      machinery: "",
      power: "",
    },
    {
      machinery: "",
      power: "",
    },
    {
      machinery: "",
      power: "",
    },
  ]);

  useEffect(() => {
    setVesselInformation((prev) => ({
      ...prev, // Spread the previous state to retain existing values
      vesselName: vesselData?.vessel_name || "",
      ownerOperator: vesselData?.owner || "",
      industrialMission: vesselData?.industrial_mission || "",
      imoNumber: vesselData?.imo_number || "",
      imoClass: vesselData?.imo_dp_equipment_class || "",
      classificationSociety: vesselData?.classification_society || "",
      dpClass: vesselData?.dp_class || "",
      flag: vesselData?.flag || "",
      anniversaryDate: vesselData?.anniversary_date || null,
      yearOfConstruction: vesselData?.year_of_construction || "",
      lengthOverall: vesselData?.length_over_all || "",
      tonnage: vesselData?.tonnage || "",
      draught: vesselData?.draught || "",
    }));
    if (vesselData?.vessel_machinery) {
      setVesselMachineryRows(
        vesselData?.vessel_machinery.map((row) => ({
          machinery: row.machinery,
          power: row.power,
        })),
      );
    }
    if (vesselData?.dp_control_systems) {
      setDPControlSystemRows(
        vesselData?.dp_control_systems.map((row) => ({
          control_system: row.control_system,
          model: row.model,
        })),
      );
    }
  }, [vesselData]);

  console.log("dp_control_systems--->", dPControlSystemRows);
  console.log("vesselMachineryRows----->", vesselMachineryRows);
  console.log("Vesseldata---->", vesselData);

  // State for Vessel Machinery
  //   const [vesselMachineryRows, setVesselMachineryRows] = useState([
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //   ]);

  //   const [dPControlSystemRows, setDPControlSystemRows] = useState([
  //     {
  //       instrument: "4X Caterpillar 3516C",
  //       manufacturer: "Kongsberg Maritime",
  //     },
  //     {
  //       instrument: "4X Caterpillar 3516C",
  //       manufacturer: "Kongsberg Maritime",
  //     },
  //     {
  //       instrument: "4X Caterpillar 3516C",
  //       manufacturer: "Kongsberg Maritime",
  //     },
  //     {
  //       instrument: "4X Caterpillar 3516C",
  //       manufacturer: "Kongsberg Maritime",
  //     },
  //   ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      vessel_name: vesselInformation.vesselName,
      owner: vesselInformation.ownerOperator,
      industrial_mission: vesselInformation.industrialMission,
      imo_number: parseInt(vesselInformation.imoNumber, 10),
      imo_dp_equipment_class: vesselInformation.imoClass,
      classification_society: vesselInformation.classificationSociety,
      dp_class: vesselInformation.dpClass,
      flag: vesselInformation.flag,
      anniversary_date: vesselInformation.anniversaryDate,
      year_of_construction: parseInt(vesselInformation.yearOfConstruction, 10),
      length_over_all: parseFloat(vesselInformation.lengthOverall),
      tonnage: parseFloat(vesselInformation.tonnage),
      draught: parseFloat(vesselInformation.draught),
      vessel_machinery: vesselMachineryRows,
      dp_control_systems: dPControlSystemRows,
    };

    if (id) {
      try {
        // Make API call using postData
        const result = await patchData({
          endpoint: "vessel/update",
          data: payload,
          params: { id: id },
          token: cookies.t,
        });

        console.log("result----->", result?.data?.data?.id);

        if (result) {
          toast.success(`Vessel Updated successfully`, {
            style: confimationStyles,
            duration: 2000,
          });

          fetchVesselData(result?.data?.data?.id);
        }

        console.log("API Response:", result);
      } catch (error) {
        console.error("Error submitting vessel information:", error);
        //   toast.error("Test data not found");
        toast.error(error, {
          style: errorStyles,
          duration: 1000,
        });
      }
    } else {
      try {
        // Make API call using postData
        const result = await postData({
          endpoint: "vessel/create",
          data: payload,
          params: {},
          token: cookies.t,
        });

        console.log("result----->", result?.data?.data?.id);

        if (result) {
          toast.success(`Vessel added successfully`, {
            style: confimationStyles,
            duration: 2000,
          });

          //   fetchVesselData(result?.data?.data?.id);
        }

        console.log("API Response:", result);
      } catch (error) {
        console.error("Error submitting vessel information:", error);
        //   toast.error("Test data not found");
        toast.error(error, {
          style: errorStyles,
          duration: 1000,
        });
      }
    }
  };

  const fetchVesselData = async () => {
    console.log("id------>", id);
    try {
      const res = await getData({
        endpoint: "vessel/getVesselDetailsById",
        token: cookies.t,
        params: { id: id },
      });
      setVesselData(res?.data || {});
    } catch (err) {
      console.log("Error fetching vessel data:", err);
      setVesselData({}); // Ensure vesselData is empty on error
    }
  };

  useEffect(() => {
    if (id) {
      fetchVesselData();
    }
  }, []);

  const accordionData1 = [
    {
      title: "Vessel Information",
      icon: <Ship />,
      content: (
        <VesselInformation
          vesselInformation={vesselInformation}
          setVesselInformation={setVesselInformation}
        />
      ),
    },
    {
      title: "Vessel Photo",
      icon: <Picture />,
      content: <p>Hiiii</p>,
    },
    {
      title: "Redundancy Concept",
      icon: <Tree />,
      content: <RedundancyConcept />,
      removePadding: true,
    },
    {
      title: "Vessel Documents",
      icon: <Document />,
      content: <p>Hiiii</p>,
    },
    {
      title: "Vessel Machinery",
      icon: <Tools />,
      content: (
        <VesselMachinery
          vesselMachineryRows={vesselMachineryRows}
          setVesselMachineryRows={setVesselMachineryRows}
        />
      ),
    },
    {
      title: "DP Control Systems",
      icon: <Chip />,
      content: (
        <DPControlSystem
          dPControlSystemRows={dPControlSystemRows}
          setDPControlSystemRows={setDPControlSystemRows}
        />
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center", // Vertically centers the items
          justifyContent: "space-between", // Spaces out items
          padding: "10px", // Optional: Add some padding around the container
          //   backgroundColor: "#f8f9fa", // Optional: Add a background color
          borderRadius: "4px", // Optional: Add rounded corners
          marginBottom: "5px",
          //   backgroundColor: "#f2f2f2",s
        }}
      >
        <Breadcrumb
          labels={["Vessels", id ? `${vesselData?.vessel_name}` : "Add Vessel"]}
        />

        <button
          onClick={handleSubmit}
          style={{
            ...styles.submitButton,
            marginTop: "0px !important",
          }}
        >
          {/* <Disk /> */}
          Save
        </button>
      </div>

      <Accordion items={accordionData1} />
      <VesselTrials />
    </>
  );
};
const styles = {
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default AddVessels;
