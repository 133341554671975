import React, { useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useSurveyAuthenticated } from "./shareGuard";
import { useCookies } from "react-cookie";

const ShareAuthGuard = (props) => {
  const { children } = props;
  const auth = useSurveyAuthenticated();
  const location = useLocation();
  const [cookie] = useCookies(["survey_id"]);
  const [requestedLocation, setRequestedLocation] = useState();

  if (!auth) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to={`/survey/auth/${cookie.survey_id}`} />;
  }
  return <>{children}</>;
};

export default ShareAuthGuard;
