import React from 'react'
import Info from '../../icons/Info'
import styles from './info.module.scss'

const InfoContainer = ({info, type = 'small'}) => {
  return (
    <div className={`${styles.info_div} d-flex align-items-center ${type === 'small' ? styles.small_info : ''}`}>
        {/* <Info/> */}
        {info}
    </div>
  )
}

export default InfoContainer