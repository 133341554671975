// import React, { useState } from "react";
// import "./style.scss";
// import ArrowDown from "../../icons/ArrowDown"; // Ensure you import the icon

// const SimpleAccordionItem = ({
//   title,
//   icon,
//   children,
//   isOpen,
//   toggle,
//   count,
// }) => {
//   return (
//     <div className="simple-accordion-item">
//       <div className="simple-accordion-header" onClick={toggle}>
//         <span style={{ paddingLeft: "8px" }}>
//           {title}{" "}
//           {count !== undefined && <span className="badge">{count}</span>}
//         </span>

//         <span className={isOpen ? "rotate" : ""} style={{ marginRight: "5px" }}>
//           <ArrowDown />
//         </span>
//       </div>
//       {isOpen && <div className="simple-accordion-content">{children}</div>}
//     </div>
//   );
// };

// // Simple Accordion Component
// const SimpleAccordion = ({ items }) => {
//   const [openIndex, setOpenIndex] = useState(null);

//   // Function to toggle accordion item without auto-closing
//   const toggleAccordion = (index) => {
//     // If the currently clicked index is open, close it, otherwise set it as open
//     setOpenIndex(openIndex === index ? null : index);
//   };

//   return (
//     <div className="simple-accordion">
//       {items.map((item, index) => (
//         <SimpleAccordionItem
//           key={index}
//           title={item.title}
//           count={item.count} // Pass the dynamic count
//           isOpen={openIndex === index}
//           toggle={() => toggleAccordion(index)}
//         >
//           {item.content}
//         </SimpleAccordionItem>
//       ))}
//     </div>
//   );
// };

// export default SimpleAccordion;

import React, { useState } from "react";
import "./style.scss";
import ArrowDown from "../../icons/ArrowDown"; // Ensure you import the icon

const SimpleAccordionItem = ({
  title,
  icon,
  children,
  isOpen,
  toggle,
  count,
}) => {
  return (
    <div className="simple-accordion-item">
      <div className="simple-accordion-header" onClick={toggle}>
        <span style={{ paddingLeft: "8px" }}>
          {title}{" "}
          {count !== undefined && <span className="badge">{count}</span>}
        </span>

        <span className={isOpen ? "rotate" : ""} style={{ marginRight: "5px" }}>
          <ArrowDown />
        </span>
      </div>
      {isOpen && <div className="simple-accordion-content">{children}</div>}
    </div>
  );
};

// Simple Accordion Component
const SimpleAccordion = ({ items }) => {
  const [openIndices, setOpenIndices] = useState([]); // Array to track open accordion items

  // Function to toggle accordion item independently
  const toggleAccordion = (index) => {
    // If index is already in the openIndices array, remove it (close), otherwise add it (open)
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index)); // Close the accordion item
    } else {
      setOpenIndices([...openIndices, index]); // Open the accordion item
    }
  };

  return (
    <div className="simple-accordion">
      {items.map((item, index) => (
        <SimpleAccordionItem
          key={index}
          title={item.title}
          count={item.count} // Pass the dynamic count
          isOpen={openIndices.includes(index)} // Check if this accordion item is open
          toggle={() => toggleAccordion(index)}
        >
          {item.content}
        </SimpleAccordionItem>
      ))}
    </div>
  );
};

export default SimpleAccordion;
