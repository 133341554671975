import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../molecules/footer";

import styles from "./surveyLayout.module.scss";
import { surveyRoute } from "../../../routes/routes";

const SurveyLayout = () => {
  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboard_body}>
        <Outlet />
        {surveyRoute}
      </div>
      <Footer />
    </div>
  );
};

export default SurveyLayout;
