import React, { useState, useRef } from "react";
import Select from "react-select";
import "./styles.scss";
import { DateSelector } from "../../molecules/MonthYearSelector";
import { convertDateToDDMMYYYY, formatDate } from "../../../helpers/formatDate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Delete from "../../icons/Delete";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../assets/styles/toast";
import CustomButton from "../../atoms/CustomButton";
import {
  testData,
  testOptions,
  staticTableData,
} from "../../../helpers/staticData";
import { capitalize, capitalizeWords } from "../../../helpers/capitalize";

const FMEATest = ({
  roleType,
  setTestDetails,
  testDetails,
  tableData,
  setTableData,
  testsNavigation,
}) => {
  // Create refs for ReactQuill editors
  const methodQuillRef = useRef(null);
  const resultsExpectedQuillRef = useRef(null);
  const actualResultsQuillRef = useRef(null);
  const commentQuillRef = useRef(null);

  const [selectedTestOption, setSelectedTestOption] = useState(null);

  const DEFAULT_ROW_COUNT = 4;

  const handleTestChange = (selectedOption) => {
    if (selectedOption) {
      const { value } = selectedOption;

      if (testData[value]) {
        const newTestDetail = JSON.parse(JSON.stringify(testData[value][0]));
        const currentTests = testDetails[value] || [];
        newTestDetail.testNo = `Test No. ${currentTests.length + 1}`;

        setTestDetails((prevDetails) => ({
          ...prevDetails,
          [value]: [...(prevDetails[value] || []), newTestDetail],
        }));

        // Initialize table data
        const newTableData = {};
        ["method", "expected", "actual", "comments"].forEach((section) => {
          if (
            newTestDetail[
              `includeTable${
                section.charAt(0).toUpperCase() + section.slice(1)
              }`
            ]
          ) {
            const headers = newTestDetail.tableHeaders[section];
            if (Array.isArray(headers?.[0])) {
              // Multiple tables
              headers.forEach((headerSet, tableIndex) => {
                newTableData[`${section}-${tableIndex}`] = {
                  headers: headerSet,
                  // rows: Array(DEFAULT_ROW_COUNT)
                  //   .fill()
                  //   .map(() => Array(headerSet?.length).fill("")),
                  rows:
                    staticTableData[value]?.[section]?.[
                      `${section}-${tableIndex}`
                    ]?.rows ||
                    Array(DEFAULT_ROW_COUNT)
                      .fill()
                      .map(() => Array(headerSet?.length).fill("")),
                };
              });
            } else {
              // Single table
              newTableData[section] = {
                headers,
                // rows: Array(DEFAULT_ROW_COUNT)
                //   .fill()
                //   .map(() => Array(headers?.length).fill("")),
                rows:
                  staticTableData[value]?.[section]?.rows ||
                  Array(DEFAULT_ROW_COUNT)
                    .fill()
                    .map(() => Array(headers?.length).fill("")),
              };
            }
          }
        });

        setTableData((prevTableData) => ({
          ...prevTableData,
          [`${value}-${currentTests.length}`]: newTableData,
        }));

        toast.success(`Test added successfully`, {
          style: confimationStyles,
          duration: 1000,
        });

        // Clear the selected option
        setSelectedTestOption(null); // Clear selected option state
      } else {
        toast.error("Test data not found");
      }
    }
  };
  const handleRemoveTest = (testType, index) => {
    setTestDetails((prevDetails) => ({
      ...prevDetails,
      [testType]: prevDetails[testType].filter((_, i) => i !== index),
    }));

    setTableData((prevTableData) => {
      const newTableData = { ...prevTableData };
      delete newTableData[`${testType}-${index}`];
      return newTableData;
    });
  };

  const handleInputChange = (testType, index, field, value) => {
    setTestDetails((prevDetails) => ({
      ...prevDetails,
      [testType]: prevDetails[testType].map((test, i) =>
        i === index ? { ...test, [field]: value } : test,
      ),
    }));
  };

  const handleCheckboxChange = (testType, index) => {
    setTestDetails((prevDetails) => {
      const updatedTests = prevDetails[testType].map((test, i) => {
        if (i === index) {
          const isChecked = !test.isSameAsExpected;
          return {
            ...test,
            isSameAsExpected: isChecked,
            actualResults: isChecked ? test.resultsExpected : "",
          };
        }
        return test;
      });

      return {
        ...prevDetails,
        [testType]: updatedTests,
      };
    });
  };

  const handleDateChange = (date, testType, index, dateField) => {
    setTestDetails((prevDetails) => ({
      ...prevDetails,
      [testType]: prevDetails[testType].map((test, i) =>
        i === index ? { ...test, [dateField]: date ? date : "" } : test,
      ),
    }));
  };

  const handleTableChange = (
    testType,
    testIndex,
    section,
    tableIndex,
    rowIndex,
    colIndex,
    value,
  ) => {
    setTableData((prevTableData) => {
      const key = `${testType}-${testIndex}`;
      const sectionKey =
        tableIndex !== undefined ? `${section}-${tableIndex}` : section;
      const currentTable = prevTableData[key][sectionKey];
      const newRows = currentTable.rows.map((row, rIndex) =>
        rIndex === rowIndex
          ? row.map((cell, cIndex) => (cIndex === colIndex ? value : cell))
          : row,
      );
      return {
        ...prevTableData,
        [key]: {
          ...prevTableData[key],
          [sectionKey]: {
            ...currentTable,
            rows: newRows,
          },
        },
      };
    });
  };

  const addTableRow = (testType, testIndex, section, tableIndex) => {
    setTableData((prevTableData) => {
      const key = `${testType}-${testIndex}`;
      const sectionKey =
        tableIndex !== undefined ? `${section}-${tableIndex}` : section;
      const currentTable = prevTableData[key][sectionKey];
      const newRow = new Array(currentTable.headers.length).fill("");
      return {
        ...prevTableData,
        [key]: {
          ...prevTableData[key],
          [sectionKey]: {
            ...currentTable,
            rows: [...currentTable.rows, newRow],
          },
        },
      };
    });
  };

  const removeTableRow = (
    testType,
    testIndex,
    section,
    tableIndex,
    rowIndex,
  ) => {
    setTableData((prevTableData) => {
      const key = `${testType}-${testIndex}`;
      const sectionKey =
        tableIndex !== undefined ? `${section}-${tableIndex}` : section;
      const currentTable = prevTableData[key][sectionKey];
      const newRows = currentTable.rows.filter(
        (_, index) => index !== rowIndex,
      );
      return {
        ...prevTableData,
        [key]: {
          ...prevTableData[key],
          [sectionKey]: {
            ...currentTable,
            rows: newRows,
          },
        },
      };
    });
  };

  const addTableColumn = (testType, testIndex, section, tableIndex) => {
    setTableData((prevTableData) => {
      const key = `${testType}-${testIndex}`;
      const sectionKey =
        tableIndex !== undefined ? `${section}-${tableIndex}` : section;
      const currentTable = prevTableData[key][sectionKey];
      const newHeaders = [
        ...currentTable.headers,
        `Column ${currentTable.headers.length + 1}`,
      ];
      const newRows = currentTable.rows.map((row) => [...row, ""]);
      return {
        ...prevTableData,
        [key]: {
          ...prevTableData[key],
          [sectionKey]: {
            headers: newHeaders,
            rows: newRows,
          },
        },
      };
    });
  };

  const removeTableColumn = (
    testType,
    testIndex,
    section,
    tableIndex,
    colIndex,
  ) => {
    setTableData((prevTableData) => {
      const key = `${testType}-${testIndex}`;
      const sectionKey =
        tableIndex !== undefined ? `${section}-${tableIndex}` : section;
      const currentTable = prevTableData[key][sectionKey];
      const newHeaders = currentTable.headers.filter(
        (_, index) => index !== colIndex,
      );
      const newRows = currentTable.rows.map((row) =>
        row.filter((_, index) => index !== colIndex),
      );
      return {
        ...prevTableData,
        [key]: {
          ...prevTableData[key],
          [sectionKey]: {
            headers: newHeaders,
            rows: newRows,
          },
        },
      };
    });
  };

  const handleHeaderChange = (
    testType,
    testIndex,
    section,
    tableIndex,
    colIndex,
    value,
  ) => {
    setTableData((prevTableData) => {
      const key = `${testType}-${testIndex}`;
      const sectionKey =
        tableIndex !== undefined ? `${section}-${tableIndex}` : section;
      const currentTable = prevTableData[key][sectionKey];
      const newHeaders = currentTable.headers.map((header, index) =>
        index === colIndex ? value : header,
      );
      return {
        ...prevTableData,
        [key]: {
          ...prevTableData[key],
          [sectionKey]: {
            ...currentTable,
            headers: newHeaders,
          },
        },
      };
    });
  };

  const renderTable = (testType, testIndex, section, tableIndex) => {
    console.log("Rendering table with parameters:", {
      testType,
      testIndex,
      section,
      tableIndex,
    });

    const key = `${testType}-${testIndex}`;
    const sectionKey =
      tableIndex !== undefined ? `${section}-${tableIndex}` : section;

    console.log("Constructed key and sectionKey:", { key, sectionKey });

    const currentTable = tableData[key]?.[sectionKey];

    if (!currentTable) {
      console.warn("No table found for the constructed key and sectionKey");
      return null;
    }

    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ margin: "0px auto", padding: "30px" }}>
          <table className="editable-table">
            <thead>
              <tr>
                {currentTable?.headers?.map((header, colIndex) => (
                  <th key={colIndex} style={thStyle}>
                    {roleType === "Admin" ? (
                      <input
                        type="text"
                        value={header}
                        onChange={(e) =>
                          handleHeaderChange(
                            testType,
                            testIndex,
                            section,
                            tableIndex,
                            colIndex,
                            e.target.value,
                          )
                        }
                        style={{ width: "100%" }}
                      />
                    ) : (
                      header
                    )}
                    {roleType === "Admin" && (
                      <div style={{ display: "flex", width: "100%" }}>
                        <div
                          style={{
                            alignItems: "center",
                            cursor: "pointer",
                            padding: "5px",
                            margin: "0px auto",
                          }}
                          onClick={() =>
                            removeTableColumn(
                              testType,
                              testIndex,
                              section,
                              tableIndex,
                              colIndex,
                            )
                          }
                        >
                          <Delete />
                        </div>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentTable.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>
                      <input
                        type="text"
                        value={cell}
                        onChange={(e) =>
                          handleTableChange(
                            testType,
                            testIndex,
                            section,
                            tableIndex,
                            rowIndex,
                            cellIndex,
                            e.target.value,
                          )
                        }
                        readOnly={
                          roleType !== "Admin" && roleType !== "Surveyor"
                        }
                        style={{ width: "100%" }}
                      />
                    </td>
                  ))}
                  {roleType === "Admin" && (
                    <td
                      style={{
                        ...removeTd,
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() =>
                          removeTableRow(
                            testType,
                            testIndex,
                            section,
                            tableIndex,
                            rowIndex,
                          )
                        }
                      >
                        <Delete />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {roleType === "Admin" && (
            <div
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "15px",
                marginBottom: "25px",
              }}
            >
              <CustomButton
                type="btn-primary"
                iconRequired={true}
                handleClick={() => {
                  addTableRow(testType, testIndex, section, tableIndex);
                }}
                style={{ width: "max-content" }}
              >
                Add Row
              </CustomButton>
              <CustomButton
                type="btn-primary"
                iconRequired={true}
                handleClick={() => {
                  addTableColumn(testType, testIndex, section, tableIndex);
                }}
                style={{ width: "max-content" }}
              >
                Add Column
              </CustomButton>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Initialize a variable to keep track of the global index
  let globalIndex = 1;

  return (
    <>
      <div className="overview-heading" style={{ marginTop: "10px" }}>
        {/* Tests */}
      </div>
      <div
        style={{
          width: "100%",
          padding: "0px",
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {Object?.entries(testDetails)?.map(([testType, tests]) => (
          <div key={testType}>
            {tests.map((testDetail, index) => {
              // Initialize a variable to keep track of the global index
              // Assign Global Test Number based on rendering order
              console.log("testDetail", testDetail);
              const currentGlobalIndex = globalIndex++;
              return (
                <div key={`${testType}-${index}`}>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => handleRemoveTest(testType, index)}
                    >
                      <Delete />
                    </div>
                  </div>

                  <table
                    id={`Test No ${currentGlobalIndex} ${capitalizeWords(
                      testDetail.name,
                    )}`}
                    className="fmea-table"
                    style={{ marginTop: "10px" }}
                  >
                    <tbody>
                      <tr>
                        <td colSpan="1" className="fmea-header">
                          EQUIPMENT
                        </td>
                        <td colSpan="1" className="fmea-header">
                          {testDetail?.equipment}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2">
                          {/* <label> {testDetail?.testNo} : </label> */}
                          <label> Test No. {currentGlobalIndex} : </label>
                          {roleType === "Admin" ? (
                            <input
                              type="text"
                              value={testDetail?.name}
                              onChange={(e) =>
                                handleInputChange(
                                  testType,
                                  index,
                                  "name",
                                  e.target.value,
                                )
                              }
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <span style={cellStyle}>{testDetail?.name}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <label>Objective:</label>
                          {roleType === "Admin" ? (
                            <input
                              type="text"
                              value={testDetail?.objective}
                              onChange={(e) =>
                                handleInputChange(
                                  testType,
                                  index,
                                  "objective",
                                  e.target.value,
                                )
                              }
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <span style={cellStyle}>
                              {testDetail?.objective}
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <label>FMEA Ref:</label>
                          {roleType === "Admin" ? (
                            <input
                              type="text"
                              value={testDetail?.fmeaRef}
                              onChange={(e) =>
                                handleInputChange(
                                  testType,
                                  index,
                                  "fmeaRef",
                                  e.target.value,
                                )
                              }
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <span style={cellStyle}>{testDetail?.fmeaRef}</span>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2">
                          <label>Method:</label>
                          {roleType === "Admin" ? (
                            <ReactQuill
                              theme="snow"
                              value={testDetail?.method}
                              onChange={(value) =>
                                handleInputChange(
                                  testType,
                                  index,
                                  "method",
                                  value,
                                )
                              }
                              className="custom-quill-editor"
                              style={{
                                backgroundColor: "#f4f4f4",
                              }}
                              ref={methodQuillRef}
                            />
                          ) : (
                            // <span style={cellStyle}>{testDetail?.method}</span>
                            <span
                              style={cellStyle}
                              dangerouslySetInnerHTML={{
                                __html: testDetail?.method,
                              }}
                            />
                          )}
                          {testDetail.includeTableMethod && (
                            <>{renderTable(testType, index, "method")}</>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2">
                          <label>Results Expected:</label>
                          {roleType === "Admin" ? (
                            <ReactQuill
                              theme="snow"
                              value={testDetail?.resultsExpected}
                              onChange={(value) =>
                                handleInputChange(
                                  testType,
                                  index,
                                  "resultsExpected",
                                  value,
                                )
                              }
                              className="custom-quill-editor"
                              style={{ backgroundColor: "#f4f4f4" }}
                              ref={resultsExpectedQuillRef}
                            />
                          ) : (
                            <span style={cellStyle}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: testDetail?.resultsExpected,
                                }}
                              />
                            </span>
                          )}

                          {testDetail.includeTableExpected && (
                            <>{renderTable(testType, index, "expected")}</>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2">
                          <label>Actual Results:</label>

                          <br />
                          <label>
                            <input
                              type="checkbox"
                              checked={testDetail.isSameAsExpected || false}
                              onChange={() =>
                                handleCheckboxChange(testType, index)
                              }
                              style={{ height: "unset", marginRight: "10px" }}
                            />
                            Same as Expected Results
                          </label>
                          <ReactQuill
                            theme="snow"
                            value={testDetail?.actualResults}
                            onChange={(value) =>
                              handleInputChange(
                                testType,
                                index,
                                "actualResults",
                                value,
                              )
                            }
                            className="custom-quill-editor"
                            style={{ backgroundColor: "#f4f4f4" }}
                            ref={actualResultsQuillRef}
                          />
                          {/* </>
                        )} */}

                          {testDetail.includeTableActual && (
                            <>
                              {Array.isArray(testDetail.tableHeaders.actual[0])
                                ? testDetail.tableHeaders.actual.map(
                                    (_, tableIndex) => (
                                      <div key={tableIndex}>
                                        {/* <h5>Table {tableIndex + 1}</h5> */}
                                        {renderTable(
                                          testType,
                                          index,
                                          "actual",
                                          tableIndex || 0,
                                        )}
                                      </div>
                                    ),
                                  )
                                : renderTable(testType, index, "actual")}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <label>Comments:</label>
                          {roleType === "Admin" ? (
                            <span style={cellStyle}>
                              {testDetail?.comments}
                            </span>
                          ) : (
                            <ReactQuill
                              theme="snow"
                              value={testDetail.comments}
                              onChange={(value) =>
                                handleInputChange(
                                  testType,
                                  index,
                                  "comments",
                                  value,
                                )
                              }
                              className="custom-quill-editor"
                              style={{
                                backgroundColor: "#f4f4f4",
                              }}
                              ref={commentQuillRef}
                            />
                          )}
                          {testDetail.includeTableComments && (
                            <>{renderTable(testType, index, "comments")}</>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="1" style={{ width: "50%" }}>
                          <label>Witnessed By:</label>
                          {roleType === "Admin" ? (
                            <span style={cellStyle}>
                              {testDetail?.witnessedBy1}
                            </span>
                          ) : (
                            <input
                              type="text"
                              value={testDetail?.witnessedBy1}
                              onChange={(e) =>
                                handleInputChange(
                                  testType,
                                  index,
                                  "witnessedBy1",
                                  e.target.value,
                                )
                              }
                              style={{ width: "100%" }}
                            />
                          )}
                        </td>
                        <td colSpan="1" style={{ width: "50%" }}>
                          <label>Date:</label>
                          {roleType === "Admin" ? (
                            <span style={cellStyle}>
                              {testDetail?.date1
                                ? convertDateToDDMMYYYY(testDetail?.date1)
                                : null}
                            </span>
                          ) : (
                            <div style={{ position: "relative" }}>
                              <DateSelector
                                placeholder="Select Date"
                                onChange={(option) =>
                                  handleDateChange(
                                    option,
                                    testType,
                                    index,
                                    "date1",
                                  )
                                }
                                selectedDate={testDetail?.date1}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="1" style={{ width: "50%" }}>
                          <label>Witnessed By:</label>
                          {roleType === "Admin" ? (
                            <span style={cellStyle}>
                              {testDetail?.witnessedBy2}
                            </span>
                          ) : (
                            <input
                              type="text"
                              value={testDetail?.witnessedBy2}
                              onChange={(e) =>
                                handleInputChange(
                                  testType,
                                  index,
                                  "witnessedBy2",
                                  e.target.value,
                                )
                              }
                              style={{ width: "100%" }}
                            />
                          )}
                        </td>
                        <td colSpan="1" style={{ width: "50%" }}>
                          <label>Date:</label>
                          {roleType === "Admin" ? (
                            <span style={cellStyle}>
                              {testDetail?.date2
                                ? convertDateToDDMMYYYY(testDetail?.date2)
                                : null}
                            </span>
                          ) : (
                            <div style={{ position: "relative" }}>
                              <DateSelector
                                placeholder="Select Date"
                                onChange={(option) =>
                                  handleDateChange(
                                    option,
                                    testType,
                                    index,
                                    "date2",
                                  )
                                }
                                selectedDate={testDetail?.date2}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        ))}
        {roleType === "Admin" && (
          <>
            <Select
              options={testOptions}
              onChange={handleTestChange}
              placeholder="Select Test Types"
              className="fmea-dropdown"
              isClearable={true}
              closeMenuOnSelect={true}
              value={selectedTestOption}
            />
          </>
        )}
      </div>
    </>
  );
};

export default FMEATest;

const cellStyle = {
  border: "none",
  padding: 0,
  paddingLeft: "5px",
  fontSize: "14px",
};
const removeTd = {
  border: "none",
};
const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  marginBottom: "20px",
};
const tdStyle = {
  border: "1px solid black",
  padding: "8px",
  textAlign: "left",
};

const thStyle = {
  ...tdStyle,
  fontWeight: "bold",
};
const headerStyle = {
  backgroundColor: "#f2f2f2",
  padding: "10px",
  borderBottom: "1px solid black",
  textAlign: "left",
  fontWeight: "bold",
};

// const testOptions = [{ value: "SOFTWARE AUDIT", label: "SOFTWARE AUDIT" }];
// const testData = {
//   "SOFTWARE AUDIT": [
//     {
//       name: "SOFTWARE AUDIT",
//       equipment: "DP  SYSTEM",
//       testNo: "Test No.",
//       objective:
//         "To record the software version installed in all DP related systems at the time of trials.",
//       fmeaRef: "235465688098967",
//       method: `Record the software version from the following systems:
// 1.	DP Control System.
// 2.	IJS
// 3.	DGPS 1
// 4.	DGPS 2
// 5.	SpotTrack
// `,
//       resultsExpected: "1.	Software versions as per the latest installations. ",
//       actualResults: "",
//       comments: "",
//       witnessedBy1: "",
//       date1: "",
//       witnessedBy2: "",
//       date2: "",
//       includeTableMethod: true,
//       includeTableExpected: true,
//       includeTableActual: true,
//       includeTableComments: true,
//       tableHeaders: {
//         method: ["System", "Software Version"],
//         expected: ["System", "Expected Version"],
//         actual: [
//           ["System", "Actual Version"],
//           ["Discrepancy", "Action Taken"],
//         ],
//         comments: ["Topic", "Comment"],
//       },
//     },
//   ],
// };

// import React, { useState, useRef } from "react";
// import Select from "react-select";
// import "./styles.scss";
// import { DateSelector } from "../../molecules/MonthYearSelector";
// import { formatDate } from "../../../helpers/formatDate";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import Delete from "../../icons/Delete";
// import toast from "react-hot-toast";
// import { confimationStyles } from "../../../assets/styles/toast";
// import CustomButton from "../../atoms/CustomButton";
// import { testData, testOptions } from "../../../helpers/staticData";

// const FMEATest = ({ roleType, setTestDetails, testDetails }) => {
//   const [tableData, setTableData] = useState({});

//   // Create refs for ReactQuill editors
//   const methodQuillRef = useRef(null);
//   const resultsExpectedQuillRef = useRef(null);
//   const actualResultsQuillRef = useRef(null);
//   const commentQuillRef = useRef(null);

//   const DEFAULT_ROW_COUNT = 4;
//   // Handle dropdown selection
//   // const handleTestChange = (selectedOption) => {
//   //   if (selectedOption) {
//   //     const { value } = selectedOption;
//   //     const newTestDetail = JSON.parse(JSON.stringify(testData[value][0]));
//   //     const currentTests = testDetails[value] || [];
//   //     newTestDetail.testNo = `Test No. ${currentTests.length + 1}`;

//   const handleTestChange = (selectedOption) => {
//     if (selectedOption) {
//       const { value } = selectedOption;

//       if (testData[value]) {
//         const newTestDetail = JSON.parse(JSON.stringify(testData[value][0]));
//         const currentTests = testDetails[value] || [];
//         newTestDetail.testNo = `Test No. ${currentTests.length + 1}`;

//         setTestDetails((prevDetails) => ({
//           ...prevDetails,
//           [value]: [...(prevDetails[value] || []), newTestDetail],
//         }));

//         // Initialize table data
//         const newTableData = {};
//         ["method", "expected", "actual", "comments"].forEach((section) => {
//           if (
//             newTestDetail[
//               `includeTable${
//                 section.charAt(0).toUpperCase() + section.slice(1)
//               }`
//             ]
//           ) {
//             const headers = newTestDetail.tableHeaders[section];
//             if (Array.isArray(headers?.[0])) {
//               // Multiple tables
//               headers.forEach((headerSet, tableIndex) => {
//                 newTableData[`${section}-${tableIndex}`] = {
//                   headers: headerSet,
//                   rows: Array(DEFAULT_ROW_COUNT)
//                     .fill()
//                     .map(() => Array(headerSet?.length).fill("")),
//                 };
//               });
//             } else {
//               // Single table
//               newTableData[section] = {
//                 headers,
//                 rows: Array(DEFAULT_ROW_COUNT)
//                   .fill()
//                   .map(() => Array(headers?.length).fill("")),
//               };
//             }
//           }
//         });

//         setTableData((prevTableData) => ({
//           ...prevTableData,
//           [`${value}-${currentTests.length}`]: newTableData,
//         }));

//         toast.success(`Test added successfully`, {
//           style: confimationStyles,
//           duration: 1000,
//         });
//       } else {
//         toast.error("Test data not found");
//       }
//     }
//   };
//   const handleRemoveTest = (testType, index) => {
//     setTestDetails((prevDetails) => ({
//       ...prevDetails,
//       [testType]: prevDetails[testType].filter((_, i) => i !== index),
//     }));

//     setTableData((prevTableData) => {
//       const newTableData = { ...prevTableData };
//       delete newTableData[`${testType}-${index}`];
//       return newTableData;
//     });
//   };

//   const handleInputChange = (testType, index, field, value) => {
//     setTestDetails((prevDetails) => ({
//       ...prevDetails,
//       [testType]: prevDetails[testType].map((test, i) =>
//         i === index ? { ...test, [field]: value } : test,
//       ),
//     }));
//   };

//   const handleCheckboxChange = (testType, index) => {
//     setTestDetails((prevDetails) => {
//       const updatedTests = prevDetails[testType].map((test, i) => {
//         if (i === index) {
//           const isChecked = !test.isSameAsExpected;
//           return {
//             ...test,
//             isSameAsExpected: isChecked,
//             actualResults: isChecked ? test.resultsExpected : "",
//           };
//         }
//         return test;
//       });

//       return {
//         ...prevDetails,
//         [testType]: updatedTests,
//       };
//     });
//   };

//   const handleDateChange = (date, testType, index, dateField) => {
//     setTestDetails((prevDetails) => ({
//       ...prevDetails,
//       [testType]: prevDetails[testType].map((test, i) =>
//         i === index ? { ...test, [dateField]: date ? date : "" } : test,
//       ),
//     }));
//   };

//   const handleTableChange = (
//     testType,
//     testIndex,
//     section,
//     tableIndex,
//     rowIndex,
//     colIndex,
//     value,
//   ) => {
//     setTableData((prevTableData) => {
//       const key = `${testType}-${testIndex}`;
//       const sectionKey =
//         tableIndex !== undefined ? `${section}-${tableIndex}` : section;
//       const currentTable = prevTableData[key][sectionKey];
//       const newRows = currentTable.rows.map((row, rIndex) =>
//         rIndex === rowIndex
//           ? row.map((cell, cIndex) => (cIndex === colIndex ? value : cell))
//           : row,
//       );
//       return {
//         ...prevTableData,
//         [key]: {
//           ...prevTableData[key],
//           [sectionKey]: {
//             ...currentTable,
//             rows: newRows,
//           },
//         },
//       };
//     });
//   };

//   const addTableRow = (testType, testIndex, section, tableIndex) => {
//     setTableData((prevTableData) => {
//       const key = `${testType}-${testIndex}`;
//       const sectionKey =
//         tableIndex !== undefined ? `${section}-${tableIndex}` : section;
//       const currentTable = prevTableData[key][sectionKey];
//       const newRow = new Array(currentTable.headers.length).fill("");
//       return {
//         ...prevTableData,
//         [key]: {
//           ...prevTableData[key],
//           [sectionKey]: {
//             ...currentTable,
//             rows: [...currentTable.rows, newRow],
//           },
//         },
//       };
//     });
//   };

//   const removeTableRow = (
//     testType,
//     testIndex,
//     section,
//     tableIndex,
//     rowIndex,
//   ) => {
//     setTableData((prevTableData) => {
//       const key = `${testType}-${testIndex}`;
//       const sectionKey =
//         tableIndex !== undefined ? `${section}-${tableIndex}` : section;
//       const currentTable = prevTableData[key][sectionKey];
//       const newRows = currentTable.rows.filter(
//         (_, index) => index !== rowIndex,
//       );
//       return {
//         ...prevTableData,
//         [key]: {
//           ...prevTableData[key],
//           [sectionKey]: {
//             ...currentTable,
//             rows: newRows,
//           },
//         },
//       };
//     });
//   };

//   const addTableColumn = (testType, testIndex, section, tableIndex) => {
//     setTableData((prevTableData) => {
//       const key = `${testType}-${testIndex}`;
//       const sectionKey =
//         tableIndex !== undefined ? `${section}-${tableIndex}` : section;
//       const currentTable = prevTableData[key][sectionKey];
//       const newHeaders = [
//         ...currentTable.headers,
//         `Column ${currentTable.headers.length + 1}`,
//       ];
//       const newRows = currentTable.rows.map((row) => [...row, ""]);
//       return {
//         ...prevTableData,
//         [key]: {
//           ...prevTableData[key],
//           [sectionKey]: {
//             headers: newHeaders,
//             rows: newRows,
//           },
//         },
//       };
//     });
//   };

//   const removeTableColumn = (
//     testType,
//     testIndex,
//     section,
//     tableIndex,
//     colIndex,
//   ) => {
//     setTableData((prevTableData) => {
//       const key = `${testType}-${testIndex}`;
//       const sectionKey =
//         tableIndex !== undefined ? `${section}-${tableIndex}` : section;
//       const currentTable = prevTableData[key][sectionKey];
//       const newHeaders = currentTable.headers.filter(
//         (_, index) => index !== colIndex,
//       );
//       const newRows = currentTable.rows.map((row) =>
//         row.filter((_, index) => index !== colIndex),
//       );
//       return {
//         ...prevTableData,
//         [key]: {
//           ...prevTableData[key],
//           [sectionKey]: {
//             headers: newHeaders,
//             rows: newRows,
//           },
//         },
//       };
//     });
//   };

//   const handleHeaderChange = (
//     testType,
//     testIndex,
//     section,
//     tableIndex,
//     colIndex,
//     value,
//   ) => {
//     setTableData((prevTableData) => {
//       const key = `${testType}-${testIndex}`;
//       const sectionKey =
//         tableIndex !== undefined ? `${section}-${tableIndex}` : section;
//       const currentTable = prevTableData[key][sectionKey];
//       const newHeaders = currentTable.headers.map((header, index) =>
//         index === colIndex ? value : header,
//       );
//       return {
//         ...prevTableData,
//         [key]: {
//           ...prevTableData[key],
//           [sectionKey]: {
//             ...currentTable,
//             headers: newHeaders,
//           },
//         },
//       };
//     });
//   };

//   const renderTable = (testType, testIndex, section, tableIndex) => {
//     console.log("Rendering table with parameters:", {
//       testType,
//       testIndex,
//       section,
//       tableIndex,
//     });

//     const key = `${testType}-${testIndex}`;
//     const sectionKey =
//       tableIndex !== undefined ? `${section}-${tableIndex}` : section;

//     console.log("Constructed key and sectionKey:", { key, sectionKey });

//     const currentTable = tableData[key]?.[sectionKey];

//     if (!currentTable) {
//       console.warn("No table found for the constructed key and sectionKey");
//       return null;
//     }

//     console.log("tableData--------->", tableData);

//     return (
//       <div style={{ display: "flex", width: "100%" }}>
//         <div style={{ margin: "0px auto", padding: "30px" }}>
//           <table className="editable-table">
//             <thead>
//               <tr>
//                 {currentTable?.headers?.map((header, colIndex) => (
//                   <th key={colIndex} style={thStyle}>
//                     {roleType === "Admin" ? (
//                       <input
//                         type="text"
//                         value={header}
//                         onChange={(e) =>
//                           handleHeaderChange(
//                             testType,
//                             testIndex,
//                             section,
//                             tableIndex,
//                             colIndex,
//                             e.target.value,
//                           )
//                         }
//                         style={{ width: "100%" }}
//                       />
//                     ) : (
//                       header
//                     )}
//                     {roleType === "Admin" && (
//                       <div style={{ display: "flex", width: "100%" }}>
//                         <div
//                           style={{
//                             alignItems: "center",
//                             cursor: "pointer",
//                             padding: "5px",
//                             margin: "0px auto",
//                           }}
//                           onClick={() =>
//                             removeTableColumn(
//                               testType,
//                               testIndex,
//                               section,
//                               tableIndex,
//                               colIndex,
//                             )
//                           }
//                         >
//                           <Delete />
//                         </div>
//                       </div>
//                     )}
//                   </th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {currentTable.rows.map((row, rowIndex) => (
//                 <tr key={rowIndex}>
//                   {row.map((cell, cellIndex) => (
//                     <td key={cellIndex}>
//                       <input
//                         type="text"
//                         value={cell}
//                         onChange={(e) =>
//                           handleTableChange(
//                             testType,
//                             testIndex,
//                             section,
//                             tableIndex,
//                             rowIndex,
//                             cellIndex,
//                             e.target.value,
//                           )
//                         }
//                         readOnly={
//                           roleType !== "Admin" && roleType !== "Surveyor"
//                         }
//                         style={{ width: "100%" }}
//                       />
//                     </td>
//                   ))}
//                   {roleType === "Admin" && (
//                     <td
//                       style={{
//                         ...removeTd,
//                         padding: "10px",
//                       }}
//                     >
//                       <div
//                         style={{
//                           alignItems: "center",
//                           cursor: "pointer",
//                           padding: "5px",
//                         }}
//                         onClick={() =>
//                           removeTableRow(
//                             testType,
//                             testIndex,
//                             section,
//                             tableIndex,
//                             rowIndex,
//                           )
//                         }
//                       >
//                         <Delete />
//                       </div>
//                     </td>
//                   )}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           {roleType === "Admin" && (
//             <div
//               style={{
//                 display: "flex",
//                 gap: "20px",
//                 marginTop: "15px",
//                 marginBottom: "25px",
//               }}
//             >
//               <CustomButton
//                 type="btn-primary"
//                 iconRequired={true}
//                 handleClick={() => {
//                   addTableRow(testType, testIndex, section, tableIndex);
//                 }}
//                 style={{ width: "max-content" }}
//               >
//                 Add Row
//               </CustomButton>
//               <CustomButton
//                 type="btn-primary"
//                 iconRequired={true}
//                 handleClick={() => {
//                   addTableColumn(testType, testIndex, section, tableIndex);
//                 }}
//                 style={{ width: "max-content" }}
//               >
//                 Add Column
//               </CustomButton>
//             </div>
//           )}
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div style={{ width: "100%", padding: "0px", minHeight: "500px" }}>
//       {roleType === "Admin" && <></>}
//       <div className="overview-heading" style={{ marginTop: "10px" }}>
//         Tests
//       </div>
//       <Select
//         options={testOptions}
//         onChange={handleTestChange}
//         placeholder="Select Test Types"
//         className="fmea-dropdown"
//         isClearable={true}
//         closeMenuOnSelect={false}
//       />

//       {Object?.entries(testDetails)?.map(([testType, tests]) => (
//         <div key={testType}>
//           {tests.map((testDetail, index) => (
//             <div key={`${testType}-${index}`}>
//               <div
//                 style={{
//                   marginTop: "10px",
//                   display: "flex",
//                   justifyContent: "flex-end",
//                   width: "100%",
//                 }}
//               >
//                 <div
//                   style={{
//                     alignItems: "center",
//                     cursor: "pointer",
//                     padding: "5px",
//                   }}
//                   onClick={() => handleRemoveTest(testType, index)}
//                 >
//                   <Delete />
//                 </div>
//               </div>

//               <table className="fmea-table" style={{ marginTop: "10px" }}>
//                 <tbody>
//                   <tr>
//                     <td colSpan="1" className="fmea-header">
//                       EQUIPMENT
//                     </td>
//                     <td colSpan="1" className="fmea-header">
//                       {testDetail?.equipment}
//                     </td>
//                   </tr>

//                   <tr>
//                     <td colSpan="2">
//                       <label> {testDetail?.testNo} : </label>
//                       {roleType === "Admin" ? (
//                         <input
//                           type="text"
//                           value={testDetail?.name}
//                           onChange={(e) =>
//                             handleInputChange(
//                               testType,
//                               index,
//                               "name",
//                               e.target.value,
//                             )
//                           }
//                           style={{ width: "100%" }}
//                         />
//                       ) : (
//                         <span style={cellStyle}>{testDetail?.name}</span>
//                       )}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td colSpan="2">
//                       <label>Objective:</label>
//                       {roleType === "Admin" ? (
//                         <input
//                           type="text"
//                           value={testDetail?.objective}
//                           onChange={(e) =>
//                             handleInputChange(
//                               testType,
//                               index,
//                               "objective",
//                               e.target.value,
//                             )
//                           }
//                           style={{ width: "100%" }}
//                         />
//                       ) : (
//                         <span style={cellStyle}>{testDetail?.objective}</span>
//                       )}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td colSpan="2">
//                       <label>FMEA Ref:</label>
//                       {roleType === "Admin" ? (
//                         <input
//                           type="text"
//                           value={testDetail?.fmeaRef}
//                           onChange={(e) =>
//                             handleInputChange(
//                               testType,
//                               index,
//                               "fmeaRef",
//                               e.target.value,
//                             )
//                           }
//                           style={{ width: "100%" }}
//                         />
//                       ) : (
//                         <span style={cellStyle}>{testDetail?.fmeaRef}</span>
//                       )}
//                     </td>
//                   </tr>

//                   <tr>
//                     <td colSpan="2">
//                       <label>Method:</label>
//                       {roleType === "Admin" ? (
//                         <ReactQuill
//                           theme="snow"
//                           value={testDetail?.method}
//                           onChange={(value) =>
//                             handleInputChange(testType, index, "method", value)
//                           }
//                           className="custom-quill-editor"
//                           style={{
//                             backgroundColor: "#f4f4f4",
//                           }}
//                           ref={methodQuillRef}
//                         />
//                       ) : (
//                         <span style={cellStyle}>{testDetail?.method}</span>
//                       )}
//                       {testDetail.includeTableMethod && (
//                         <>{renderTable(testType, index, "method")}</>
//                       )}
//                     </td>
//                   </tr>

//                   <tr>
//                     <td colSpan="2">
//                       <label>Results Expected:</label>
//                       {roleType === "Admin" ? (
//                         <ReactQuill
//                           theme="snow"
//                           value={testDetail?.resultsExpected}
//                           onChange={(value) =>
//                             handleInputChange(
//                               testType,
//                               index,
//                               "resultsExpected",
//                               value,
//                             )
//                           }
//                           className="custom-quill-editor"
//                           style={{ backgroundColor: "#f4f4f4" }}
//                           ref={resultsExpectedQuillRef}
//                         />
//                       ) : (
//                         <span style={cellStyle}>
//                           <div
//                             dangerouslySetInnerHTML={{
//                               __html: testDetail?.resultsExpected,
//                             }}
//                           />
//                         </span>
//                       )}

//                       {testDetail.includeTableExpected && (
//                         <>{renderTable(testType, index, "expected")}</>
//                       )}
//                     </td>
//                   </tr>

//                   <tr>
//                     <td colSpan="2">
//                       <label>Actual Results:</label>
//                       {roleType === "Admin" || roleType === "Surveyor" ? (
//                         <span style={cellStyle}>
//                           {/* <div
//                             dangerouslySetInnerHTML={{
//                               __html: testDetail?.actualResults,
//                             }}
//                           /> */}
//                           <ReactQuill
//                             theme="snow"
//                             value={testDetail.actualResults}
//                             onChange={(value) =>
//                               handleInputChange(
//                                 testType,
//                                 index,
//                                 "actualResults",
//                                 value,
//                               )
//                             }
//                             className="custom-quill-editor"
//                             style={{ backgroundColor: "#f4f4f4" }}
//                             ref={actualResultsQuillRef}
//                           />
//                         </span>
//                       ) : (
//                         <>
//                           <br />
//                           <label>
//                             <input
//                               type="checkbox"
//                               checked={testDetail.isSameAsExpected || false}
//                               onChange={() =>
//                                 handleCheckboxChange(testType, index)
//                               }
//                               style={{ height: "unset", marginRight: "10px" }}
//                             />
//                             Same as Expected Results
//                           </label>
//                           <ReactQuill
//                             theme="snow"
//                             value={testDetail?.actualResults}
//                             onChange={(value) =>
//                               handleInputChange(
//                                 testType,
//                                 index,
//                                 "actualResults",
//                                 value,
//                               )
//                             }
//                             className="custom-quill-editor"
//                             style={{ backgroundColor: "#f4f4f4" }}
//                             ref={actualResultsQuillRef}
//                           />
//                         </>
//                       )}

//                       {testDetail.includeTableActual && (
//                         <>
//                           {Array.isArray(testDetail.tableHeaders.actual[0])
//                             ? testDetail.tableHeaders.actual.map(
//                                 (_, tableIndex) => (
//                                   <div key={tableIndex}>
//                                     {/* <h5>Table {tableIndex + 1}</h5> */}
//                                     {renderTable(
//                                       testType,
//                                       index,
//                                       "actual",
//                                       tableIndex || 0,
//                                     )}
//                                   </div>
//                                 ),
//                               )
//                             : renderTable(testType, index, "actual")}
//                         </>
//                       )}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td colSpan="2">
//                       <label>Comments:</label>
//                       {roleType === "Admin" ? (
//                         <span style={cellStyle}>{testDetail?.comments}</span>
//                       ) : (
//                         // <textarea
//                         //   value={testDetail.comments}
//                         //   onChange={(e) =>
//                         //     handleInputChange(
//                         //       testType,
//                         //       index,
//                         //       "comments",
//                         //       e.target.value,
//                         //     )
//                         //   }
//                         //   style={{ width: "100%" }}
//                         // />
//                         <ReactQuill
//                           theme="snow"
//                           value={testDetail.comments}
//                           onChange={(value) =>
//                             handleInputChange(
//                               testType,
//                               index,
//                               "comments",
//                               value,
//                             )
//                           }
//                           className="custom-quill-editor"
//                           style={{
//                             backgroundColor: "#f4f4f4",
//                           }}
//                           ref={commentQuillRef}
//                         />
//                       )}
//                       {testDetail.includeTableComments && (
//                         <>{renderTable(testType, index, "comments")}</>
//                       )}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td colSpan="1" style={{ width: "50%" }}>
//                       <label>Witnessed By:</label>
//                       {roleType === "Admin" ? (
//                         <span style={cellStyle}>
//                           {testDetail?.witnessedBy1}
//                         </span>
//                       ) : (
//                         <input
//                           type="text"
//                           value={testDetail?.witnessedBy1}
//                           onChange={(e) =>
//                             handleInputChange(
//                               testType,
//                               index,
//                               "witnessedBy1",
//                               e.target.value,
//                             )
//                           }
//                           style={{ width: "100%" }}
//                         />
//                       )}
//                     </td>
//                     <td colSpan="1" style={{ width: "50%" }}>
//                       <label>Date:</label>
//                       {roleType === "Admin" ? (
//                         <span style={cellStyle}>
//                           {formatDate(testDetail?.date1)}
//                         </span>
//                       ) : (
//                         <div style={{ position: "relative" }}>
//                           <DateSelector
//                             placeholder="Select Date"
//                             onChange={(option) =>
//                               handleDateChange(option, testType, index, "date1")
//                             }
//                             selectedDate={testDetail?.date1}
//                           />
//                         </div>
//                       )}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td colSpan="1" style={{ width: "50%" }}>
//                       <label>Witnessed By:</label>
//                       {roleType === "Admin" ? (
//                         <span style={cellStyle}>
//                           {testDetail?.witnessedBy2}
//                         </span>
//                       ) : (
//                         <input
//                           type="text"
//                           value={testDetail?.witnessedBy2}
//                           onChange={(e) =>
//                             handleInputChange(
//                               testType,
//                               index,
//                               "witnessedBy2",
//                               e.target.value,
//                             )
//                           }
//                           style={{ width: "100%" }}
//                         />
//                       )}
//                     </td>
//                     <td colSpan="1" style={{ width: "50%" }}>
//                       <label>Date:</label>
//                       {roleType === "Admin" ? (
//                         <span style={cellStyle}>
//                           {formatDate(testDetail?.date2)}
//                         </span>
//                       ) : (
//                         <div style={{ position: "relative" }}>
//                           <DateSelector
//                             placeholder="Select Date"
//                             onChange={(option) =>
//                               handleDateChange(option, testType, index, "date2")
//                             }
//                             selectedDate={testDetail?.date2}
//                           />
//                         </div>
//                       )}
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default FMEATest;

// const cellStyle = {
//   border: "none",
//   padding: 0,
//   paddingLeft: "5px",
//   fontSize: "14px",
// };
// const removeTd = {
//   border: "none",
// };
// const tableStyle = {
//   width: "100%",
//   borderCollapse: "collapse",
//   marginBottom: "20px",
// };
// const tdStyle = {
//   border: "1px solid black",
//   padding: "8px",
//   textAlign: "left",
// };

// const thStyle = {
//   ...tdStyle,
//   fontWeight: "bold",
// };
// const headerStyle = {
//   backgroundColor: "#f2f2f2",
//   padding: "10px",
//   borderBottom: "1px solid black",
//   textAlign: "left",
//   fontWeight: "bold",
// };

// // const testOptions = [{ value: "SOFTWARE AUDIT", label: "SOFTWARE AUDIT" }];
// // const testData = {
// //   "SOFTWARE AUDIT": [
// //     {
// //       name: "SOFTWARE AUDIT",
// //       equipment: "DP  SYSTEM",
// //       testNo: "Test No.",
// //       objective:
// //         "To record the software version installed in all DP related systems at the time of trials.",
// //       fmeaRef: "235465688098967",
// //       method: `Record the software version from the following systems:
// // 1.	DP Control System.
// // 2.	IJS
// // 3.	DGPS 1
// // 4.	DGPS 2
// // 5.	SpotTrack
// // `,
// //       resultsExpected: "1.	Software versions as per the latest installations. ",
// //       actualResults: "",
// //       comments: "",
// //       witnessedBy1: "",
// //       date1: "",
// //       witnessedBy2: "",
// //       date2: "",
// //       includeTableMethod: true,
// //       includeTableExpected: true,
// //       includeTableActual: true,
// //       includeTableComments: true,
// //       tableHeaders: {
// //         method: ["System", "Software Version"],
// //         expected: ["System", "Expected Version"],
// //         actual: [
// //           ["System", "Actual Version"],
// //           ["Discrepancy", "Action Taken"],
// //         ],
// //         comments: ["Topic", "Comment"],
// //       },
// //     },
// //   ],
// // };
