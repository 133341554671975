import { useState } from "react";
import InfoContainer from "../Info";
import Info from "../../icons/Info";

const MyTextArea = ({
  info = "",
  label,
  icon,
  InputHeight = "small",
  width = "w-30",
  style,
  customholder = "",
  value, // Use the value prop passed from the parent
  onChange, // Use the onChange prop passed from the parent
  validate,
  customBlur,
  blurError,
  ...props
}) => {
  const [touched, setTouched] = useState(false);
  const [placeholder, setPlaceholder] = useState(customholder);

  const handleFocus = () => {
    setPlaceholder(
      customholder.substring(0, 5) === "Enter"
        ? customholder.substring(5)
        : customholder,
    );
  };

  const handleBlur = (event) => {
    setTouched(true);
    if (event.target.value === "") {
      setPlaceholder(customholder);
    }

    // Validation logic (if `validate` prop is provided)
    if (validate) {
      const errorMessage = validate(event.target.value);
      if (blurError) blurError(errorMessage); // Use customBlur for parent error handling
    }

    if (customBlur) customBlur(); // Call customBlur if provided
  };

  const handleChange = (event) => {
    if (onChange) onChange(event); // Call onChange prop from the parent
  };

  return (
    <div className={width} style={style}>
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      <div className={`d-flex align-center input-container input_icon_wrap`}>
        <textarea
          {...props}
          value={value} // Use value prop from parent
          className={`${touched && blurError ? "error-input " : ""}${
            InputHeight === "small" ? "primary-input-small" : "primary-input"
          } `}
          placeholder=""
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <label
          className={`${
            InputHeight === "small"
              ? "input-placeholder-small"
              : "input-placeholder"
          }`}
        >
          {placeholder}
        </label>
      </div>
      {info && !blurError ? <InfoContainer info={Info} /> : null}
      {touched && blurError ? (
        <div
          className="error-input-message"
          style={{ marginTop: InputHeight === "small" ? "4px" : "8px" }}
        >
          {blurError}
        </div>
      ) : null}
    </div>
  );
};

export default MyTextArea;
