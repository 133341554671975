import React, { useEffect } from "react";
import MyTextInput from "../../../../atoms/MyTextInput";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";
import MyDropdown from "../../../../atoms/MyDropdown";
import { DateSelector } from "../../../../molecules/MonthYearSelector";
import { postData } from "../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";

const TrialDetails = ({ modal = false, trialDetails, setTrialDetails }) => {
  const [cookies] = useCookies(["t"]);

  const handleDropdownChange = (name, option) => {
    setTrialDetails((prevState) => ({
      ...prevState,
      [name]: option?.value,
    }));
  };

  const handleCheckboxChange = () => {
    setTrialDetails((prevState) => ({
      ...prevState,
      rollingScheme: !prevState.rollingScheme,
    }));
  };

  console.log("TrialDetails", trialDetails);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Handle form submission
  //   const formData = {
  //     trialDetails,
  //   };
  //   console.log(formData);
  // };

  // const fetchVesselListing = async () => {
  //   console.log("id------>", id);
  //   try {
  //     const res = await getData({
  //       endpoint: "vessel/getVesselDetailsById",
  //       token: cookies.t,
  //       params: { id: id },
  //     });
  //     setVesselData(res?.data || {});
  //   } catch (err) {
  //     console.log("Error fetching vessel data:", err);
  //     setVesselData({}); // Ensure vesselData is empty on error
  //   }
  // };

  // useEffect(() => {
  //   fetchVesselListing();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      // vessel_id:
      trials: trialDetails?.trials,
      type: trialDetails?.type,
      is_rolling_scheme: trialDetails?.rollingScheme,
      start_date: trialDetails?.startDate,
      end_date: trialDetails?.endDate,
    };

    console.log("payload", payload);

    if (modal === true) {
      console.log("I am inNNN");

      try {
        // Make API call using postData
        const result = await postData({
          // endpoint: "vessel/create",
          data: payload,
          params: {},
          token: cookies.t,
        });

        console.log("result----->", result?.data?.data?.id);

        if (result) {
          toast.success(`Vessel added successfully`, {
            style: confimationStyles,
            duration: 2000,
          });

          //   fetchVesselData(result?.data?.data?.id);
        }

        console.log("API Response:", result);
      } catch (error) {
        console.error("Error submitting vessel information:", error);
        //   toast.error("Test data not found");
        toast.error(error, {
          style: errorStyles,
          duration: 1000,
        });
      }
    }
  };

  return (
    <>
      {modal === true ? (
        <form onSubmit={handleSubmit}>
          <div style={styles.row}>
            <div style={{ width: "100%" }}>
              <MyTextInput
                type="text"
                name="Trials"
                customholder="Trials"
                value={trialDetails.trials}
                // onChange={(e) => setTrials(e.target.value)}
                onChange={(e) =>
                  setTrialDetails({ ...trialDetails, trials: e.target.value })
                }
              />
            </div>
          </div>
          <div style={styles.row}>
            <div style={{ width: "49%" }}>
              <MyDropdown
                placeholder="Select Vessel"
                data={[
                  { label: "ABC", value: "ABC" },
                  { label: "XYZ", value: "XYZ" },
                ]}
                format="all"
                menuOpen={true}
                name="vesselName"
                width="100%"
                selectedValue={trialDetails.vesselName}
                // onChange={(options) => setVesselName(options?.value)}
                onChange={(option) =>
                  handleDropdownChange("vesselName", option)
                }
              />
            </div>
            <div style={styles.checkboxContainer}>
              <CheckboxWrapper
                checked={trialDetails.rollingScheme}
                onChange={handleCheckboxChange}
              />
              <span>Rolling Scheme</span>
            </div>

            <div style={styles.dropdownContainer}>
              <MyDropdown
                placeholder="Type"
                data={[
                  { label: "Proving", value: "Proving" },
                  { label: "Annual", value: "Annual" },
                ]}
                format="all"
                menuOpen={true}
                name="type"
                width="100%"
                selectedValue={trialDetails.type}
                onChange={(option) => handleDropdownChange("type", option)}
              />
            </div>
          </div>

          {/* Date Selectors */}
          <div style={styles.row}>
            <div style={styles.dateSelectorContainer}>
              <DateSelector
                placeholder="Start Date"
                selectedDate={trialDetails.startDate}
                // onChange={setStartDate}
                onChange={(date) =>
                  setTrialDetails((prevState) => ({
                    ...prevState,
                    startDate: date,
                  }))
                }
              />
            </div>

            <div style={styles.dateSelectorContainer}>
              <DateSelector
                placeholder="End Date"
                selectedDate={trialDetails.endDate}
                // onChange={setEndDate}
                onChange={(date) =>
                  setTrialDetails((prevState) => ({
                    ...prevState,
                    endDate: date,
                  }))
                }
              />
            </div>
          </div>

          {/* Submit Button */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {/* <button type="submit" style={styles.submitButton}>
              Submit
            </button> */}
            <button
              type="submit"
              style={styles.submitButton}
              // onClick={handleSubmitAddTrial}
            >
              Submit
            </button>
          </div>
        </form>
      ) : (
        <form onSubmit={handleSubmit}>
          {/* Trials Input and Rolling Scheme Checkbox */}
          <div style={styles.row}>
            <div
              style={styles.inputContainer}
              // style={{ width: modal === true ? "49%" : "60%" }}
            >
              <MyTextInput
                type="text"
                name="Trials"
                customholder="Trials"
                value={trialDetails.trials}
                // onChange={(e) => setTrials(e.target.value)}
                onChange={(e) =>
                  setTrialDetails({ ...trialDetails, trials: e.target.value })
                }
              />
            </div>

            <div style={styles.checkboxContainer}>
              <CheckboxWrapper
                checked={trialDetails.rollingScheme}
                onChange={handleCheckboxChange}
              />
              <span>Rolling Scheme</span>
            </div>

            <div style={styles.dropdownContainer}>
              <MyDropdown
                placeholder="Type"
                data={[
                  { label: "Proving", value: "Proving" },
                  { label: "Annual", value: "Annual" },
                ]}
                format="all"
                menuOpen={true}
                name="type"
                width="100%"
                selectedValue={trialDetails.type}
                onChange={(option) => handleDropdownChange("type", option)}
              />
            </div>
          </div>

          {/* Date Selectors */}
          <div style={styles.row}>
            <div style={styles.dateSelectorContainer}>
              <DateSelector
                placeholder="Start Date"
                selectedDate={trialDetails.startDate}
                // onChange={setStartDate}
                onChange={(date) =>
                  setTrialDetails((prevState) => ({
                    ...prevState,
                    startDate: date,
                  }))
                }
              />
            </div>

            <div style={styles.dateSelectorContainer}>
              <DateSelector
                placeholder="End Date"
                selectedDate={trialDetails.endDate}
                // onChange={setEndDate}
                onChange={(date) =>
                  setTrialDetails((prevState) => ({
                    ...prevState,
                    endDate: date,
                  }))
                }
              />
            </div>
          </div>

          {/* Submit Button */}
          {/* <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button type="submit" style={styles.submitButton}>
              Submit
            </button>
          </div> */}
        </form>
      )}
    </>
  );
};

const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    gap: "16px",
  },
  inputContainer: {
    width: "60%",
  },
  checkboxContainer: {
    width: "145px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  dropdownContainer: {
    width: "25%",
  },
  dateSelectorContainer: {
    width: "50%",
    position: "relative",
  },
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default TrialDetails;
