import React, { useCallback, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import PageHeader from "../../../../../atoms/PageHeader";
import Search from "../../../../../molecules/search";
import Pagination from "../../../../../molecules/Pagination";
import { getData } from "../../../../../../services";
import style from "./index.module.scss";
import moment from "moment/moment";

const Prompt = ({ is_create }) => {
  const [cookies] = useCookies(["t", "uid"]);
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const getUserData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "/getAllAmcaLogs",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchedValue,
        },
      });
      setTableData(res?.data);
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      <div className="d-flex space-between">
        <PageHeader title="AMCA Logs" type="small" />
        <div className="d-flex gap-3">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />
        </div>
      </div>
      <div className={style.logBodyWrap}>
        {tableData?.[0]?.logsData?.map((item) => {
          return (
            <>
              <div className={style.amcaLogWrap}>
                {item?.survey_status === "Added" && (
                  <>
                    <span className={style.labelText}>{item?.survey_name}</span>{" "}
                    is created by{" "}
                    <span className={style.labelText}>
                      {item?.survey_added_by}
                    </span>{" "}
                    on{" "}
                    <span className={style.labelText}>
                      {moment(item?.survey_added_date).format("DD-MM-YYYY")}
                    </span>
                    .
                  </>
                )}
                {item?.survey_status === "Edit" && (
                  <>
                    <span className={style.labelText}>{item?.survey_name}</span>{" "}
                    survey is ongoing by{" "}
                    <span className={style.labelText}>
                      {item?.surveyor_added_by}
                    </span>{" "}
                    on{" "}
                    <span className={style.labelText}>
                      {moment(item?.survey_added_date).format("DD-MM-YYYY")}
                    </span>
                    .
                  </>
                )}
                {item?.survey_status === "Submitted" && (
                  <>
                    <span className={style.labelText}>{item?.survey_name}</span>
                    survey is Submitted by{" "}
                    <span className={style.labelText}>
                      {item?.surveyor_added_by}
                    </span>{" "}
                    on{" "}
                    <span className={style.labelText}>
                      {moment(item?.survey_added_date).format("DD-MM-YYYY")}
                    </span>
                    .
                  </>
                )}
                {item?.survey_status === "Archive" && (
                  <>
                    <span className={style.labelText}>{item?.survey_name}</span>{" "}
                    survey is Deleted by{" "}
                    <span className={style.labelText}>
                      {item?.surveyor_added_by}
                    </span>{" "}
                    on{" "}
                    <span className={style.labelText}>
                      {moment(item?.survey_added_date).format("DD-MM-YYYY")}
                    </span>
                    .
                  </>
                )}
                {item?.type === "User" && (
                  <>
                    <span className={style.labelText}>{item?.user}</span> user
                    added by{" "}
                    <span className={style.labelText}>
                      {item?.user_added_by}
                    </span>
                    on{" "}
                    <span className={style.labelText}>
                      {moment(item?.user_added_date).format("DD-MM-YYYY")}
                    </span>
                    .
                  </>
                )}
                {item?.type === "User updated" && (
                  <>
                    <span className={style.labelText}>{item?.user}</span> user
                    updated by{" "}
                    <span className={style.labelText}>
                      {item?.user_added_by}
                    </span>{" "}
                    on{" "}
                    <span className={style.labelText}>
                      {moment(item?.user_added_date).format("DD-MM-YYYY")}
                    </span>
                    .
                  </>
                )}
                {item?.type === "Deleted" && (
                  <>
                    <span className={style.labelText}>{item?.user}</span> user
                    delete by{" "}
                    <span className={style.labelText}>
                      {item?.user_added_by}
                    </span>{" "}
                    on{" "}
                    <span className={style.labelText}>
                      {moment(item?.user_added_date).format("DD-MM-YYYY")}
                    </span>
                    .
                  </>
                )}
                {item?.type === "shareSurvey" && (
                  <>
                    <span className={style.labelText}>{item?.survey_name}</span>{" "}
                    Survey share by{" "}
                    <span className={style.labelText}>
                      {item?.user_added_by}
                    </span>{" "}
                    on{" "}
                    <span className={style.labelText}>
                      {moment(item?.user_added_date).format("DD-MM-YYYY")}
                    </span>
                    .
                  </>
                )}
                {item?.type === "shareSurveyUpdate" && (
                  <>
                    <span className={style.labelText}>{item?.survey_name}</span>{" "}
                    survey share updated by{" "}
                    <span className={style.labelText}>
                      {item?.user_added_by}
                    </span>{" "}
                    on{" "}
                    <span className={style.labelText}>
                      {moment(item?.user_added_date).format("DD-MM-YYYY")}
                    </span>
                    .
                  </>
                )}
              </div>
            </>
          );
        })}
      </div>
      {tableData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={tableData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default Prompt;
