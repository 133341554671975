const SmallArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.7098 8.21C18.6169 8.11627 18.5063 8.04188 18.3844 7.99111C18.2625 7.94034 18.1318 7.9142 17.9998 7.9142C17.8678 7.9142 17.7371 7.94034 17.6152 7.99111C17.4934 8.04188 17.3828 8.11627 17.2898 8.21L12.7098 12.79C12.6169 12.8837 12.5063 12.9581 12.3844 13.0089C12.2625 13.0596 12.1318 13.0858 11.9998 13.0858C11.8678 13.0858 11.7371 13.0596 11.6152 13.0089C11.4934 12.9581 11.3828 12.8837 11.2898 12.79L6.70982 8.21C6.61685 8.11627 6.50625 8.04188 6.38439 7.99111C6.26253 7.94034 6.13183 7.9142 5.99982 7.9142C5.8678 7.9142 5.7371 7.94034 5.61524 7.99111C5.49338 8.04188 5.38278 8.11627 5.28982 8.21C5.10356 8.39736 4.99902 8.65081 4.99902 8.915C4.99902 9.17918 5.10356 9.43263 5.28982 9.62L9.87982 14.21C10.4423 14.7718 11.2048 15.0873 11.9998 15.0873C12.7948 15.0873 13.5573 14.7718 14.1198 14.21L18.7098 9.62C18.8961 9.43263 19.0006 9.17918 19.0006 8.915C19.0006 8.65081 18.8961 8.39736 18.7098 8.21Z"
        fill="white"
      />
    </svg>
  );
};
export default SmallArrowDown;
