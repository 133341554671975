import { Box, Tab, Tabs } from "@mui/material";

const _sx = {
  "& .Mui-selected": {
    fontWeight: "600",
    color: "#000 !important",
    backgroundColor: "#fff !important",
  },
  "& button": {
    textTransform: "capitalize !important",
    paddingTop: "30px",
    fontWeight: "500",
    fontFamily: "Inter !important",
  },
  "& button:active": { border: "none !important", outline: "none !important" },
  "& .Mui-selected:active": {
    border: "none !important",
    outline: "none !important",
  },
  "& .Mui-selected:focus": {
    border: "none !important",
    outline: "none !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#145b64", // This can be a color of your choice
  },
  "& .MuiButtonBase-root .MuiTab-root": {
    fontFamily: "Inter !important",
  },
  "& .MuiTabs-scroller": {
    overflowX: "auto !important",
  },

  "&::-webkit-scrollbar": {
    display: "none !important", // Adjust as needed
  },
};

const TabStrip = (props) => {
  const {
    activeTab,
    onTabChange,
    data,
    allowedTabId,
    areTabsDisabled,
    margin,
  } = props;

  // console.log('allowed', allowedTabId)
  // console.log('disabled' , areTabsDisabled)

  const isIdPresent = !!allowedTabId;

  return (
    <div id="tabstrip">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginX: margin,
          overflowX: "auto",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={onTabChange}
          sx={_sx}
          style={{ overflowX: "auto" }}
        >
          {data?.map((item) => {
            const isTabDisabled =
              areTabsDisabled &&
              allowedTabId !== undefined &&
              item.id !== allowedTabId;
            // console.log('isdis', isTabDisabled)
            return (
              <Tab
                key={item?.id}
                value={item?.label}
                label={item?.label}
                disabled={isTabDisabled}
              />
            );
          })}
        </Tabs>
      </Box>
    </div>
  );
};

export default TabStrip;
