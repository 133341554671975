import React, { useState } from "react";
import MyTextInput from "../../../../atoms/MyTextInput";

const MainSwitchboard = () => {
  const [conditions, setConditions] = useState({
    maintenanceCheck: "",
    contractorsName: "",
    modifications: "",
    remarks: "",
  });

  console.log("conditions", conditions);

  return (
    <div>
      <div
        style={{ marginBottom: "15px", display: "inline-block", width: "100%" }}
      >
        <div
          style={{
            display: "inline-block",
            width: "50%",
            paddingRight: "10px",
          }}
        >
          <MyTextInput
            type="text"
            name="maintenanceCheck"
            customholder="Last Maintenance/Health Check"
            value={conditions.maintenanceCheck}
            onChange={(e) =>
              setConditions({ ...conditions, maintenanceCheck: e.target.value })
            }
          />
        </div>
        <div
          style={{ display: "inline-block", width: "50%", paddingLeft: "10px" }}
        >
          <MyTextInput
            type="text"
            name="contractorsName"
            customholder="Contractors Name"
            value={conditions.contractorsName}
            onChange={(e) =>
              setConditions({ ...conditions, contractorsName: e.target.value })
            }
          />
        </div>
      </div>

      <div style={{ display: "inline-block", width: "100%" }}>
        <div
          style={{
            display: "inline-block",
            width: "50%",
            paddingRight: "10px",
          }}
        >
          <MyTextInput
            type="text"
            name="modifications"
            customholder="Any modifications since last trials"
            value={conditions.modifications}
            onChange={(e) =>
              setConditions({ ...conditions, modifications: e.target.value })
            }
          />
        </div>
        <div
          style={{ display: "inline-block", width: "50%", paddingLeft: "10px" }}
        >
          <MyTextInput
            type="text"
            name="remarks"
            customholder="Main Switchboard Remarks"
            value={conditions.remarks}
            onChange={(e) =>
              setConditions({ ...conditions, remarks: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MainSwitchboard;
