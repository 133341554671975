import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { formats, modules } from "./EditorToolbar";
import "./TextEditor.css";

const TextEditor = ({
  placeHolderText = "Type Something..",
  label,
  value,
  onChange,
  classes,
  editorClases,
}) => {
  const [editorValue, setEditorValue] = useState(value || "");

  const [selectedTextProperties, setSelectedTextProperties] = useState({});

  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  const handleEditorChange = (value) => {
    setEditorValue(value);
    onChange(value);
  };

  const updateSelectedTextProperties = () => {
    const quill = quillRef.current.getEditor();
    const selection = quill.getSelection();
    if (selection) {
      const formats = quill.getFormat(selection.index, selection.length);
      setSelectedTextProperties(formats);
    }
  };

  const handleSelectionChange = () => {
    updateSelectedTextProperties();
  };

  const quillRef = React.useRef();

  return (
    <>
      <label>{label}</label>
      <div>
        <EditorToolbar
          toolbarId={"t1"}
          className={editorClases}
          formats={selectedTextProperties}
        />
        <ReactQuill
          ref={quillRef}
          className={classes}
          theme="snow"
          value={editorValue}
          onChange={handleEditorChange}
          placeholder={placeHolderText}
          modules={modules("t1")}
          formats={formats}
          onChangeSelection={handleSelectionChange}
        />
      </div>
    </>
  );
};

export default TextEditor;
