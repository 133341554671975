const Ship = ({ className = "", width = "16", height = "16" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="18"
      height="18"
    >
      <path d="M12,7.838,4,10.505V8A3,3,0,0,1,7,5H17a3,3,0,0,1,3,3v2.505ZM22,21c0,.344-.682,1-1.75,1a2.044,2.044,0,0,1-1.619-.7A11.131,11.131,0,0,0,22,13.333v-.054l-9-3V21H11V10.28l-9,3v.054A11.131,11.131,0,0,0,5.375,21.3,2.046,2.046,0,0,1,3.75,22C2.661,22,2,21.306,2,21H0c0,1.626,1.718,3,3.75,3A4.194,4.194,0,0,0,6.5,23a4.313,4.313,0,0,0,5.5.014A4.313,4.313,0,0,0,17.5,23a4.194,4.194,0,0,0,2.749,1C22.282,24,24,22.626,24,21ZM16,2a2,2,0,0,0-2-2H10A2,2,0,0,0,8,2V3h8Z" />
    </svg>
  );
};

export default Ship;
