import "./sidebar.scss";
import Dashboard from "../../icons/dashboard";
import CustomNavLink from "./CustomNavLink";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../icons/LeftArrow";
import { useCallback, useEffect, useState } from "react";
import useScreenWidth from "../../../hooks/useScreenwidth";
import CompleteLogo from "../../../assets/branding/amcaLogo.png";
import Configuration from "../../icons/Configuration";
import Ship from "../../icons/Ship";
import Trust from "../../icons/Trust";
import CircleAvatar from "../../atoms/CircleAvatar";
import {
  capitalizeLetter,
  decodeCookieValue,
} from "../../../helpers/capitalize";
import { Menu, MenuItem } from "@mui/material";
import LogoutModal from "../../organisms/modals/LogoutModal";
import { useCookies } from "react-cookie";
import UpwardArrow from "../../icons/UpwardArrow";
import Bottomarrow from "../../icons/Bottomarrow";

const Sidebar = ({ isActive, setIsActive }) => {
  const screenWidth = useScreenWidth();
  const [cookies, setCookies] = useCookies("un");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();
  const links = [
    {
      to: "/dashboard/vessel",
      label: "Vessels",
      icon: <Ship />,
      alternatePath: "/dashboard/setting",
    },

    {
      to: "/dashboard/Trial",
      label: "Trials",
      icon: <Trust />,
      alternatePath: "/dashboard/setting",
    },
    {
      to: "/dashboard/setting",
      label: "Setting",
      icon: <Configuration />,
      alternatePath: "/dashboard/setting",
    },
  ];

  const memoizedSetIsActive = useCallback((newIsActive) => {
    setIsActive(newIsActive);
  }, []);

  useEffect(() => {
    memoizedSetIsActive(screenWidth >= 1233);
  }, [screenWidth, memoizedSetIsActive]);
  const handleSideBar = () => {
    // if (screenWidth < 1233) setIsActive(false);
  };
  return (
    <>
      <div className={`sidebar `}>
        <div className="header">
          {isActive ? (
            <img
              src={CompleteLogo}
              alt="Logo"
              className="open-header-img"
              onClick={() => navigate("/dashboard")}
            />
          ) : (
            <img
              src={CompleteLogo}
              alt="Logo"
              className="closed-header-img"
              onClick={() => navigate("/dashboard")}
            />
          )}
        </div>
        <ul className="ul">
          {links.map((link, index) => (
            <CustomNavLink
              key={index}
              to={link.to}
              label={link.label}
              icon={link.icon}
              handleSideBar={handleSideBar}
              isSideBarActive={isActive}
              setIsSideBarActive={setIsActive}
            >
              {link.subLinks?.map((subLink, subIndex) => (
                <CustomNavLink
                  key={subIndex}
                  to={subLink.to}
                  label={subLink.label}
                  handleSideBar={handleSideBar}
                  isSideBarActive={isActive}
                />
              ))}
            </CustomNavLink>
          ))}
        </ul>
        <div className="profile" onClick={handleProfileMenu}>
          <div className="d-flex gap-2">
            <CircleAvatar
              color="#DEE3E7"
              iconColor="black"
              name={capitalizeLetter(decodeCookieValue(cookies.un))}
            />
            {isActive && (
              <span className="profile-span">
                {capitalizeLetter(decodeCookieValue(cookies.un))}
              </span>
            )}
          </div>

          {isActive && (anchorEl ? <UpwardArrow /> : <Bottomarrow />)}
        </div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              // width: '200px',
              borderRadius: "6px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
              marginBottom: "40px",
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "bottom",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "bottom",
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/dashboard/edit-profile");
              setAnchorEl(null);
            }}
            style={{ fontWeight: 500 }}
          >
            <span>Edit Profile</span>
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigate("/dashboard/change-password");
              setAnchorEl(null);
            }}
            style={{ fontWeight: 500 }}
          >
            <span>Change Password</span>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setAnchorEl(null);
            }}
            style={{ fontWeight: 500 }}
          >
            <span style={{ color: "#BE0F00" }}>Logout</span>
          </MenuItem>
        </Menu>

        {isModalOpen && (
          <LogoutModal
            isActive={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </>
  );
};
export default Sidebar;
