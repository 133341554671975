import React from "react";

const Map = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path d="M15,22.281l-6,1.5V1.924l6-1.61ZM7,1.417,4.155.233A3,3,0,0,0,0,3V21.754l7,2Zm14.867.2L17.515.155A3.022,3.022,0,0,0,17,.036V22.273l7,1.705V4.483A3,3,0,0,0,21.867,1.612Z" />
    </svg>
  );
};

export default Map;
