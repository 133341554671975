import React from "react";
import { useLocation, Link } from "react-router-dom";
import "./styles.scss";
import { ArrowRightIcon } from "@mui/x-date-pickers";

const Breadcrumb = ({
  maxItems = 2,
  labels,
  excludeLastPath = false,
  stepsToRemove,
}) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  // Exclude the last part of the path if excludeLastPath is true
  const effectivePathnames = stepsToRemove
    ? pathnames.slice(0, -stepsToRemove)
    : excludeLastPath
    ? pathnames.slice(0, -1)
    : pathnames;

  // Generate the full paths for all breadcrumbs
  const fullPathnames = effectivePathnames.map((value, index) => ({
    pathname: `/${effectivePathnames.slice(0, index + 1).join("/")}`,
    label: value.charAt(0).toUpperCase() + value.slice(1),
  }));

  // Determine which pathnames to display
  let displayedPathnames =
    fullPathnames.length > maxItems
      ? fullPathnames.slice(-maxItems)
      : fullPathnames;

  const modifiedPathnames = displayedPathnames.map((item, index) => ({
    ...item,
    label: labels[index] || item.label,
  }));

  return (
    <nav aria-label="Breadcrumb" className="breadcrumb">
      <ol style={{ listStyle: "none", padding: 0, margin: 0 }}>
        {modifiedPathnames.map((item, index) => (
          <li key={item.pathname} style={{ display: "inline" }}>
            {index === modifiedPathnames.length - 1 ? (
              <span className="breadcrumb-item">{item.label}</span>
            ) : (
              <>
                <Link to={item.pathname} className="breadcrumb-link">
                  {item.label}
                </Link>{" "}
                <ArrowRightIcon />
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
