import { Formik, Form as FormikForm, FormikValues, Field } from "formik";
import * as yup from "yup";
import Error from "../../../../atoms/Error";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { postData } from "../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/toast";
import { errorStyles } from "../../../../molecules/Dropdown/dropdown";
import { expitationTime } from "../../../../../helpers/expirationTime";

const VerifyOTPForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookie, setCookie] = useCookies([
    "cookie",
    "survey_id",
    "email",
    "role",
    "st",
  ]);

  const initialValues = {
    otp: "",
  };
  const validationSchema = yup.object().shape({
    otp: yup
      .string()
      .length(6, "OTP is 6 digits long")
      .required("OTP is required"),
  });
  const storedCountdownStartTime = localStorage.getItem("countdownStartTime");
  const initialResendTimer = storedCountdownStartTime
    ? Number(storedCountdownStartTime)
    : 30;

  const [resendTimer, setResendTimer] = useState(initialResendTimer);
  const [showResend, setShowResend] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const startResendTimer = () => {
    setResendTimer(30);
    setShowResend(false);
  };

  const handleResendCompleted = () => {
    startResendTimer();
  };
  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevResendTimer) => prevResendTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [resendTimer]);

  useEffect(() => {
    localStorage.setItem("countdownStartTime", resendTimer.toString());
  }, [resendTimer]);

  const formatTimer = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleResendClick = async () => {
    try {
      // Set resendLoading to true when starting the resend process
      setResendLoading(true);
      // dispatch(setLoading(true));
      const { data: resultData, isLoading: resendLoading } = await postData({
        endpoint: "/shareSurvey/survey/resendOTP",
        data: {},
        params: { user_id: cookie.uid },
      });

      if (resultData.status) {
        // dispatch(setLoading(false));
        toast.success(resultData.message, {
          duration: 1000,
          style: confimationStyles,
        });
        handleResendCompleted();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setResendLoading(false);
    }
  };
  const handleSubmit = async (values) => {
    const setCookies = (token, role) => {
      setCookie("st", token, { path: "/", expires: expitationTime() });
      setCookie("role", role, { path: "/", expires: expitationTime() });
    };
    try {
      // dispatch(setLoading(true));

      const dataToSend = {
        ...values,
        survey_id: cookie.survey_id,
        email: cookie.email,
      };
      const { data: resultData } = await postData({
        endpoint: "/shareSurvey/survey/verifyOTP",
        data: {},
        params: dataToSend,
      });
      console.log("res", resultData);

      // dispatch(setLoading(false));

      if (resultData) {
        toast.success(resultData.message, {
          duration: 1000,
          style: confimationStyles,
        });
        navigate(`/survey/surveyor/${id}`);
        setCookies(resultData?.data?.token, btoa(resultData?.data?.role));
        handleResendCompleted();
      } else {
        toast.error(resultData.message, { duration: 1000, style: errorStyles });
      }
    } catch (error) {
      // dispatch(setLoading(false));
      console.error(error);
      toast.error(error?.response?.data?.message, {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        values,
        setFieldValue,
        handleBlur,
      }) => (
        <FormikForm onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Verification Code</label>
            <Field
              type="text"
              name="otp"
              placeholder="Enter Verification Code"
              maxLength={6}
              pattern="[0-9]*"
              className={`${touched.otp && errors.otp ? "error-input" : ""}`}
              onInput={(e) => {
                e.preventDefault();
                e.target.value = parseInt(e.target.value) || "";
                e.target.value = e.target.value.toString().slice(0, 6);
              }}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
            />

            {(touched.otp || isSubmitting) && errors.otp && (
              <Error error={errors.otp} />
            )}
          </div>

          <button
            className="btn btn-primary w-100 mt-4"
            disabled={isSubmitting}
            type="submit"
          >
            Verify{" "}
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <div className={`form_footer mt-4`}>
            <button
              className="btn btn-outline-dark"
              onClick={() => navigate(-1)}
              type="button"
            >
              Back
            </button>
            {resendTimer === 0 || showResend ? (
              <button
                className="btn btn-outline-dark"
                onClick={handleResendClick}
                type="button"
                disabled={resendLoading} // Adjust the condition based on your logic
              >
                Resend
                {resendLoading && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            ) : (
              <button
                className="btn btn-outline-dark"
                onClick={handleResendClick}
                type="button"
                disabled // Adjust the condition based on your logic
              >
                {formatTimer(resendTimer)}
              </button>
            )}
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};
export default VerifyOTPForm;
