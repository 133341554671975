import React, { useEffect, useState } from "react";
import "./styles.scss";

const Search = ({ onSearchEmit, placeholder = "Search", value, style }) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (newValue.length >= 3) {
      console.log("emit");
      onSearchEmit(newValue);
    } else {
      onSearchEmit("");
    }
  };

  return (
    <div className="search" style={style}>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text " id="basic-addon1">
            <i className="fi fi-rr-search ms-2 mt-1"></i>
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          style={{ paddingLeft: "0" }}
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={inputValue}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default Search;
