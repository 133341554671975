import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const ShareGuard = ({ children }) => {
  const auth = useSurveyAuthenticated();
  const [cookies] = useCookies(["survey_id"]);

  if (auth) {
    return <Navigate to={`/survey/surveyor/${cookies.survey_id}`} />;
  }

  return <>{children}</>;
};

export default ShareGuard;

export const useSurveyAuthenticated = () => {
  const [cookies] = useCookies(["st"]);

  const token = cookies.st || "";
  return !!token;
};
