import React, { useEffect, useRef, useState } from "react";
import "./styles.scss"; // Ensure you include this CSS file
// import FormHeader from "../../header";
import img1 from "../../../../assets/images/front.png";
import img2 from "../../../../assets/images/front2.png";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { getData } from "../../../../services";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { convertDateToDDMMYYYY } from "../../../../helpers/formatDate";
import FMEATestView from "../../FMEATest/FMEATestView";
import { formatDate } from "../../../../helpers/formatDate";

const ViewPage = ({
  roleType,
  previewMode = false,
  navigationClicked,
  setTestDetails,
  testDetails,
}) => {
  const [cookies] = useCookies(["role", "t"]);
  const linkId = useParams();
  const [surveyDataById, SetSurveyDataById] = useState({});
  const [testTableData, setTestTableData] = useState({});
  // const [testDetails, setTestDetails] = useState({});

  const divRefDisclaimer = useRef(null);
  const divRefSummary = useRef(null);
  const divRefInstruction = useRef(null);
  const divRefOrderOfTrial = useRef(null);
  const divRefScopeOfWork = useRef(null);
  const divRefRefAndAsstPub = useRef(null);
  const divRefReqDuringTest = useRef(null);
  const divRefVesselGeneral = useRef(null);
  const divRefMachineConfDuringTest = useRef(null);
  const divRefWorstCaseFailure = useRef(null);

  const id = linkId?.id;

  const getSurveyById = async (id) => {
    try {
      const res = await getData({
        endpoint: "survey/getSurveyById",
        token: cookies.t,
        params: { id: id },
      });
      SetSurveyDataById(res?.data);
      setTestTableData(
        res?.data?.test_tableData ? res?.data?.test_tableData : {},
      );
      setTestDetails(
        res?.data?.trial_template ? res?.data?.trial_template : {},
      );
      // setTtes
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getSurveyById(id);
    }
  }, [id]);

  console.log("testTableData------->", testTableData);
  console.log("testDetails------>", testDetails);
  //----------------------------Summary-------------------------------------------

  const summaryData = {
    summary_requested_by: surveyDataById?.summary_requested_by,
    summary_vessel_name: surveyDataById?.summary_vessel_name,
    summary_certified: surveyDataById?.summary_certified,
    summary_vessel_at: surveyDataById?.summary_vessel_at,
    summary_conducted_at: surveyDataById?.summary_conducted_at,
    summary_conducted_on: surveyDataById?.summary_conducted_on,
    summary_witnessed_by: surveyDataById?.summary_witnessed_by,
    summary_amca_marine_consulting:
      surveyDataById?.summary_amca_marine_consulting,
  };

  const {
    summary_requested_by,
    summary_behalf,
    summary_vessel_name,
    summary_certified,
    summary_vessel_at,
    summary_conducted_at,
    summary_conducted_on,
    summary_witnessed_by,
    summary_amca_marine_consulting,
  } = summaryData;

  // Template string with placeholders {{}} for dynamic content
  let SummaryTemplate = surveyDataById?.summary;

  // Replace placeholders with actual values from the API
  const filledSummaryTemplate = SummaryTemplate?.replace(
    "{{}}",
    summary_requested_by,
  )
    .replace("{{}}", summary_behalf)
    .replace("{{}}", summary_vessel_name)
    .replace("{{}}", summary_certified)
    .replace("{{}}", summary_vessel_at)
    .replace("{{}}", summary_conducted_at)
    .replace("{{}}", summary_conducted_on)
    .replace("{{}}", summary_witnessed_by)
    .replace("{{}}", summary_amca_marine_consulting);

  //--------------------Instruction Data-------------------------------------------------
  const instructionData = {
    Instruction_vessel_name: surveyDataById?.Instruction_vessel_name,
    Instruction_received_from: surveyDataById?.Instruction_received_from,
    Instruction_behalf_of: surveyDataById?.Instruction_behalf_of,
  };

  const {
    Instruction_vessel_name,
    Instruction_received_from,
    Instruction_behalf_of,
  } = instructionData;

  // Template string with placeholders for dynamic content
  // let instructionTemplate = `AMCA Marine Consulting was requested to witness the FMEA Proving Trial of the vessel {{Enter Vessel Name}}. Instructions were received from {{Enter Requestor}}, on behalf of {{Enter Client Name}}.`;

  let instructionTemplate = surveyDataById?.instruction || "";
  // Replace placeholders with actual values from the API
  const filledInstruction = instructionTemplate
    .replace("{{Enter Vessel Name}}", Instruction_vessel_name)
    .replace("{{Enter Requestor}}", Instruction_received_from)
    .replace("{{Enter Client Name}}", Instruction_behalf_of);

  //--------------------------------Order of Trials--------------------------------------
  const trialData = {
    order_of_Trials_dp: surveyDataById?.order_of_Trials_dp,
    order_of_Trials_dp_commenced_approximately:
      surveyDataById?.order_of_Trials_dp_commenced_approximately,
    order_of_Trials_dp_commenced_approximately_on:
      surveyDataById?.order_of_Trials_dp_commenced_approximately_on,
    order_of_Trials_dp_completed_approximately:
      surveyDataById?.order_of_Trials_dp_completed_approximately,
    order_of_Trials_dp_completed_approximately_on:
      surveyDataById?.order_of_Trials_dp_completed_approximately_on,
    order_of_Trials_carried_out_at:
      surveyDataById?.order_of_Trials_carried_out_at,
    order_of_Trials_around: surveyDataById?.order_of_Trials_around,
    order_of_Trials_weather_condition:
      surveyDataById?.order_of_Trials_weather_condition,
    order_of_Trials_seas_were: surveyDataById?.order_of_Trials_seas_were,
  };

  const {
    order_of_Trials_dp,
    order_of_Trials_dp_commenced_approximately,
    order_of_Trials_dp_commenced_approximately_on,
    order_of_Trials_dp_completed_approximately,
    order_of_Trials_dp_completed_approximately_on,
    order_of_Trials_carried_out_at,
    order_of_Trials_around,
    order_of_Trials_weather_condition,
    order_of_Trials_seas_were,
  } = trialData;

  // Template string with placeholders for dynamic content
  let orderOfTrialTemplate = surveyDataById?.order_of_trial || "";

  // Replace placeholders with actual values from the API
  const filledOrderOfTrial = orderOfTrialTemplate
    .replace("{{Enter trial DP}}", order_of_Trials_dp)
    .replace(
      "{{Enter Start Approximately}}",
      order_of_Trials_dp_commenced_approximately,
    )
    .replace(
      "{{Enter Start Approximately on}}",
      order_of_Trials_dp_commenced_approximately_on,
    )
    .replace("{{Enter End Time}}", order_of_Trials_dp_completed_approximately)
    .replace(
      "{{Enter End Date}}",
      order_of_Trials_dp_completed_approximately_on,
    )
    .replace("{{Enter Location}}", order_of_Trials_carried_out_at)
    .replace("{{Enter Depth}}", order_of_Trials_around)
    .replace("{{Enter Weather Condition}}", order_of_Trials_weather_condition)
    .replace("{{Enter Sea State}}", order_of_Trials_seas_were);

  //-----------------------------------------------------------------------

  const vesselGeneralData = {
    vessel_name: surveyDataById?.vessel_particulars_vessel_name,
    year_built: surveyDataById?.vessel_particulars_years,
    vessel_type: surveyDataById?.vessel_name,
    builder_location: surveyDataById?.vessel_particulars_builder_location,
    class_notation: surveyDataById?.vessel_particulars_classnotation,
    bow_thrusters: surveyDataById?.vessel_particulars_bow_number,
    stern_thrusters: surveyDataById?.vessel_particulars_thrusters_number,
    propeller_type: surveyDataById?.vessel_particulars_typeofmainpropeller,
  };

  // Destructure the vessel general data
  const {
    vessel_name,
    year_built,
    vessel_type,
    builder_location,
    class_notation,
    bow_thrusters,
    stern_thrusters,
    propeller_type,
  } = vesselGeneralData;

  // Template string for vessel general placeholders
  let vesselGeneralTemplate = surveyDataById?.vessel_general || "";

  console.log(
    "vesselGeneralTemplate--->",
    surveyDataById?.vesselGeneralTemplate,
  );

  // Replace placeholders with actual values from the API
  const filledVesselGeneral = vesselGeneralTemplate
    .replace("{{Enter Vessel Name}}", vessel_name)
    .replace("{{Enter Year Built}}", year_built)
    .replace("{{Enter Vessel}}", vessel_type)
    .replace("{{ Enter Builder & Location}}", builder_location)
    .replace("{{Enter Class Notation}}", class_notation)
    .replace("{{Enter Number of Bow Tunnel Thrusters}}", bow_thrusters)
    .replace("{{Enter Number of Stern Tunnel Thrusters}}", stern_thrusters)
    .replace("{{Enter Type of Main Propeller}}", propeller_type);

  console.log("filledVesselGeneral----->", filledVesselGeneral);
  //----------------------------------------------------------------------------
  console.log("VesselGeneral", surveyDataById?.vessel_general);
  console.log("id----------->", id);

  console.log("surveyDataById-------------->", surveyDataById);

  useEffect(() => {
    const handleScrollToSection = (id) => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "center" }); // Smooth scroll to the section
      }
    };
    handleScrollToSection(navigationClicked);
  }, [navigationClicked]);

  // Example Component to render HTML content
  const RenderHtmlContent = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  console.log(
    "surveyDataById?.document_revision_record---->",
    surveyDataById?.document_revision_record,
  );

  return (
    <>
      <div id="Verification Page" className="fmea-container">
        <div className="image-container">
          <img src={img1} alt="Logo 1" />
          <img src={img2} alt="Logo 2" />
        </div>

        <div className="spacer"></div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Centers the whole block horizontally
            width: "70%",
            // maxWidth: "600px",
            margin: "0 auto",
            fontFamily: "Arial, sans-serif",
          }}
        >
          {/* Container to align both texts to start from the same position */}
          <div
            style={{
              textAlign: "left", // Left-align both the texts
              width: "100%", // Make sure it takes up full width of the container
              maxWidth: "600px", // Same max-width as the parent container for centering
            }}
          >
            {/* Vessel Name */}
            <div
              style={{
                marginBottom: "12px",
              }}
            >
              <span
                style={{
                  color: "#a9d5e2", // Light blue color
                  fontSize: "40px", // Larger font size
                  fontWeight: "600", // Semi-bold weight
                }}
              >
                {surveyDataById?.vessel_name
                  ? surveyDataById?.vessel_name
                  : "#Vessel Name"}
              </span>
            </div>

            {/* FMEA Trial */}
            <div
              style={{
                marginBottom: "12px",
              }}
            >
              <span
                style={{
                  color: "#0f1c52", // Dark blue color
                  fontSize: "32px", // Slightly smaller font size than the vessel name
                  fontWeight: "700", // Bold weight
                }}
              >
                {surveyDataById?.fmea_trial
                  ? surveyDataById?.fmea_trial
                  : "FMEA Trial Name"}
              </span>
            </div>
          </div>
        </div>

        <div className="spacer"></div>

        <div className="table-container">
          <table className="info-table">
            <tbody>
              <tr>
                <td style={{ fontWeight: "500" }}>Prepared For</td>
                <td style={{ fontWeight: "500" }}>:</td>
                <td style={{ fontWeight: "500" }}>
                  {surveyDataById?.client_name}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "500" }}>Prepared By</td>
                <td style={{ fontWeight: "500" }}>:</td>
                <td style={{ fontWeight: "500" }}>
                  {surveyDataById?.prepared_by}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "500" }}>Doc. No.</td>
                <td style={{ fontWeight: "500" }}>:</td>
                <td style={{ fontWeight: "500" }}>{surveyDataById?.doc_no}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "500" }}>Rev. No.</td>
                <td style={{ fontWeight: "500" }}>:</td>
                <td style={{ fontWeight: "500" }}>{surveyDataById?.rev_no}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "500" }}>Date</td>
                <td style={{ fontWeight: "500" }}>:</td>
                <td style={{ fontWeight: "500" }}>
                  {surveyDataById?.date
                    ? formatDate(surveyDataById?.date)
                    : null}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="footer">
            Corr. Add: Office No. -704, Business Bay, Plot No.-51, Sector-1,
            Nerul, Navi Mumbai; E-mail: info@amcamarine.com
          </div>

          {/* {edit && <button onClick={handleSave}>Save</button>} */}
        </div>
      </div>

      <div className="disclaimer-container">
        <div id="Disclaimer" className="disclaimer-heading">
          DISCLAIMER
        </div>
        <div
          className="disclaimer-text-summary"
          ref={divRefDisclaimer}
          style={{ lineHeight: "unset" }}
        >
          {surveyDataById?.disclaimer}
        </div>
      </div>
      <div className="disclaimer-container">
        <div id="Summary" className="disclaimer-heading">
          SUMMARY
        </div>
        <div
          className="disclaimer-text-summary"
          ref={divRefSummary}
          style={{ lineHeight: "unset" }}
        >
          {filledSummaryTemplate}
        </div>
      </div>

      <div className="disclaimer-container">
        {/* Document Revision Record Table */}
        <div
          id="Document Revision Record"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          Document Revision Record
        </div>
        <div
          style={{
            width: "100%",
            overflowX: "scroll",
            height: "100%",
            msOverflowY: "hidden",
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th style={thStyle}>Rev</th>
                <th style={thStyle}>Date</th>
                <th style={thStyle}>Description</th>
                <th style={thStyle}>Prep.</th>
                <th style={thStyle}>Review</th>
                <th style={thStyle}>Appr</th>
              </tr>
            </thead>
            <tbody>
              {surveyDataById?.document_revision_record?.map(
                (record, index) => (
                  <tr key={index}>
                    <td style={{ ...cellStyle, height: "42px" }}>
                      {record.rev || ""}
                    </td>
                    <td style={{ ...cellStyle, height: "42px" }}>
                      {record.date ? convertDateToDDMMYYYY(record.date) : null}
                    </td>
                    <td style={{ ...cellStyle, height: "42px" }}>
                      {record.description}
                    </td>
                    <td style={{ ...cellStyle, height: "42px" }}>
                      {record.prep}
                    </td>
                    <td style={{ ...cellStyle, height: "42px" }}>
                      {record.review}
                    </td>
                    <td style={{ ...cellStyle, height: "42px" }}>
                      {record.appr}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>

        {/* Amendment Record Table */}
        <div
          id="Amendment Record"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          Amendment Record
        </div>
        <div
          style={{
            width: "100%",
            overflowX: "scroll",
            height: "100%",
            OverflowY: "hidden",
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th style={thStyle}>Revision</th>
                <th style={thStyle}>Description of Changes</th>
              </tr>
            </thead>
            <tbody>
              {surveyDataById?.amendment_record?.map((record, index) => (
                <tr key={index}>
                  <td style={{ ...cellStyle, height: "42px" }}>
                    {record.revision || ""}
                  </td>
                  <td style={{ ...cellStyle, height: "42px" }}>
                    {record.description || ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="overview-container">
        <div id="Overview" className="overview-heading">
          Section A: Overview
        </div>
        <div id="A-1: Introduction" className="overview-heading">
          A-1: Introduction
        </div>
        <div
          id="A-1.1: Instruction"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.1.: Instruction
        </div>
        <div className="disclaimer-text-summary" ref={divRefInstruction}>
          {filledInstruction}
        </div>
        <div
          id="A-1.2: Scope of Work"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.2: Scope of Work
        </div>
        <div className="overview-text" ref={divRefScopeOfWork}>
          {surveyDataById?.scope_of_work}
        </div>
        <div
          id="A-1.3: Referenced and Associated Publications"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.3: Referenced and Associated Publications
        </div>
        <div className="reference-text" ref={divRefRefAndAsstPub}>
          {surveyDataById?.referenced_and_associated_publications}
        </div>
        <div
          id="A-1.4: Key Personnel in Attendance at the Trials"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.4: Key Personnel in Attendance at the Trials
        </div>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <tbody>
            {surveyDataById?.key_personnel_attendance_Trial?.map(
              (row, index) => (
                <tr key={index}>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid black",
                      ...thStyle,
                    }}
                  >
                    {row.position}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      border: "1px solid black",
                      width: "50%",
                    }}
                  >
                    {row.name || ""}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
        <div
          id="A-1.5: Order of Trials"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.5: Order of Trials
        </div>
        <div
          className="overview-text"
          contentEditable={roleType === "Admin" ? true : false}
          ref={divRefOrderOfTrial}
        >
          {filledOrderOfTrial}
        </div>
        <div
          id="A-1.6: Requirements during Testing"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-1.6: Requirements during Testing
        </div>
        <div ref={divRefReqDuringTest} className="overview-text">
          {surveyDataById?.requirements_during_testing}
        </div>
        <div
          id="A-2: Vessel Particulars"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2: Vessel Particulars
        </div>
        <div
          id="A-2.1: General"
          className="overview-heading"
          style={{ marginTop: "5px" }}
        >
          A-2.1: General
        </div>
        <div
          className="disclaimer-text"
          ref={divRefVesselGeneral}
          style={{ marginBottom: "20px" }}
        >
          {filledVesselGeneral}
        </div>
        <table style={tableStyle}>
          <tbody>
            <tr>
              <td style={thStyle}>Type</td>
              <td style={cellStyle} colSpan="3">
                {surveyDataById?.vessel_particulatars_first_table?.type}
              </td>
              <td style={thStyle}>Class</td>
              <td style={cellStyle}>
                {surveyDataById?.vessel_particulatars_first_table?.class}
              </td>
            </tr>
            <tr>
              <td style={thStyle}>Length</td>
              <td style={cellStyle}>
                {surveyDataById?.vessel_particulatars_first_table?.length}
              </td>
              <td style={thStyle}>Speed</td>
              <td style={cellStyle}>
                {surveyDataById?.vessel_particulatars_first_table?.speed}
              </td>
              <td style={thStyle}>Builder</td>
              <td style={cellStyle}>
                {surveyDataById?.vessel_particulatars_first_table?.builder}
              </td>
            </tr>
            <tr>
              <td style={thStyle}>Breadth</td>
              <td style={cellStyle}>
                {surveyDataById?.vessel_particulatars_first_table?.breath}
              </td>
              <td style={thStyle}>Flag</td>
              <td style={cellStyle}>
                {surveyDataById?.vessel_particulatars_first_table?.flag}
              </td>
              <td style={thStyle}>Built</td>
              <td style={cellStyle}>
                {surveyDataById?.vessel_particulatars_first_table?.built}
              </td>
            </tr>
            <tr>
              <td style={thStyle}>Draft(S)</td>
              <td style={cellStyle}>
                {surveyDataById?.vessel_particulatars_first_table?.draft}
              </td>
              <td style={thStyle}>IMO No.</td>
              <td style={cellStyle}>
                {surveyDataById?.vessel_particulatars_first_table?.imo_no}
              </td>
              <td style={thStyle}>Owner</td>
              <td style={cellStyle}>
                {surveyDataById?.vessel_particulatars_first_table?.owner}
              </td>
            </tr>
            <tr>
              <td style={thStyle}>Dead Weight</td>
              <td style={cellStyle}>
                {surveyDataById?.vessel_particulatars_first_table?.dead_weight}
              </td>
              <td style={thStyle}></td>
              <td style={cellStyle}></td>
              <td style={thStyle}></td>
              <td style={cellStyle}></td>
            </tr>
          </tbody>
        </table>
        <div
          id="A-2.2: Main Engines & Auxiliary Diesel Engine Generators"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.2: Main Engines & Auxiliary Diesel Engine Generators
        </div>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  ...thStyle,
                  padding: "12px",
                  border: "1px solid black",
                }}
              >
                #
              </th>
              <th
                style={{
                  ...thStyle,
                  padding: "12px",
                  border: "1px solid black",
                }}
              >
                Description
              </th>
              <th
                style={{
                  ...thStyle,
                  padding: "12px",
                  border: "1px solid black",
                }}
              >
                Rated Power
              </th>
              <th
                style={{
                  ...thStyle,
                  padding: "12px",
                  border: "1px solid black",
                }}
              >
                Rated Speed
              </th>
              <th
                style={{
                  ...thStyle,
                  padding: "12px",
                  border: "1px solid black",
                }}
              >
                Model
              </th>
            </tr>
          </thead>
          <tbody>
            {surveyDataById?.auxiliary_diesel_engine_generator?.map(
              (row, index) => (
                <tr key={index}>
                  {/* # and Description editable for backoffice */}
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {row.me_number}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {row.description}
                  </td>

                  {/* Editable Rated Power, Rated Speed, and Model for surveyor */}
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {row.rated_power}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {row.rated_speed}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid black" }}>
                    {row.model}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
        <div
          id="A-2.3: Thrusters and Propulsion"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.3: Thrusters and Propulsion
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  #
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Type & Location
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Pitch
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Propeller Speed
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Model
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Drive
                </th>
                {/* {roleType === "Admin" && (
                  <th style={{ border: "1px solid black", padding: "10px" }}>
                    Actions
                  </th>
                )} */}
              </tr>
            </thead>
            <tbody>
              {surveyDataById?.thrusters_and_Propulsion?.map((row, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {row.id}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {row.type_and_location}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {row.pitch || ""}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {row.propeller_speed || ""}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {row.model || ""}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {row.drive || ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          id="A-2.4: Electric Distribution"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.4: Electric Distribution
        </div>
        <div>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Gen
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Switch-board
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Generator
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Engine
                </th>
                <th
                  style={{
                    ...thStyle,
                    border: "1px solid black",
                    padding: "10px",
                  }}
                >
                  Control
                </th>
              </tr>
            </thead>
            <tbody>
              {surveyDataById?.electric_distribution?.map((row, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {row.id || ""}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {row.switch_board || ""}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {row.generator || ""}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {row.engine || ""}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {row.control || ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          id="A-2.5: VESSEL LAYOUT / REDUNDANCY CONCEPT"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.5: VESSEL LAYOUT / REDUNDANCY CONCEPT
        </div>
        {/* <div style={{ width: "100%", display: "flex", padding: "39px" }}>
          <img
            src={vesselLayoutImage}
            alt=""
            style={{ width: "90%", margin: "auto" }}
          />
        </div> */}
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: surveyDataById?.vesselLayoutImages,
            }}
          ></div>
        </div>
        <div
          id="A-2.6: DP System"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.6: DP System
        </div>
        <div>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <tbody>
              <tr>
                <td style={thStyle}>Equipment</td>
                <td style={thStyle}>Make / Model</td>
              </tr>
              {surveyDataById?.dp_system_equipment?.map((row, index) => (
                <tr key={`equipment-${index}`}>
                  <td style={tdStyle}>{row.equipment || ""}</td>
                  <td style={tdStyle}>{row.make_model || ""}</td>
                </tr>
              ))}

              <tr>
                <td style={thStyle}>Position Reference System (PRS)</td>
                <td style={thStyle}>Make / Model</td>
              </tr>
              {surveyDataById?.position_reference_system?.map((row, index) => (
                <tr key={`prs-${index}`}>
                  <td style={tdStyle}>{row.equipment || ""}</td>
                  <td style={tdStyle}>{row.make_model || ""}</td>
                </tr>
              ))}
              <tr>
                <td style={thStyle}>Sensors</td>
                <td style={thStyle}>Make / Model</td>
              </tr>
              {surveyDataById?.dp_system_sensor?.map((row, index) => (
                <tr key={`sensors-${index}`}>
                  <td style={tdStyle}>{row.equipment || ""}</td>
                  <td style={tdStyle}>{row.make_model || ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          id="A-2.7: MACHINERY CONFIGURATION DURING TRIALS"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.7: MACHINERY CONFIGURATION DURING TRIALS
        </div>
        <div ref={divRefMachineConfDuringTest} className="overview-text">
          {surveyDataById?.machine_conf_during_test || ""}
        </div>
        <div
          id="A-2.8: WORST CASE FAILURE DESIGN INTENT"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-2.8: WORST CASE FAILURE DESIGN INTENT
        </div>
        <div ref={divRefWorstCaseFailure}>
          {surveyDataById?.worst_case_failure || ""}
        </div>
        <div
          id="A-3: Trials Summary"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-3: Trials Summary
        </div>
        <h4 style={{ fontSize: "18px" }}>
          *The Trial Summary shall be updated after the Trials
        </h4>
        <div>
          <div
            id="A-3.1: Documentation"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.1: Documentation
          </div>
          <RenderHtmlContent htmlContent={surveyDataById?.documentation} />
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.2: Machinery Maintenance"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.2: Machinery Maintenance
          </div>
          <RenderHtmlContent
            htmlContent={surveyDataById?.machinery_maintenance}
          />
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.3: DP System Maintenance"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.3: DP System Maintenance
          </div>
          <RenderHtmlContent
            htmlContent={surveyDataById?.dp_system_maintenance}
          />
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.4: Incidents"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.4: Incidents
          </div>
          <RenderHtmlContent htmlContent={surveyDataById?.incidents} />
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.5: Power Generation"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.5: Power Generation
          </div>
          <RenderHtmlContent htmlContent={surveyDataById?.power_generation} />
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.6: Power Distribution"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.6: Power Distribution
          </div>
          <RenderHtmlContent htmlContent={surveyDataById?.power_distribution} />
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.7: Power Management"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.7: Power Management
          </div>
          <RenderHtmlContent htmlContent={surveyDataById?.power_management} />
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.8: Control Loops"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.8: Control Loops
          </div>
          <RenderHtmlContent htmlContent={surveyDataById?.control_loops} />
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.9: Environmental and Heading Sensors"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.9: Environmental and Heading Sensors
          </div>
          <RenderHtmlContent
            htmlContent={surveyDataById?.envviroment_heading_sensors}
          />{" "}
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.10: Position References"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.10: Position References
          </div>
          <RenderHtmlContent
            htmlContent={surveyDataById?.position_references}
          />
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.11: DP Control"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.11: DP Control
          </div>
          <RenderHtmlContent htmlContent={surveyDataById?.dp_control} />
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.12: Personnel"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.12: Personnel
          </div>
          <RenderHtmlContent htmlContent={surveyDataById?.personal} />
          <div style={{ height: "20px" }}></div>
          <div
            id="A-3.13: Trials Conclusions"
            className="overview-heading"
            style={{ marginTop: "10px" }}
          >
            A-3.13: Trials Conclusions
          </div>
          <RenderHtmlContent htmlContent={surveyDataById?.trials_conclusions} />
          <div style={{ height: "20px" }}></div>
        </div>
        <div
          id="Repeat Findings (Still Open) from Previous DP Trials"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-4: Repeat Findings (Still Open) from Previous DP Trials
        </div>
        <div id="Repeat Findings (Still Open) from Previous DP Trials">
          At the time of the DP trials covered by this report the following
          findings were considered to still be open following review of previous
          DP trials reports and FMEA documents, as well as the associated close
          out documentation:
        </div>
        <div style={{ overflowX: "scroll", marginTop: "20px" }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "70%" }}>
                  Finding Category and Description
                </th>
                <th style={{ ...thStyle, width: "30%" }}>Finding Date</th>
              </tr>
            </thead>
            <tbody>
              {["findingA", "findingB", "findingC"]?.map((category) => (
                <>
                  <tr key={`${category}-header`}>
                    <td
                      style={{ ...headerStyle, width: "70%" }}
                    >{`Category ${category.slice(-1)}`}</td>
                    <td style={{ ...headerStyle, width: "30%" }}></td>
                  </tr>
                  <tr key={`${category}-input`}>
                    <td style={{ ...cellStyle, width: "70%" }}>
                      {surveyDataById?.findings?.[category]?.details || "N/A"}
                    </td>
                    <td style={{ ...cellStyle, width: "30%" }}>
                      {surveyDataById?.findings?.[category]?.date
                        ? convertDateToDDMMYYYY(
                            surveyDataById?.findings?.[category]?.date,
                          )
                        : null}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          Any open findings have been included in the findings contained under
          Section A-5: Findings.
        </div>
        <div
          id="A-5: Findings"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          A-5: Findings
        </div>
        <div>
          <p>Findings are grouped under three headings:</p>
          <ul>
            <li>A – For immediate attention;</li>
            <li>B – For action when reasonably convenient;</li>
            <li>C – For future attention/consideration.</li>
          </ul>

          <p>
            Findings from previous DP FMEA and annual DP trials which have not
            been closed out at the conclusion of these trials are to be included
            with the original date of the finding.
          </p>
        </div>
        <div className="overview-heading" style={{ marginTop: "10px" }}>
          A-5.1: A - Findings
        </div>
        <RenderHtmlContent htmlContent={surveyDataById?.editor_value_A || ""} />
        <div className="overview-heading" style={{ marginTop: "10px" }}>
          A-5.2: B - Findings
        </div>
        <RenderHtmlContent htmlContent={surveyDataById?.editor_value_B || ""} />
        <div className="overview-heading" style={{ marginTop: "10px" }}>
          A-5.3: C - Findings
        </div>
        <RenderHtmlContent htmlContent={surveyDataById?.editor_value_C || ""} />
        <div>
          The evidence for Closing-Out of these Findings can be found in Section
          C at the end of this document.
        </div>
        <div
          id="Section B: Trials Records"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          Section B: Trials Records
        </div>
        <div
          id="B-1: Document and Maintenance Audit"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1: Document and Maintenance Audit
        </div>
        <div
          id="B-1.1: Machinery maintenance"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.1: Machinery maintenance
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                {surveyDataById?.machineryMaintenanceColumns?.map(
                  (col, index) => (
                    <th
                      key={col.id}
                      style={{
                        ...headerStyle,
                        width: col.width,
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      {col.label || ""}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {surveyDataById?.machineryMaintenanceRows?.map(
                (row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, colIndex) => (
                      <td
                        key={colIndex}
                        style={{
                          ...(colIndex === 0 ? thStyle : cellStyle),
                          ...(colIndex === 0
                            ? { backgroundColor: "#f2f2f2" }
                            : { backgroundColor: "white" }),
                        }}
                      >
                        <span>{cell}</span>
                      </td>
                    ))}
                  </tr>
                ),
              )}
              <tr>
                <td style={thStyle}>Remarks :</td>
                <td
                  colSpan={
                    surveyDataById?.machineryMaintenanceColumns?.length - 1
                  }
                  style={cellStyle}
                >
                  <span>
                    {surveyDataById?.machineryMaintainenceRemarks || ""}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                {surveyDataById?.mainEnginesColumns?.map((col, index) => (
                  <th
                    key={col.id}
                    style={{
                      ...headerStyle,
                      width: col.width,
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    {col.label || ""}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {surveyDataById?.mainEnginesRows?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <td
                      key={colIndex}
                      // style={colIndex === 0 ? thStyle : cellStyle}
                      style={{
                        ...(colIndex === 0 ? thStyle : cellStyle),
                        ...(colIndex === 0
                          ? { backgroundColor: "#f2f2f2" }
                          : { backgroundColor: "white" }),
                      }}
                    >
                      <span>{cell}</span>
                    </td>
                  ))}
                </tr>
              ))}
              {/* Remarks row */}
              <tr>
                <td style={thStyle}>Remarks :</td>
                <td
                  colSpan={surveyDataById?.mainEnginesColumns?.length - 1}
                  style={cellStyle}
                >
                  <span>{surveyDataById?.mainEnginesRemarks || ""}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="B-1.2: Switchboard Maintenance"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.2: Switchboard Maintenance
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "25%" }}>Report Number</th>
                <th style={{ ...thStyle, width: "25%" }}>Date</th>
                <th style={{ ...thStyle, width: "50%" }}>Service Company</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ ...thStyle, width: "25%" }}>Work Completed</td>
                <td style={{ ...cellStyle, width: "25%" }}>
                  {surveyDataById?.switchboard_maintenance?.workCompletedDate
                    ? convertDateToDDMMYYYY(
                        surveyDataById?.switchboard_maintenance
                          ?.workCompletedDate,
                      )
                    : null}
                </td>
                <td style={{ ...cellStyle, width: "50%" }}>
                  {
                    surveyDataById?.switchboard_maintenance
                      ?.workCompletedServiceCompany
                  }
                </td>
              </tr>
              <tr>
                <td style={{ ...thStyle, width: "25%" }}>
                  Modifications since last Trials
                </td>
                <td style={{ ...cellStyle, width: "25%" }}>
                  {surveyDataById?.switchboard_maintenance?.modificationsDate
                    ? convertDateToDDMMYYYY(
                        surveyDataById?.switchboard_maintenance
                          ?.modificationsDate,
                      )
                    : null}
                </td>
                <td style={{ ...cellStyle, width: "50%" }}>
                  {
                    surveyDataById?.switchboard_maintenance
                      ?.modificationsServiceCompany
                  }
                </td>
              </tr>
              <tr>
                <td style={{ ...thStyle, width: "25%" }}>Comments</td>
                <td colSpan={2} style={{ ...cellStyle, width: "75%" }}>
                  {surveyDataById?.switchboard_maintenance?.comments}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="B-1.3: DP System Maintenance"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.3: DP System Maintenance
        </div>
        <div>
          Dates should be the last recorded at the Planned Maintenance System or
          DP Maintenance Logbook.
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "100%" }} colSpan="3">
                  DP SENSORS & REFERENCE SYSTEMS
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "33.33%" }}>SENSOR</th>
                <th style={{ ...thStyle, width: "33.33%" }}>RECORD CHECKED</th>
                <th style={{ ...thStyle, width: "33.33%" }}>REMARKS</th>
                {/* Empty header for remove button column */}
              </tr>
            </thead>
            <tbody>
              {/* Static Rows */}
              {surveyDataById?.dp_sensor_reference_system?.map((row, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...cellStyle,
                      width: "33.33%",
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    {row.sensor}
                  </td>
                  <td style={{ ...cellStyle, width: "33.33%" }}>
                    {row.record_checked}
                  </td>
                  <td style={{ ...cellStyle, width: "33.33%" }}>
                    {row.remarks}
                  </td>
                </tr>
              ))}

              <tr>
                <td
                  style={{
                    ...cellStyle,
                    width: "33.33%",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Remarks :
                </td>
                <td style={cellStyle} colSpan="2">
                  {surveyDataById?.dp_sensor_general_remark}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="DP Computers" style={{ overflowX: "scroll" }}>
          <table style={{ ...tableStyle, marginTop: "20px" }}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "100%" }} colSpan="2">
                  DP COMPUTERS
                </th>
              </tr>
            </thead>
            <tbody>
              {surveyDataById?.dp_computer?.slice(0, -1).map((row, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...cellStyle,
                      width: "50%",
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    {row.label}
                  </td>

                  <td style={{ ...cellStyle, width: "50%" }}>{row.value}</td>
                </tr>
              ))}
              <tr>
                <td
                  style={{
                    ...cellStyle,
                    width: "50%",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Remarks
                </td>
                <td style={{ ...cellStyle, width: "50%" }}>
                  {
                    surveyDataById?.dp_computer?.[
                      surveyDataById?.dp_computer?.length - 1
                    ]?.value
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="B-1.4: Battery Maintenance"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.4: Battery Maintenance
        </div>
        <div>
          Dates should be the last recorded at the Planned Maintenance System or
          Battery Maintenance Logbook.
        </div>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th
                style={{ ...thStyle, width: "25%", backgroundColor: "#f2f2f2" }}
              >
                Unit
              </th>
              <th style={{ ...thStyle, width: "50%" }} colSpan="2">
                Battery Maintenance Dates
              </th>
              <th
                style={{ ...thStyle, width: "25%", backgroundColor: "#f2f2f2" }}
              >
                Remarks
              </th>
            </tr>
            <tr>
              <th
                style={{ ...thStyle, width: "25%", backgroundColor: "#f2f2f2" }}
              ></th>
              <th style={{ ...thStyle, width: "25%" }}>Installed</th>
              <th style={{ ...thStyle, width: "25%" }}>Last discharge test</th>
              <th
                style={{ ...thStyle, width: "25%", backgroundColor: "#f2f2f2" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {surveyDataById?.battery_maintenance?.map((row, index) => (
              <tr key={index}>
                <td
                  style={{
                    ...cellStyle,
                    width: "25%",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  {row.unit || ""}
                </td>
                <td style={{ ...cellStyle, width: "25%" }}>
                  {row.installed || ""}
                </td>
                <td style={{ ...cellStyle, width: "25%" }}>
                  {row.last_discharge_test || ""}
                </td>
                <td style={{ ...cellStyle, width: "25%" }}>
                  {row.remarks || ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          id="B-1.5: Checklists and DP Related Documentation"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.5: Checklists and DP Related Documentation
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <tbody>
              {surveyDataById?.checklist_dp_related_doc?.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...thStyle,
                      width: "50%",
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    {item.label}
                  </td>
                  <td style={{ ...cellStyle, width: "50%" }}>{item.value}</td>
                </tr>
              ))}
              <tr>
                <td style={{ ...thStyle, width: "50%" }}>Remarks :</td>
                <td style={{ ...cellStyle, width: "50%" }}>
                  {surveyDataById?.checkListDPRemarks}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="B-1.6: HARDWARE MODIFICATIONS"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.6: HARDWARE MODIFICATIONS
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <tbody>
              {surveyDataById?.hardware_modification?.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...thStyle,
                      width: "50%",
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    {item.label}
                  </td>
                  <td style={{ ...cellStyle, width: "50%" }}>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          id="B-1.7: CAPABILITY PLOTS"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.7: CAPABILITY PLOTS
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <tbody>
              {surveyDataById?.capability_plot?.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...thStyle,
                      width: "50%",
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    {item.label}
                  </td>
                  <td style={{ ...cellStyle, width: "50%" }}>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          id="B-1.8: DP INCIDENTS"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.8: DP INCIDENTS
        </div>
        <table style={tableStyle}>
          <tbody>
            {surveyDataById?.dp_incident?.map((row, index) => (
              <tr key={index}>
                <td
                  style={{
                    ...thStyle,
                    width: "50%",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  {row.question}
                </td>
                <td style={{ ...cellStyle, width: "50%" }}>{row.details}</td>
              </tr>
            ))}
            <tr>
              <td style={{ ...thStyle, width: "50%" }}>Remarks:</td>
              <td style={{ ...cellStyle, width: "50%" }}>
                {surveyDataById?.dpIncidentRemarks}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          id="B-1.9: DP Training of Personnel"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.9: DP Training of Personnel
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "20%" }}>Position</th>
                <th style={{ ...thStyle, width: "20%" }}>Certificate Type</th>
                <th style={{ ...thStyle, width: "20%" }}>Certificate Number</th>
                <th style={{ ...thStyle, width: "20%" }}>Date Issued</th>
                <th style={{ ...thStyle, width: "20%" }}>Issued by</th>
              </tr>
            </thead>
            <tbody>
              {surveyDataById?.dp_traning_personnel?.map((row, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...thStyle,
                      width: "20%",
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    {row.position}
                  </td>
                  <td style={{ ...cellStyle, width: "20%" }}>
                    {row.certificate_type}
                  </td>
                  <td style={{ ...cellStyle, width: "20%" }}>
                    {row.certificate_number}
                  </td>
                  <td style={{ ...cellStyle, width: "20%" }}>
                    {row.date_issued
                      ? convertDateToDDMMYYYY(row.date_issued)
                      : null}
                  </td>
                  <td style={{ ...cellStyle, width: "20%" }}>
                    {row.issued_by}
                  </td>
                </tr>
              ))}

              <tr>
                <td style={{ ...thStyle, width: "20%" }}>Remarks</td>
                <td colSpan="4" style={{ ...cellStyle }}>
                  {surveyDataById?.dp_training_personnel_comments}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="B-1.10: PRE-TRIAL CHECKS"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-1.10: PRE-TRIAL CHECKS
        </div>
        <div style={{ overflowX: "scroll" }}>
          <table style={tableStyle}>
            <tbody>
              {surveyDataById?.pre_trial_check?.map((row, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...thStyle,
                      width: "48%",
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    {row.label}
                  </td>

                  <td style={{ ...cellStyle, width: "49%" }}>{row.value}</td>
                </tr>
              ))}
              <tr>
                <td style={{ ...thStyle, fontWeight: "bold" }}>Remarks</td>
                <td colSpan="1" style={{ ...cellStyle }}>
                  {surveyDataById?.preTrialCheckremarks || ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          id="B-2: DP TRIAL TEST SCHEDULE"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          B-2: DP TRIAL TEST SCHEDULE
        </div>
        <RenderHtmlContent
          htmlContent={surveyDataById?.dp_trial_test_schedule}
        />
        <div style={{ height: "30px" }}></div>
        <FMEATestView tableData={testTableData} testDetails={testDetails} />
        <div
          id="B-2: DP TRIAL TEST SCHEDULE"
          className="overview-heading"
          style={{ marginTop: "10px" }}
        >
          APPENDIX C – OBSERVATIONS CLOSE OUT
        </div>
        <div>
          <RenderHtmlContent
            htmlContent={surveyDataById?.editorValueObservationsCloseOut}
          />
        </div>
      </div>
    </>
  );
};
const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  marginBottom: "20px",
};
const tdStyle = {
  border: "1px solid black",
  padding: "8px",
  textAlign: "left",
};

const thStyle = {
  ...tdStyle,
  backgroundColor: "#f2f2f2",
  fontWeight: "bold",
};
const headerStyle = {
  backgroundColor: "#f2f2f2",
  padding: "10px",
  borderBottom: "1px solid black",
  textAlign: "left",
  fontWeight: "bold",
};

const cellStyle = {
  border: "1px solid black",
  padding: "8px",
  textAlign: "left",
  width: "16.66%", // Added this line to make all cells equal width
};

const crewHeaderStyle = {
  ...headerStyle,
  width: "50%",
};

const crewCellStyle = {
  ...cellStyle,
  width: "50%",
};

const removeTd = {
  border: "none",
};

export default ViewPage;
