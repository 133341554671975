const ArrowSubmit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      fill="#fff"
      width="18"
      height="18"
    >
      <path d="M5.279,6.693c-.383-.398-.371-1.031,.027-1.414L9.893,.865c1.105-1.106,3.095-1.12,4.229,.014l4.572,4.4c.398,.383,.41,1.016,.027,1.414-.196,.204-.458,.307-.721,.307-.25,0-.5-.093-.693-.279L13,2.575v13.425c0,.552-.448,1-1,1s-1-.448-1-1V2.575L6.693,6.721c-.397,.383-1.029,.371-1.414-.027Zm15.721,1.307c-.552,0-1,.448-1,1v10c0,1.654-1.346,3-3,3H7c-1.654,0-3-1.346-3-3V9c0-.552-.448-1-1-1s-1,.448-1,1v10c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V9c0-.552-.448-1-1-1Z" />
    </svg>
  );
};
export default ArrowSubmit;
