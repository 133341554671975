import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import BackBtn from "../../../../atoms/BackBtn/index.jsx";
import Overview from "../../../../organisms/template/Overview/index.jsx";
import CustomButton from "../../../../atoms/CustomButton/index.jsx";
import "./styles.scss";
import SideSearchBar from "../../../../molecules/SideSearchBar/index.jsx";
import { NavigationData } from "../../../../../helpers/staticData.js";
import { getData, patchData, postData } from "../../../../../services/index.js";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/toast.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Tick from "../../../../icons/Tick.jsx";
import CircleAvatar from "../../../../atoms/CircleAvatar/index.jsx";
import { Menu, MenuItem } from "@mui/material";
import LogoutModal from "../../../../organisms/modals/LogoutModal.jsx";
import PageHeader from "../../../../atoms/PageHeader/index.jsx";
import Modal from "../../../../organisms/modals/Modal.jsx";
import ArrowSubmit from "../../../../icons/ArrowSubmit.jsx";
import useScreenWidth from "../../../../../hooks/useScreenwidth.js";

const AddEmployee = () => {
  const [cookies] = useCookies(["role", "t", "email", "survey_id"]);
  const [, , removeCookies] = useCookies([
    "t",
    "st",
    "un",
    "email",
    "uid",
    "role",
  ]);
  const { id } = useParams();
  const screenWidth = useScreenWidth();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState();
  const [navigationClicked, SetNavigationClicked] = useState();
  const [surveyDataById, SetSurveyDataById] = useState({});
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [finalModalSubmit, setFinalModalSubmit] = useState({
    isModalOpen: false,
    modalId: "",
  });
  const [amcaFrontPage, setAMCAFrontPage] = useState({
    surveyName: "",
    vesselName: surveyDataById?.vessel_name || "",
    fmeaTrial: surveyDataById?.fmea_trial || "",
    clientName: surveyDataById?.client_name || "",
    preparedBy: surveyDataById?.prepared_by || "",
    docNo: surveyDataById?.doc_no || "",
    revNo: surveyDataById?.rev_no || "",
    date: surveyDataById?.date || "",
  });
  const [vesselLayoutImages, setVesselLayoutImages] = useState();
  const [isSurveyNameModal, setIsSurveyNameModal] = useState({
    isModalOpen: false,
    modaldata: "",
  });
  const [testsNavigation, setTestNavigation] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const closeFinalSubmitModal = () => {
    setFinalModalSubmit(() => ({
      isModalOpen: false,
      modalId: "",
    }));
  };

  const [vesselGeneralTableData, setVesselGeneralTableData] = useState({
    type: "",
    class: "",
    length: "",
    speed: "",
    builder: "",
    breath: "",
    flag: "",
    built: "",
    draft: "",
    imo_no: "",
    owner: "",
    dead_weight: "",
  });

  const [summaryDataState, setSummaryDataState] = useState({
    "Enter Requestor": "",
    "Enter Client Name": "",
    "Enter Vessel Name": "",
    "Enter Certified Person Name": "",
    "Enter Location": "",
    "Enter Trials Location": "",
    "Enter Date": "",
    "Enter AMCA Witness": "",
    "Enter IRS Witness": "",
  });
  const [instructionDataState, setInstructionDataState] = useState({
    "Enter Vessel Name": "",
    "Enter Client Name": "",
    "Enter Requestor": "",
  });

  const [orderOfTrialState, setOrderOfTrialState] = useState({
    "Enter trial DP": "",
    "Enter Start Approximately": "",
    "Enter Start Approximately on": "",
    "Enter End Time": "",
    "Enter End Date": "",
    "Enter Location": "",
    "Enter Depth": "",
    "Enter Weather Condition": "",
    "Enter Sea State": "",
  });

  const [VesselGeneralState, setVesselGeneralState] = useState({
    "Enter Vessel Name": "",
    "Enter Year Built": "",
    "Enter Vessel": "",
    "Enter Builder & Location": "",
    "Enter Class Notation": "",
    "Enter Number of Bow Tunnel Thrusters": "",
    "Enter Number of Stern Tunnel Thrusters": "",
    "Enter Type of Main Propeller": "",
  });

  const [disclaimerData, setDisclaimerData] = useState(
    `AMCA Marine Consulting, its affiliates, employees or any person acting on behalf or otherwise in reference to the performance of this analysis does not:
1.	Warrant or represent with respect to the accuracy of completeness or usefulness of the information contained herein, or that the use of any information, method or process contained herein, may not infringe on privately owned rights.
2.	Assume any liability with respect to the use of, or for special incidental or consequential damages related to or arising directly out of the use of any information, report, method, apparatus or process disclosed herein.
AMCA Marine Consulting has made every reasonable effort to derive this report contained herein consistent with IMO, Classification Society regulations, IMCA and the best marine practices. In addition, AMCA Marine Consulting regards this report as being advisory in nature. The responsibility for use and implementation of the recommendations or conclusions herein rests entirely with the client.
All DP test results have been recorded as witnessed on board during the DP trials. The observed test results and comments contained herein are to be interpreted in accordance with the limitations as set out in the vessel specific CAM, TAM, ASOG and DP capability plots. The final decision relating to the implementing of the results of these tests lies with the Senior DPO on board and the vessel Master.

`,
  );
  const [summaryData, setSummaryData] = useState(
    `AMCA Marine Consulting was requested by {{Enter Requestor}}, on behalf of {{Enter Client Name}} to Prepare the FMEA Verification Trial Protocol and witness the Trials for the vessel {{Enter Vessel Name}}. {{Enter Certified Person Name}}, a certified IMCA DP Trials and Assurance Practitioner, attended to the vessel at {{Enter Location}}. The trials were conducted at {{Enter Trials Location}} on {{ Enter Date}} in accordance with this document. All trials were coordinated by the vessel’s master or his nominee and witnessed by {{Enter AMCA Witness}} of AMCA Marine Consulting and {{Enter IRS Witness}} of IRS. The Summary shall be updated after the trials.`,
  );
  const [instructionData, setInstructionData] = useState(
    `AMCA Marine Consulting was requested to witness the FMEA Proving Trial of the vessel {{Enter Vessel Name}} Instructions were received from {{Enter Requestor}}, on behalf of {{Enter Client Name}}.`,
  );
  const [orderOfTrial, setOrderOfTrial] =
    useState(`{{Enter trial DP}}, a DP Trials and Assurance Practitioner, representing AMCA Marine, witnessed the DP FMEA Proving Trial and noted relevant results, alarms and printouts of various tests and disconnections as required. 

Trials commenced at approximately {{Enter Start Approximately}} on {{Enter Start Approximately on}} and were completed at approximately {{Enter End Time}} on {{Enter End Date}} 

The trials were carried out at {{Enter Location}}. The water depth at the location was around {{Enter Depth}} below the keel. 

The weather conditions were recorded on individual test sheets as required. Throughout the trials the weather condition was {{Enter Weather Condition}}, and seas were {{Enter Sea State}}

During the trials, all DP equipment was available.

The DP Performance Trials part of the DP trials programme will be completed yearly and will test the general performance of the DP System. As per IMCA guidelines, the DP FMEA Redundancy Trials part of the DP trials programme will be completed through a cycle of four years, with a quarter of the tests done each year. DP FMEA Verification Trials will be completed during the fifth year, during which the DP Performance Trials part and the complete DP FMEA Redundancy Trials part will be completed.`);
  const [scopeOfWork, setScopeOfWork] = useState(
    `The DP FMEA verification trials programme has been developed from the FMEA of the vessel’s DP system and the previous DP Trials. The trials are intended to verify the vessel’s DP FMEA and to show that the vessel meets the requirements of IMO, the rules and regulations of IRS  and the guidelines of IMCA.

IMO MSC/Circ. 645 states that “The annual survey should ensure that the DP system has been maintained in accordance with the applicable parts of the guidelines and is in good working order. Further, an annual test of all important systems and components should be carried out to document the ability of the DP vessel to keep position after single failures associated with the assigned equipment class.”
`,
  );

  const [refAndAssPub, setRefAndAssPub] =
    useState(`The following publications have been referenced in writing this document:
•   IRS Rules for classification of ships Part 5 Chapter 24 - Dynamic Positioning Systems 
•   IMO, Guidelines for vessels with dynamic positioning systems, IMO MSC/Circ. 645; 1994
•   IMO, Guidelines for Vessels and Units with Dynamic Positioning Systems, IMO MSC.1/Circ.1580
•   International Marine Contractors Association (IMCA)
    Guidelines for the Design and Operation of Dynamically Positioned Vessels
    IMCA M 103, Rev. 5.2; Apr 2024
•   International Marine Contractors Association (IMCA) 
    Guidance on Failure Mode and Effect Analyses (FMEAs) 
    IMCA M 166, Rev. 3; Mar 2024
•   International Marine Contractors Association (IMCA) 
    A guide to DP-related documentation for DP vessels 
    IMCA M 109, Rev. 4.0; Dec 2023
•   International Marine Contractors Association (IMCA)
    Code of practice for developing and conducting DP annual trials programmes
    IMCA M 190A, M191 & M212 Rev. 3.2; May 2024
•   International Marine Contractors Association (IMCA)
    A guide to DP electrical power and control systems.
    IMCA M 206, Rev. 2.1; Nov 2023
•   Marine Technology Society Dynamic Positioning Committee
    DP Operations Guidance Part 2, Rev. 3.0; Apr 2021`);

  const [reqDuringTest, setReqDuringTest] = useState(
    `The client (vessel operator) will appoint a trials coordinator to schedule the necessary resources and organize the conduct of the DP trials programme. This person must not be the DP surveyor.

During the trials, all relevant shipboard equipment is required to be fully operational. In particular, all propulsion units and their controls, both manual and automatic, all power generation equipment, all computer systems and all position reference systems must be fully functional, including their alarms, standby units, battery backups, shutdowns, trips, etc.

All trials will be conducted with the approval of the master and with full regard to the safe navigation of the vessel.

The trials co-coordinator (not the DP surveyor) will satisfy themselves by whatever means necessary that a test can be conducted safely and any test that cannot be conducted safely will be cancelled. A suitable and sufficient risk assessment should also be carried out by the vessel’s staff for any tests where there is a risk of equipment damage even if that test can be conducted safely. An unsatisfactory outcome will be assumed for any test cancelled on the grounds of safety or equipment damage until proven otherwise.

Unless otherwise stated all tests will be carried out on full DP, in realistic environmental conditions, or with some varying load on the system induced by movements of the vessel.

During the trials, the vessel’s staff will assist as required in recording alarms and failures locally. Locally means not only at the DP console but also at the ECR, the thruster room, etc.

Following failure tests, the system must not be reinstated until the DP operators, ECR staff and witnesses are satisfied they understand the full effects of the failure and that all the information or indicators that show what has occurred have been noted.

When reinstating systems after failure simulations, it must be ensured that all equipment has been configured correctly, breakers reset, power supplies re-established and cables re-connected. Only when everyone involved is satisfied that the system has been reset and has Stabilized will the trials continue.

If there are any doubts about a test, it will be repeated. If test results are unexpected, then the test will also be repeated. It should be noted that seemingly small or spurious faults in DP control systems may be the first manifestations of a more serious problem.

Tests will proceed only when all those involved have been informed and (where necessary) suitable communications have been set up, e.g., DP console to thruster room.

The tests will not only prove hardware redundancy and DP capability after failures but also that the operators and maintainers have the necessary training and experience to use the system and deal successfully with such failures.


     `,
  );
  const [VesselGeneral, setVesselGeneral] =
    useState(`{{Enter Vessel Name}} is a {{Enter Year}} built {{Enter Vessel Type}}. She was built in{{ Enter Builder & Location}}. The vessel is classed by {{Enter Class Notation}}. 

She has {{Enter Number of Bow Tunnel Thrusters}} Bow tunnel thrusters, {{Enter Number of Stern Tunnel Thrusters}} Stern Tunnel Thrusters and Two (2) {{Enter Type of Main Propeller}} driven by main engine through a step down gear box. The vessel details are as follows:`);
  const [machineConfDuringTest, setMachineConfDuringTest] =
    useState(`The operating configurations are #numberofengine main engines running, supply propulsive power to the two main propeller, with the connected shaft generators supplying all of the ship’s power requirements.
All thrusters on-line, with the main 440Vac switchboards operating as independent systems (Bus-tie breakers open).
The following configurations are to be provided during the trials as stated in the DP FMEA:

•	Main Engines Port and Stbd running and both CPP online
•	Port Shaft Generator (SG1) powers SG 1 Bus 
•	Stbd Shaft Generator (SG) powers SG 2 Bus
•	BT1 and ST powered by SG1 Bus
•	BT2 powered by Stbd SG2 Bus
•	DG 1 running and Supplying power to 440V Main Bus Bar 1 
•	440 V ESB connected to Main Bus Bar 1
•	DG 2 running and Supplying power to 440V Main Main Bus 2.
•	Tie Breaker (CB3) between SG1 Main Bus Bar 1and the HC1 - CLOSED
•	Tie Breaker (CB7) between SG2 Main Bus Bar 1and the HC2 - CLOSED
•	Tie Breaker (CB4) between 440V Main Bus Bar 1 and SG1 - OPEN
•	Tie Breaker (CB6) between 440V Main Main Bus 2 and SG2 - OPEN
•	Tie Breaker (MCCB) between 440V Main Bus Bar 1 and 440V Main Main Bus 2 - OPEN
•	Tie Breaker between (CB5) HC1 and HC2 - OPEN 
•	220 V Bus Bar 1 and 220 V Bus Bar 2 supplied from the 440 V Main Bus Bar 1 and 440 v Main Bus Bar 2 respectively
•	Tie Breaker between main Bus Bar 1 220 V and Main Bus Bar 2 220 V - OPEN
•	Emergency generator set to AUTO
•	FO Supplied to each Main Engine from Separate tanks and interconnection valves are closed
•	FO Supplied to each Auxilary Engine from Separate tanks and interconnection valves are closed
•	FW Misc. Pump No.1, on MSB Bus 1, in use. FW Misc. Pump No.2, on Bus 2, on stby . 
•	SW cooling Pump ME(P), on MSB Bus 1 and SW cooling Pump ME(S), on MSB Bus 2 in Operation. Stby Pump ME(P&S), on MSB Bus 1 on standby.
•	Stdby pumps set to auto standby.
`);
  const [worstCaseFailure, setWorstCaseFailure] =
    useState(`•	 Loss of either port or starboard Main Engine
The worst case failure for the DP 2 system for the #vesselname shall be the failure of the either of the main Engines (Port or Stbd), resulting in failure of one of the propeller and the connected Shaft Generator Bus (SG1Bus or SG2 Bus). Such Failure will lead to the failure of the One propeller, One Bow Thruster and One supply to the Stern Thruster.
In case of worst case failure, the redundancy shall be lost and the position shall be maintained by #One (1) Bow Thruster, One (1) Propeller and One (1) Stern Thruster.
`);

  useEffect(() => {
    if (surveyDataById?._id) {
      setAMCAFrontPage(() => ({
        surveyName: surveyDataById?.survey_name,
        vesselName: surveyDataById?.vessel_name,
        fmeaTrial: surveyDataById?.fmea_trial,
        clientName: surveyDataById?.client_name,
        preparedBy: surveyDataById?.prepared_by,
        docNo: surveyDataById?.doc_no,
        revNo: surveyDataById?.rev_no,
        date: surveyDataById?.date,
      }));
      setSummaryData(() => surveyDataById?.summary);
      setDisclaimerData(() => surveyDataById?.disclaimer);
      setInstructionData(() => surveyDataById?.instruction);
      setScopeOfWork(() => surveyDataById?.scope_of_work);
      setRefAndAssPub(
        () => surveyDataById?.referenced_and_associated_publications,
      );
      setVesselGeneral(() => surveyDataById?.vessel_general);
      setCrewData(() => surveyDataById?.key_personnel_attendance_Trial);
      setOrderOfTrial(() => surveyDataById?.order_of_trial);
      setReqDuringTest(() => surveyDataById?.requirements_during_testing);
      setEngineAndAuxiliary(
        () => surveyDataById?.auxiliary_diesel_engine_generator,
      );
      setThrusterPropulsion(() => surveyDataById?.thrusters_and_Propulsion);
      setElectricDitribution(() => surveyDataById?.electric_distribution);
      setEquipmentRows(() => surveyDataById?.dp_system_equipment);
      setPrsRows(() => surveyDataById?.position_reference_system);
      setSensorRows(() => surveyDataById?.dp_system_sensor);
      setDpSystemMaintain(() => surveyDataById?.dp_sensor_reference_system);
      setDpComputers(() => surveyDataById?.dp_computer);
      setbatteryMaintenance(() => surveyDataById?.battery_maintenance);
      setCheckListDP(() => surveyDataById?.checklist_dp_related_doc);
      setHardWareModification(() => surveyDataById?.hardware_modification);
      setCapabilityPlots(() => surveyDataById?.capability_plot);
      setDPIncident(() => surveyDataById?.dp_incident);
      setDPTrainingPersonal(() => surveyDataById?.dp_traning_personnel);
      setPreTrialCheck(() => surveyDataById?.pre_trial_check);
      setPreTrialCheckRemarks(() => surveyDataById?.preTrialCheckremarks);
      setMainEnginesRows(() => surveyDataById?.mainEnginesRows);
      setMainEnginesColumns(() => surveyDataById?.mainEnginesColumns);
      setMachineryMaintenanceRows(
        () => surveyDataById?.machineryMaintenanceRows,
      );
      setMachineryMaintenanceColumns(
        () => surveyDataById?.machineryMaintenanceColumns,
      );

      setDocRevRecords(() =>
        createDocRecords(surveyDataById?.docs_revision_record_number),
      );
      setAmendmentRecords(() =>
        createAmendentRecords(surveyDataById?.amendment_record_number),
      );
      if (
        surveyDataById?.document_revision_record?.some((obj) => {
          return Object?.values(obj)?.some((value) => value !== "");
        })
      ) {
        if (surveyDataById?.docs_revision_record_number) {
          setDocRevRecords(() => surveyDataById?.document_revision_record);
        }
        if (surveyDataById?.amendment_record_number) {
          setAmendmentRecords(() => surveyDataById?.amendment_record);
        }
      }
      setContent(() => ({
        doc: surveyDataById?.documentation,
        maintenance: surveyDataById?.machinery_maintenance,
        dpSystem: surveyDataById?.dp_system_maintenance,
        incidents: surveyDataById?.incidents,
        powerGen: surveyDataById?.power_generation,
        powerDist: surveyDataById?.power_distribution,
        powerMgmt: surveyDataById?.power_management,
        controlLoops: surveyDataById?.control_loops,
        envSensors: surveyDataById?.envviroment_heading_sensors,
        posRefs: surveyDataById?.position_references,
        dpControl: surveyDataById?.dp_control,
        personnel: surveyDataById?.personal,
        trialsConclusions: surveyDataById?.trials_conclusions,
      }));
      setMainEnginesRemarks(() => surveyDataById?.mainEnginesRemarks);
      setMachineryMaintainenceRemarks(
        () => surveyDataById?.machineryMaintainenceRemarks,
      );
      setSwitchboardMaintenance(() => ({
        // modificationsDate: surveyDataById?.
        comments: surveyDataById?.switchboard_maintenance_comments,
      }));
      setGeneralRemarks(() => surveyDataById?.dp_sensor_general_remark);
      setCheckListDPRemarks(() => surveyDataById?.checkListDPRemarks);
      if (surveyDataById?.trial_template === null) {
        setTestDetails({});
      } else if (Object.keys(surveyDataById?.trial_template).length) {
        setTestDetails(() => surveyDataById?.trial_template);
      }
      if (surveyDataById?.test_tableData === null) {
        setTableData({});
      } else if (Object.keys(surveyDataById?.trial_template).length) {
        setTableData(() => surveyDataById?.test_tableData);
      }
      setDpIncidentRemarks(() => surveyDataById?.dpIncidentRemarks);
      setDpTraningPersonalRemarks(
        () => surveyDataById?.dp_training_personnel_comments,
      );
      setEditorValueDpTrialTestSchedule(
        () => surveyDataById?.dp_trial_test_schedule,
      );
      setVesselGeneralTableData(
        () => surveyDataById?.vessel_particulatars_first_table,
      );
      setSummaryDataState(() => ({
        "Enter Requestor": surveyDataById?.summary_requested_by,
        "Enter Client Name": surveyDataById?.summary_behalf,
        "Enter Vessel Name": surveyDataById?.summary_vessel_name,
        "Enter Certified Person Name": surveyDataById?.summary_certified,
        "Enter Date": surveyDataById?.summary_vessel_at,
        "Enter Location": surveyDataById?.summary_conducted_at,
        "Enter Trials Location": surveyDataById?.summary_conducted_on,
        "Enter AMCA Witness": surveyDataById?.summary_witnessed_by,
        "Enter IRS Witness": surveyDataById?.summary_amca_marine_consulting,
      }));
      setInstructionDataState(() => ({
        "Enter Requestor": surveyDataById?.Instruction_received_from,
        "Enter Client Name": surveyDataById?.Instruction_behalf_of,
        "Enter Vessel Name": surveyDataById?.Instruction_vessel_name,
      }));
      setOrderOfTrialState(() => ({
        "Enter trial DP": surveyDataById?.order_of_Trials_dp,
        "Enter Start Approximately":
          surveyDataById?.order_of_Trials_dp_commenced_approximately,
        "Enter Start Approximately on":
          surveyDataById?.order_of_Trials_dp_commenced_approximately_on,
        "Enter End Time":
          surveyDataById?.order_of_Trials_dp_completed_approximately,
        "Enter End Date":
          surveyDataById?.order_of_Trials_dp_completed_approximately_on,
        "Enter Location": surveyDataById?.order_of_Trials_carried_out_at,
        "Enter Depth": surveyDataById?.order_of_Trials_around,
        "Enter Weather Condition":
          surveyDataById?.order_of_Trials_weather_condition,
        "Enter Sea State": surveyDataById?.order_of_Trials_seas_were,
      }));
      setVesselGeneralState(() => ({
        "Enter Vessel Name": surveyDataById?.vessel_particulars_vessel_name,
        "Enter Year Built": surveyDataById?.vessel_particulars_years,
        "Enter Builder & Location":
          surveyDataById?.vessel_particulars_builder_location,
        "Enter Class Notation":
          surveyDataById?.vessel_particulars_classnotation,
        "Enter Number of Bow Tunnel Thrusters":
          surveyDataById?.vessel_particulars_bow_number,
        "Enter Number of Stern Tunnel Thrusters":
          surveyDataById?.vessel_particulars_thrusters_number,
        "Enter Type of Main Propeller":
          surveyDataById?.vessel_particulars_typeofmainpropeller,
      }));

      setEditorValueObservationsCloseOut(
        () => surveyDataById?.editorValueObservationsCloseOut,
      );
      setFindings(() => surveyDataById?.findings);
      setEditorValueA(() => surveyDataById?.editor_value_A);
      setEditorValueB(() => surveyDataById?.editor_value_B);
      setEditorValueC(() => surveyDataById?.editor_value_C);

      setDPTrainingPersonal(() => surveyDataById?.dp_traning_personnel);
      setSwitchboardMaintenance(() => surveyDataById?.switchboard_maintenance);
      setVesselLayoutImages(() => surveyDataById?.vesselLayoutImages);
    }
  }, [surveyDataById]);

  const createDocRecords = (num) => {
    return Array.from({ length: num }, () => ({
      rev: "",
      date: "",
      description: "",
      prep: "",
      review: "",
      appr: "",
    }));
  };
  const [docRevRecords, setDocRevRecords] = useState(createDocRecords(5));
  // Dummy data for Amendment Record Table
  const createAmendentRecords = (num) => {
    return Array.from({ length: num }, () => ({
      revision: "",
      description: "",
    }));
  };
  const [amendmentRecords, setAmendmentRecords] = useState(
    createAmendentRecords(5),
  );

  const [crewData, setCrewData] = useState([
    { position: "Captain", name: null },
    { position: "Chief Officer", name: null },
    { position: "Second Officer", name: null },
    { position: "Chief Engineer", name: null },
    { position: "Third Officer", name: null },
    { position: "ETO", name: null },
    { position: "Class Surveyor", name: null },
    { position: "DP Surveyor", name: null },
  ]);

  const [engineAndAuxiliary, setEngineAndAuxiliary] = useState([
    {
      me_number: "ME1",
      description: "Port Main Engine",
      rate_power: "",
      rated_speed: "",
      model: "",
    },
    {
      me_number: "ME2",
      description: "Starboard Main Engine",
      rate_power: "",
      rated_speed: "",
      model: "",
    },
    {
      me_number: "SG1",
      description: "Port Shaft Generator",
      rate_power: "",
      rated_speed: "",
      model: "",
    },
    {
      me_number: "SG2",
      description: "Starboard Shaft Generator",
      rate_power: "",
      rated_speed: "",
      model: "",
    },
    {
      me_number: "DG1",
      description: "No.1 Auxiliary Diesel Engine Generator",
      rate_power: "",
      rated_speed: "",
      model: "",
    },
    {
      me_number: "DG2",
      description: "No.2 Auxiliary Diesel Engine Generator",
      rate_power: "",
      rated_speed: "",
      model: "",
    },
  ]);

  const [thrusterPropulsion, setThrusterPropulsion] = useState([
    {
      id: "T1",
      type_and_location: "No.1 Bow Thruster",
      pitch: "",
      propeller_speed: "",
      model: "",
      drive: "",
    },
    {
      id: "T2",
      type_and_location: "No.2 Bow Thruster",
      pitch: "",
      propellerSpeed: "",
      model: "",
      drive: "",
    },
    {
      id: "T3",
      type_and_location: "Stern Thruster",
      pitch: "",
      propellerSpeed: "",
      model: "",
      drive: "",
    },
    {
      id: "T4",
      type_and_location: "Port Main Propeller",
      pitch: "",
      propellerSpeed: "",
      model: "",
      drive: "",
    },
    {
      id: "T5",
      type_and_location: "Stbd Main Propeller",
      pitch: "",
      propellerSpeed: "",
      model: "",
      drive: "",
    },
  ]);

  const [electricDistribution, setElectricDitribution] = useState([
    {
      id: "SG1",
      switch_board: "440Vac Switchboard SG 1 Bus",
      generator: "",
      engine: "",
      control: "",
    },
    {
      id: "SG2",
      switch_board: "440Vac Switchboard SG 2 Bus",
      generator: "",
      engine: "",
      control: "",
    },
    {
      id: "DG1",
      switch_board: "440Vac Switchboard Main Bus 1",
      generator: "",
      engine: "",
      control: "",
    },
    {
      id: "DG2",
      switch_board: "440Vac Switchboard Main Bus 2",
      generator: "",
      engine: "",
      control: "",
    },
  ]);

  const [equipmentRows, setEquipmentRows] = useState([
    { equipment: "DP Control System", make_model: "" },
    { equipment: "Independent Joystick System", make_model: "" },
  ]);

  const [prsRows, setPrsRows] = useState([
    { equipment: "DGPS 1", makeModel: "" },
    { equipment: "DGPS 2", makeModel: "" },
    { equipment: "SpotTrack", makeModel: "" },
  ]);

  const [sensorRows, setSensorRows] = useState([
    { equipment: "Gyro 1, 2 and 3", makeModel: "" },
    { equipment: "MRU 1, 2 and 3", makeModel: "" },
    { equipment: "Wind Sensor 1 & 2", makeModel: "" },
  ]);

  const [dpSystemMaintain, setDpSystemMaintain] = useState([
    { sensor: "GYRO 1", record_checked: "", remarks: "" },
    { sensor: "GYRO 2", record_checked: "", remarks: "" },
    { sensor: "GYRO 3", record_checked: "", remarks: "" },
    { sensor: "WIND 1", record_checked: "", remarks: "" },
    { sensor: "WIND 2", record_checked: "", remarks: "" },
    { sensor: "WIND 3", record_checked: "", remarks: "" },
    { sensor: "MRU 1", record_checked: "", remarks: "" },
    { sensor: "MRU 2", record_checked: "", remarks: "" },
    { sensor: "MRU 3", record_checked: "", remarks: "" },
    { sensor: "DGPS 1", record_checked: "", remarks: "" },
    { sensor: "DGPS 2", record_checked: "", remarks: "" },
    { sensor: "SpotTrack", record_checked: "", remarks: "" },
  ]);

  const [generalRemarks, setGeneralRemarks] = useState(""); // State for general remarks

  const [dpComputers, setDpComputers] = useState([
    { label: "Maintenance checked", value: "" },
    { label: "Last system software revision", value: "" },
    { label: "Remarks :", value: "" },
  ]);

  const [dpComputersRemarks, setdpComputersRemarks] = useState(""); // State for general remarks

  const [batteryMaintenance, setbatteryMaintenance] = useState([
    { unit: "DP UPS 1", installed: "", last_discharge_test: "", remarks: "" },
    { unit: "DP UPS 2", installed: "", last_discharge_test: "", remarks: "" },
  ]);

  const [checkListDP, setCheckListDP] = useState([
    { label: "Vessel specific DP operation manual", value: "" },
    { label: "DP maintenance records", value: "" },
    { label: "FMEA Documentation", value: "" },
    { label: "Previous trial documentation", value: "" },
    { label: "DP checklist / logs", value: "" },
  ]);

  const [checkListDPRemarks, setCheckListDPRemarks] = useState(""); // State for remarks

  const [hardwareModification, setHardWareModification] = useState([
    { label: "Any modifications since last trial?", value: "" },
    { label: "Have modifications been thoroughly tested?", value: "" },
    { label: "Have trial procedures been updated?", value: "" },
    { label: "Has the vessels DP FMEA been updated?", value: "" },
  ]);

  const [capabilityPlots, setCapabilityPlots] = useState([
    { label: "Are the correct capability plots onboard?", value: "" },
    { label: "Are there verifying footprints onboard?", value: "" },
  ]);

  const [dpIncident, setDPIncident] = useState([
    { question: "Have any incidents been recorded?", details: "" },
    { question: "If Yes, Number of incidents:", details: "" },
    {
      question: "Is there documented a satisfactory explanation?",
      details: "",
    },
  ]);

  const [dpIncidentRemarks, setDpIncidentRemarks] = useState(""); // State for remarks

  const [dpTraningPersonal, setDPTrainingPersonal] = useState([
    {
      position: "Master",
      certificate_type: "",
      certificate_number: "",
      date_issued: "",
      issued_by: "",
    },
    {
      position: "Chief Officer",
      certificate_type: "",
      certificate_number: "",
      date_issued: "",
      issued_by: "",
    },
    {
      position: "Second Officer",
      certificate_type: "",
      certificate_number: "",
      date_issued: "",
      issued_by: "",
    },
    {
      position: "Second Officer",
      certificate_type: "",
      certificate_number: "",
      date_issued: "",
      issued_by: "",
    },
    {
      position: "Chief Engineer",
      certificate_type: "",
      certificate_number: "",
      date_issued: "",
      issued_by: "",
    },
    {
      position: "Second Engineer",
      certificate_type: "",
      certificate_number: "",
      date_issued: "",
      issued_by: "",
    },
    {
      position: "ETO",
      certificate_type: "",
      certificate_number: "",
      date_issued: "",
      issued_by: "",
    },
  ]);

  const [dpTraningPersonalRemarks, setDpTraningPersonalRemarks] = useState([]);

  const [preTrialCheck, setPreTrialCheck] = useState([
    { label: "Position Error Warning and Alarm", value: "" },
    { label: "Heading Error Warning and Alarm", value: "" },
    { label: "Gyro Alignment", value: "" },
    { label: "MRU Alignment", value: "" },
    { label: "Wind Sensor Calibration", value: "" },
    { label: "Consequence Analysis Active", value: "" },
    { label: "DP Alarm Printer Operational / Tested", value: "" },
    { label: "Generator / Thruster Configuration", value: "" },
    { label: "Switchboard Configuration", value: "" },
    { label: "Fuel System split", value: "" },
    { label: "Thrusters Online", value: "" },
  ]);

  const [switchboardMaintenance, setSwitchboardMaintenance] = useState({
    workCompletedDate: "",
    workCompletedServiceCompany: "",
    modificationsDate: "",
    modificationsServiceCompany: "",
    comments: "",
  });
  const [preTrialCheckremarks, setPreTrialCheckRemarks] = useState("");

  const [machineryMaintenanceColumns, setMachineryMaintenanceColumns] =
    useState([
      { id: "col1", label: "PROPULSION & THRUSTERS", width: "25%" },
      { id: "col2", label: "BT 1", width: "15%" },
      { id: "col3", label: "BT 2", width: "15%" },
      { id: "col4", label: "ST", width: "15%" },
      { id: "col5", label: "Port CPP", width: "15%" },
      { id: "col6", label: "Stbd CPP", width: "15%" },
    ]);

  const [machineryMaintenanceRows, setMachineryMaintenanceRows] = useState([
    ["Maintenance record checked", "", "", "", "", ""],
    ["Outstanding maintenance", "", "", "", "", ""],
    ["Last oil analysis (date)", "", "", "", "", ""],
    ["Last oil analysis result", "", "", "", "", ""],
    ["Running hours at present", "", "", "", "", ""],
    ["Last major overhaul", "", "", "", "", ""],
    ["Next major overhaul", "", "", "", "", ""],
  ]);

  const [machineryMaintainenceRemarks, setMachineryMaintainenceRemarks] =
    useState("");

  const [mainEnginesColumns, setMainEnginesColumns] = useState([
    { id: "col1", label: "MAIN ENGINES AND DIESEL GENERATORS", width: "25%" },
    { id: "col2", label: "ME 1", width: "15%" },
    { id: "col3", label: "ME 2", width: "15%" },
    { id: "col4", label: "DG1", width: "15%" },
    { id: "col5", label: "DG 2", width: "15%" },
    { id: "col6", label: "EMER. GEN", width: "15%" },
  ]);

  const [mainEnginesRows, setMainEnginesRows] = useState([
    ["Maintenance record checked", "", "", "", "", ""],
    ["Outstanding maintenance", "", "", "", "", ""],
    ["Last oil analysis (date)", "", "", "", "", ""],
    ["Last oil analysis result", "", "", "", "", ""],
    ["Running hours at present", "", "", "", "", ""],
    ["Last major overhaul", "", "", "", "", ""],
    ["Next major overhaul", "", "", "", "", ""],
  ]);

  const [editorValueObservationsCloseOut, setEditorValueObservationsCloseOut] =
    useState("");

  //-------------------RTE------------------------
  // States to store RTE content for each section
  const [content, setContent] = useState({
    doc: "",
    maintenance: "",
    dpSystem: "",
    incidents: "",
    powerGen: "",
    powerDist: "",
    powerMgmt: "",
    controlLoops: "",
    envSensors: "",
    posRefs: "",
    dpControl: "",
    personnel: "",
    trialsConclusions: "",
  });

  const [editorValueDpTrialTestSchedule, setEditorValueDpTrialTestSchedule] =
    useState("");

  const [findings, setFindings] = useState({
    findingA: { details: "", date: "" },
    findingB: { details: "", date: "" },
    findingC: { details: "", date: "" },
  });

  const [editorValueA, setEditorValueA] = useState("");
  const [editorValueB, setEditorValueB] = useState("");
  const [editorValueC, setEditorValueC] = useState("");

  const [mainEnginesRemarks, setMainEnginesRemarks] = useState("");

  const [testDetails, setTestDetails] = useState({});

  const [tableData, setTableData] = useState({});

  const getSurveyById = async (id) => {
    try {
      const res = await getData({
        endpoint: "survey/getSurveyById",
        token: cookies.t,
        params: { id: id },
      });
      SetSurveyDataById(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getSurveyById(id);
    }
  }, [id]);

  const handleAMCAForm = async () => {
    if (amcaFrontPage.surveyName === "") {
      setIsSurveyNameModal(() => ({
        isModalOpen: true,
      }));
    } else {
      if (
        location?.state === "Surveyor" ||
        location?.pathname.startsWith("/survey/surveyor")
      ) {
        try {
          setIsLoading(true);
          const res = await patchData({
            endpoint: "survey/updateSurvey",
            token: cookies.t,
            params: {
              id: id,
            },
            data: {
              document_revision_record: docRevRecords,
              amendment_record: amendmentRecords,
              key_personnel_attendance_Trial: crewData,
              auxiliary_diesel_engine_generator: engineAndAuxiliary,
              thrusters_and_Propulsion: thrusterPropulsion,
              electric_distribution: electricDistribution,
              dp_system_equipment: equipmentRows,
              position_reference_system: prsRows,
              dp_system_sensor: sensorRows,
              documentation: content?.doc,
              machinery_maintenance: content?.maintenance,
              dp_system_maintenance: content?.dpSystem,
              incidents: content?.incidents,
              power_generation: content?.powerGen,
              power_distribution: content?.powerDist,
              power_management: content?.powerMgmt,
              control_loops: content?.controlLoops,
              envviroment_heading_sensors: content?.envSensors,
              position_references: content?.posRefs,
              dp_control: content?.dpControl,
              personal: content?.personnel,
              trials_conclusions: content?.trialsConclusions,

              mainEnginesRows: mainEnginesRows,
              mainEnginesColumns: mainEnginesColumns,
              mainEnginesRemarks: mainEnginesRemarks,

              machineryMaintenanceRows: machineryMaintenanceRows,
              machineryMaintenanceColumns: machineryMaintenanceColumns,
              machineryMaintainenceRemarks: machineryMaintainenceRemarks,

              switchboard_maintenance: switchboardMaintenance,
              switchboard_maintenance_comments:
                switchboardMaintenance?.comments,
              dp_sensor_reference_system: dpSystemMaintain,
              dp_sensor_general_remark: generalRemarks,
              dp_computer: dpComputers,
              battery_maintenance: batteryMaintenance,
              checklist_dp_related_doc: checkListDP,
              checkListDPRemarks: checkListDPRemarks,

              hardware_modification: hardwareModification,
              capability_plot: capabilityPlots,
              dp_incident: dpIncident,
              dpIncidentRemarks: dpIncidentRemarks,

              dp_traning_personnel: dpTraningPersonal,
              dp_training_personnel_comments: dpTraningPersonalRemarks,

              pre_trial_check: preTrialCheck,
              preTrialCheckremarks: preTrialCheckremarks,
              dp_trial_test_schedule: editorValueDpTrialTestSchedule,
              summary: summaryData,
              instruction: instructionData,
              scope_of_work: scopeOfWork,
              order_of_trial: orderOfTrial,
              referenced_and_associated_publications: refAndAssPub,
              vessel_general: VesselGeneral,

              vessel_particulatars_first_table: vesselGeneralTableData,
              summary_requested_by: summaryDataState?.["Enter Requestor"],
              summary_behalf: summaryDataState?.["Enter Client Name"],
              summary_vessel_name: summaryDataState?.["Enter Vessel Name"],
              summary_certified:
                summaryDataState?.["Enter Certified Person Name"],
              summary_vessel_at: summaryDataState?.["Enter Date"],
              summary_conducted_at: summaryDataState?.["Enter Location"],
              summary_conducted_on: summaryDataState?.["Enter Trials Location"],
              summary_witnessed_by: summaryDataState?.["Enter AMCA Witness"],
              summary_amca_marine_consulting:
                summaryDataState?.["Enter IRS Witness"],
              Instruction_behalf_of:
                instructionDataState?.["Enter Client Name"],
              Instruction_vessel_name:
                instructionDataState?.["Enter Vessel Name"],
              Instruction_received_from:
                instructionDataState?.["Enter Requestor"],

              order_of_Trials_seas_were: orderOfTrialState?.["Enter Sea State"],
              order_of_Trials_weather_condition:
                orderOfTrialState?.["Enter Weather Condition"],
              order_of_Trials_around: orderOfTrialState?.["Enter Depth"],
              order_of_Trials_carried_out_at:
                orderOfTrialState?.["Enter Location"],
              order_of_Trials_dp_completed_approximately_on:
                orderOfTrialState?.["Enter End Date"],
              order_of_Trials_dp_completed_approximately:
                orderOfTrialState?.["Enter End Time"],
              order_of_Trials_dp_commenced_approximately_on:
                orderOfTrialState?.["Enter Start Approximately on"],
              order_of_Trials_dp_commenced_approximately:
                orderOfTrialState?.["Enter Start Approximately"],
              order_of_Trials_dp: orderOfTrialState?.["Enter trial DP"],

              vessel_particulars_typeofmainpropeller:
                VesselGeneralState?.["Enter Type of Main Propeller"],
              vessel_particulars_thrusters_number:
                VesselGeneralState?.["Enter Number of Stern Tunnel Thrusters"],
              vessel_particulars_bow_number:
                VesselGeneralState?.["Enter Number of Bow Tunnel Thrusters"],
              vessel_particulars_classnotation:
                VesselGeneralState?.["Enter Class Notation"],
              vessel_particulars_builder_location:
                VesselGeneralState?.["Enter Builder & Location"],
              vessel_particulars_years:
                VesselGeneralState?.["Enter Year Built"],
              vessel_particulars_vessel_name:
                VesselGeneralState?.["Enter Vessel Name"],

              trial_template: Object.keys(testDetails).length
                ? testDetails
                : null,

              editorValueObservationsCloseOut: editorValueObservationsCloseOut,

              test_tableData: Object.keys(testDetails).length
                ? tableData
                : null,
              editor_value_A: editorValueA,
              editor_value_B: editorValueB,
              editor_value_C: editorValueC,

              findings: findings,
              survey_by: [
                {
                  email: cookies.email,
                  date: new Date(),
                  name: cookies.un ? atob(cookies.un) : null,
                },
              ],
              status: "Ongoing",
            },
          });
          if (res) {
            toast.success("Surveyor Added Data Successfully", {
              duration: 1000,
              style: confimationStyles,
            });
          }
        } catch (err) {
          console.log("err");
        } finally {
          setIsLoading(false);
        }
      } else {
        if (
          location?.state === "updateAgain" ||
          location?.pathname?.startsWith("/dashboard/survey/edit") ||
          location?.pathname?.startsWith("/dashboard/survey/surveyor")
        ) {
          try {
            setIsLoading(true);
            const res = await patchData({
              endpoint: "survey/updateSurvey",
              token: cookies.t,
              params: {
                id: id,
              },
              data: {
                survey_name: amcaFrontPage?.surveyName,
                vessel_name: amcaFrontPage?.vesselName,
                client_name: amcaFrontPage?.clientName,
                date: amcaFrontPage?.date,
                doc_no: amcaFrontPage?.docNo,
                rev_no: amcaFrontPage?.revNo,
                fmea_trial: amcaFrontPage?.fmeaTrial,
                prepared_by: amcaFrontPage?.preparedBy,
                docs_revision_record_number: docRevRecords?.length,
                amendment_record_number: amendmentRecords?.length,
                key_personnel_attendance_Trial: crewData,
                summary: summaryData,
                instruction: instructionData,
                scope_of_work: scopeOfWork,
                referenced_and_associated_publications: refAndAssPub,
                order_of_trial: orderOfTrial,
                requirements_during_testing: reqDuringTest,
                vessel_general: VesselGeneral,
                auxiliary_diesel_engine_generator: engineAndAuxiliary,
                thrusters_and_Propulsion: thrusterPropulsion,
                electric_distribution: electricDistribution,
                dp_system_equipment: equipmentRows,
                position_reference_system: prsRows,
                dp_system_sensor: sensorRows,
                machine_conf_during_test: machineConfDuringTest,
                worst_case_failure: worstCaseFailure,
                switchboard_maintenance: switchboardMaintenance,
                dp_sensor_reference_system: dpSystemMaintain,
                dp_sensor_general_remark: generalRemarks,
                dp_computer: dpComputers,
                battery_maintenance: batteryMaintenance,
                checklist_dp_related_doc: checkListDP,
                hardware_modification: hardwareModification,
                capability_plot: capabilityPlots,
                dp_incident: dpIncident,
                dp_traning_personnel: dpTraningPersonal,
                pre_trial_check: preTrialCheck,
                disclaimer: disclaimerData,
                preTrialCheckremarks: preTrialCheckremarks,

                mainEnginesRows: mainEnginesRows,
                mainEnginesColumns: mainEnginesColumns,
                mainEnginesRemarks: mainEnginesRemarks,

                machineryMaintenanceRows: machineryMaintenanceRows,
                machineryMaintenanceColumns: machineryMaintenanceColumns,
                machineryMaintainenceRemarks: machineryMaintainenceRemarks,

                editorValueObservationsCloseOut:
                  editorValueObservationsCloseOut,

                trial_template: Object.keys(testDetails).length
                  ? testDetails
                  : null,

                test_tableData: Object.keys(testDetails).length
                  ? tableData
                  : null,
                editor_value_A: editorValueA,
                editor_value_B: editorValueB,
                editor_value_C: editorValueC,

                findings: findings,
                added_by: [
                  {
                    email: cookies.email,
                    date: new Date(),
                    name: cookies.un ? atob(cookies.un) : null,
                  },
                ],
                status: "Created",
                vesselLayoutImages: vesselLayoutImages,
              },
            });
            if (res) {
              toast.success("Survey Data Successfully", {
                duration: 1000,
                style: confimationStyles,
              });
              navigate(`/dashboard/survey/add/${res?.data?._id}`, {
                state: "updateAgain",
              });
            }
          } catch (err) {
            console.log("err", err);
          } finally {
            setIsLoading(false);
          }
        } else {
          try {
            setIsLoading(true);
            const res = await postData({
              endpoint: "survey/createSurvey",
              token: cookies.t,
              data: {
                survey_name: amcaFrontPage?.surveyName,
                vessel_name: amcaFrontPage?.vesselName,
                client_name: amcaFrontPage?.clientName,
                date: amcaFrontPage?.date,
                doc_no: amcaFrontPage?.docNo,
                rev_no: amcaFrontPage?.revNo,
                fmea_trial: amcaFrontPage?.fmeaTrial,
                prepared_by: amcaFrontPage?.preparedBy,
                docs_revision_record_number: docRevRecords?.length,
                amendment_record_number: amendmentRecords?.length,
                key_personnel_attendance_Trial: crewData,
                disclaimer: disclaimerData,
                summary: summaryData,
                instruction: instructionData,
                scope_of_work: scopeOfWork,
                referenced_and_associated_publications: refAndAssPub,
                order_of_trial: orderOfTrial,
                requirements_during_testing: reqDuringTest,
                vessel_general: VesselGeneral,
                auxiliary_diesel_engine_generator: engineAndAuxiliary,
                thrusters_and_Propulsion: thrusterPropulsion,
                electric_distribution: electricDistribution,
                dp_system_equipment: equipmentRows,
                position_reference_system: prsRows,
                dp_system_sensor: sensorRows,
                machine_conf_during_test: machineConfDuringTest,
                worst_case_failure: worstCaseFailure,
                switchboard_maintenance: switchboardMaintenance,
                dp_sensor_reference_system: dpSystemMaintain,
                dp_computer: dpComputers,
                battery_maintenance: batteryMaintenance,
                checklist_dp_related_doc: checkListDP,
                hardware_modification: hardwareModification,
                capability_plot: capabilityPlots,
                dp_incident: dpIncident,
                dp_traning_personnel: dpTraningPersonal,
                pre_trial_check: preTrialCheck,
                preTrialCheckremarks: preTrialCheckremarks,

                mainEnginesRows: mainEnginesRows,
                mainEnginesColumns: mainEnginesColumns,
                mainEnginesRemarks: mainEnginesRemarks,

                machineryMaintenanceRows: machineryMaintenanceRows,
                machineryMaintenanceColumns: machineryMaintenanceColumns,
                machineryMaintainenceRemarks: machineryMaintainenceRemarks,

                editor_value_A: editorValueA,
                editor_value_B: editorValueB,
                editor_value_C: editorValueC,

                editorValueObservationsCloseOut:
                  editorValueObservationsCloseOut,

                trial_template: Object.keys(testDetails).length
                  ? testDetails
                  : null,

                test_tableData: Object.keys(testDetails).length
                  ? tableData
                  : null,

                status: "Created",
                vesselLayoutImages: vesselLayoutImages,

                findings: findings,
                added_by: [
                  {
                    email: cookies.email,
                    date: new Date(),
                    name: cookies.un ? atob(cookies.un) : null,
                  },
                ],
              },
            });
            if (res) {
              toast.success("Survey Data Successfully", {
                duration: 1000,
                style: confimationStyles,
              });
              navigate(`/dashboard/survey/add/${res?.data?.data?._id}`, {
                state: "updateAgain",
              });
            }
          } catch (err) {
            console.log("err", err);
          } finally {
            setIsLoading(false);
          }
        }
      }
    }
  };
  const handleConfirmModal = async () => {
    try {
      setIsSubmitting(true);
      const res = await patchData({
        endpoint: "survey/updateSurvey",
        token: cookies.t,
        params: {
          id: id,
        },
        data: {
          document_revision_record: docRevRecords,
          amendment_record: amendmentRecords,
          key_personnel_attendance_Trial: crewData,
          auxiliary_diesel_engine_generator: engineAndAuxiliary,
          thrusters_and_Propulsion: thrusterPropulsion,
          electric_distribution: electricDistribution,
          dp_system_equipment: equipmentRows,
          position_reference_system: prsRows,
          dp_system_sensor: sensorRows,
          documentation: content?.doc,
          machinery_maintenance: content?.maintenance,
          dp_system_maintenance: content?.dpSystem,
          incidents: content?.incidents,
          power_generation: content?.powerGen,
          power_distribution: content?.powerDist,
          power_management: content?.powerMgmt,
          control_loops: content?.controlLoops,
          envviroment_heading_sensors: content?.envSensors,
          position_references: content?.posRefs,
          dp_control: content?.dpControl,
          personal: content?.personnel,
          trials_conclusions: content?.trialsConclusions,

          mainEnginesRows: mainEnginesRows,
          mainEnginesColumns: mainEnginesColumns,
          mainEnginesRemarks: mainEnginesRemarks,

          machineryMaintenanceRows: machineryMaintenanceRows,
          machineryMaintenanceColumns: machineryMaintenanceColumns,
          machineryMaintainenceRemarks: machineryMaintainenceRemarks,

          switchboard_maintenance: switchboardMaintenance,
          switchboard_maintenance_comments: switchboardMaintenance?.comments,
          dp_sensor_reference_system: dpSystemMaintain,
          dp_sensor_general_remark: generalRemarks,
          dp_computer: dpComputers,
          battery_maintenance: batteryMaintenance,
          checklist_dp_related_doc: checkListDP,
          checkListDPRemarks: checkListDPRemarks,

          hardware_modification: hardwareModification,
          capability_plot: capabilityPlots,
          dp_incident: dpIncident,
          dpIncidentRemarks: dpIncidentRemarks,

          dp_traning_personnel: dpTraningPersonal,
          dp_training_personnel_comments: dpTraningPersonalRemarks,

          pre_trial_check: preTrialCheck,
          preTrialCheckremarks: preTrialCheckremarks,
          dp_trial_test_schedule: editorValueDpTrialTestSchedule,
          summary: summaryData,
          instruction: instructionData,
          scope_of_work: scopeOfWork,
          order_of_trial: orderOfTrial,
          referenced_and_associated_publications: refAndAssPub,
          vessel_general: VesselGeneral,

          vessel_particulatars_first_table: vesselGeneralTableData,
          summary_requested_by: summaryDataState?.["Enter Requestor"],
          summary_behalf: summaryDataState?.["Enter Client Name"],
          summary_vessel_name: summaryDataState?.["Enter Vessel Name"],
          summary_certified: summaryDataState?.["Enter Certified Person Name"],
          summary_vessel_at: summaryDataState?.["Enter Date"],
          summary_conducted_at: summaryDataState?.["Enter Location"],
          summary_conducted_on: summaryDataState?.["Enter Trials Location"],
          summary_witnessed_by: summaryDataState?.["Enter AMCA Witness"],
          summary_amca_marine_consulting:
            summaryDataState?.["Enter IRS Witness"],
          Instruction_behalf_of: instructionDataState?.["Enter Client Name"],
          Instruction_vessel_name: instructionDataState?.["Enter Vessel Name"],
          Instruction_received_from: instructionDataState?.["Enter Requestor"],

          order_of_Trials_seas_were: orderOfTrialState?.["Enter Sea State"],
          order_of_Trials_weather_condition:
            orderOfTrialState?.["Enter Weather Condition"],
          order_of_Trials_around: orderOfTrialState?.["Enter Depth"],
          order_of_Trials_carried_out_at: orderOfTrialState?.["Enter Location"],
          order_of_Trials_dp_completed_approximately_on:
            orderOfTrialState?.["Enter End Date"],
          order_of_Trials_dp_completed_approximately:
            orderOfTrialState?.["Enter End Time"],
          order_of_Trials_dp_commenced_approximately_on:
            orderOfTrialState?.["Enter Start Approximately on"],
          order_of_Trials_dp_commenced_approximately:
            orderOfTrialState?.["Enter Start Approximately"],
          order_of_Trials_dp: orderOfTrialState?.["Enter trial DP"],

          vessel_particulars_typeofmainpropeller:
            VesselGeneralState?.["Enter Type of Main Propeller"],
          vessel_particulars_thrusters_number:
            VesselGeneralState?.["Enter Number of Stern Tunnel Thrusters"],
          vessel_particulars_bow_number:
            VesselGeneralState?.["Enter Number of Bow Tunnel Thrusters"],
          vessel_particulars_classnotation:
            VesselGeneralState?.["Enter Class Notation"],
          vessel_particulars_builder_location:
            VesselGeneralState?.["Enter Builder & Location"],
          vessel_particulars_years: VesselGeneralState?.["Enter Year Built"],
          vessel_particulars_vessel_name:
            VesselGeneralState?.["Enter Vessel Name"],

          trial_template: Object.keys(testDetails).length ? testDetails : null,

          editorValueObservationsCloseOut: editorValueObservationsCloseOut,

          test_tableData: Object.keys(testDetails).length ? tableData : null,
          editor_value_A: editorValueA,
          editor_value_B: editorValueB,
          editor_value_C: editorValueC,

          findings: findings,
          survey_by: [
            {
              email: cookies.email,
              date: new Date(),
              name: cookies.un ? atob(cookies.un) : null,
            },
          ],
          status: "Submitted",
        },
      });
      if (res) {
        toast.success("Surveyor Added Data Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        if (location.pathname.startsWith("/dashboard/survey/surveyor")) {
          navigate("/dashboard/survey");
        } else {
          const cookieNames = ["t", "un", "email", "uid", "role"];
          const surveyCookies = ["st"];

          cookieNames.forEach((cookieName) => {
            removeCookies(cookieName, { path: "/" });
          });
          navigate(`/survey/auth/${cookies.survey_id}`);
          surveyCookies.forEach((cookieName) => {
            removeCookies(cookieName, { path: "/" });
          });
        }
      }
    } catch (err) {
      console.log("err");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMenuOpen = (setter) => (event) => {
    setter(event.currentTarget);
  };
  const handleMenuClose = (setter) => () => {
    setter(null);
  };

  return (
    <>
      <div className="addSurveyWrap">
        <div className="backSaveButton">
          {screenWidth < 900 ? (
            <div className="d-flex flex-column gap-3">
              {!location?.pathname.startsWith("/survey/surveyor") && (
                <BackBtn
                  handleClick={() => {
                    navigate("/dashboard/survey");
                  }}
                />
              )}
              <PageHeader
                title={`${
                  location?.pathname?.startsWith("/survey/surveyor") ||
                  location.pathname?.startsWith("/dashboard/survey/surveyor/")
                    ? "Mode: Surveyor"
                    : "Mode: Admin"
                }`}
                type="small"
              />
            </div>
          ) : (
            <>
              {!location?.pathname.startsWith("/survey/surveyor") && (
                <BackBtn
                  handleClick={() => {
                    navigate("/dashboard/survey");
                  }}
                />
              )}
              <PageHeader
                title={`${
                  location?.pathname?.startsWith("/survey/surveyor") ||
                  location.pathname?.startsWith("/dashboard/survey/surveyor/")
                    ? "Mode: Surveyor"
                    : "Mode: Admin"
                }`}
                type="small"
              />
            </>
          )}
          <div
            className={`${
              location?.pathname?.startsWith("/survey/surveyor")
                ? " d-flex flex-row gap-3 survery-btn-none-mbr"
                : "filter-add"
            }`}
          >
            <CustomButton
              // text="Save"
              iconRequired={false}
              type="btn-primary"
              size="btn-lg"
              handleClick={() => {
                handleAMCAForm();
              }}
              disabled={isLoading}
            >
              <Tick /> &nbsp;
              {isLoading ? "Saving" : "Save"}
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm ms-2 text-light"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </CustomButton>
            {(location?.pathname?.startsWith("/survey/surveyor") ||
              location?.pathname?.startsWith(
                "/dashboard/survey/surveyor/",
              )) && (
              <div>
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  size="btn-lg"
                  handleClick={() => {
                    setFinalModalSubmit(() => ({
                      isModalOpen: true,
                      modalId: id,
                    }));
                  }}
                  disabled={isSubmitting}
                >
                  <ArrowSubmit /> &nbsp;
                  {isSubmitting ? "Submitting" : "Submit"}
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              </div>
            )}
          </div>
          {location?.pathname?.startsWith("/survey/surveyor") && (
            <>
              <div onClick={handleMenuOpen(setProfileAnchorEl)}>
                <CircleAvatar name={cookies.un ? atob(cookies.un) : null} />
              </div>
              <Menu
                anchorEl={profileAnchorEl}
                open={Boolean(profileAnchorEl)}
                onClose={handleMenuClose(setProfileAnchorEl)}
                PaperProps={{
                  style: {
                    width: "200px",
                    borderRadius: "16px",
                    marginTop: "20px",
                    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setIsModalOpen(!isModalOpen);
                    setProfileAnchorEl(null);
                  }}
                >
                  <span style={{ color: "#BE0F00" }}>Logout</span>
                </MenuItem>
              </Menu>
              {isModalOpen && (
                <LogoutModal
                  isActive={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  surveyRole={
                    location?.pathname?.startsWith("/survey/surveyor") &&
                    "surveyor"
                  }
                />
              )}
            </>
          )}
        </div>
        <div
          className={`${
            location?.pathname?.startsWith("/survey/surveyor")
              ? "survery-btn-display-save-btn-mbr"
              : "filter-add"
          }`}
        >
          <CustomButton
            // text="Save"
            iconRequired={false}
            type="btn-primary"
            size="btn-lg"
            handleClick={() => {
              handleAMCAForm();
            }}
            disabled={isLoading}
          >
            <Tick /> &nbsp;
            {isLoading ? "Saving" : "Save"}
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm ms-2 text-light"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </CustomButton>
          {(location?.pathname?.startsWith("/survey/surveyor") ||
            location?.pathname?.startsWith("/dashboard/survey/surveyor/")) && (
            <div>
              <CustomButton
                iconRequired={false}
                type="btn-primary"
                size="btn-lg"
                handleClick={() => {
                  setFinalModalSubmit(() => ({
                    isModalOpen: true,
                    modalId: id,
                  }));
                }}
                disabled={isSubmitting}
              >
                <ArrowSubmit /> &nbsp;
                {isSubmitting ? "Submitting" : "Submit"}
                {isSubmitting && (
                  <span
                    className="spinner-border spinner-border-sm ms-2 text-light"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </CustomButton>
            </div>
          )}
        </div>
      </div>
      <div className="pageWrapper">
        <div className="sidesearchpageWrap">
          <div className="searchBar">
            <SideSearchBar
              NavigationData={NavigationData}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              SetNavigationClicked={SetNavigationClicked}
              testDetails={testDetails}
              testsNavigation={testsNavigation}
              setTestNavigation={setTestNavigation}
            />
          </div>
          <div>
            <Overview
              navigationClicked={navigationClicked}
              roleType={
                location?.state === "Surveyor" ? "Surveyor" : atob(cookies.role)
              }
              amcaFrontPage={amcaFrontPage}
              setAMCAFrontPage={setAMCAFrontPage}
              amendmentRecords={amendmentRecords}
              setAmendmentRecords={setAmendmentRecords}
              docRevRecords={docRevRecords}
              setDocRevRecords={setDocRevRecords}
              crewData={crewData}
              setCrewData={setCrewData}
              setSummaryData={setSummaryData}
              summaryData={summaryData}
              disclaimerData={disclaimerData}
              setDisclaimerData={setDisclaimerData}
              instructionData={instructionData}
              setInstructionData={setInstructionData}
              orderOfTrial={orderOfTrial}
              setOrderOfTrial={setOrderOfTrial}
              refAndAssPub={refAndAssPub}
              setRefAndAssPub={setRefAndAssPub}
              scopeOfWork={scopeOfWork}
              setScopeOfWork={setScopeOfWork}
              reqDuringTest={reqDuringTest}
              setReqDuringTest={setReqDuringTest}
              VesselGeneral={VesselGeneral}
              setVesselGeneral={setVesselGeneral}
              engineAndAuxiliary={engineAndAuxiliary}
              setEngineAndAuxiliary={setEngineAndAuxiliary}
              setThrusterPropulsion={setThrusterPropulsion}
              thrusterPropulsion={thrusterPropulsion}
              electricDistribution={electricDistribution}
              setElectricDitribution={setElectricDitribution}
              equipmentRows={equipmentRows}
              setEquipmentRows={setEquipmentRows}
              prsRows={prsRows}
              setPrsRows={setPrsRows}
              sensorRows={sensorRows}
              setSensorRows={setSensorRows}
              machineConfDuringTest={machineConfDuringTest}
              setMachineConfDuringTest={setMachineConfDuringTest}
              worstCaseFailure={worstCaseFailure}
              setWorstCaseFailure={setWorstCaseFailure}
              dpSystemMaintain={dpSystemMaintain}
              setDpSystemMaintain={setDpSystemMaintain}
              generalRemarks={generalRemarks}
              setGeneralRemarks={setGeneralRemarks}
              dpComputers={dpComputers}
              setDpComputers={setDpComputers}
              batteryMaintenance={batteryMaintenance}
              setbatteryMaintenance={setbatteryMaintenance}
              checkListDP={checkListDP}
              setCheckListDP={setCheckListDP}
              setCheckListDPRemarks={setCheckListDPRemarks}
              checkListDPRemarks={checkListDPRemarks}
              hardwareModification={hardwareModification}
              setHardWareModification={setHardWareModification}
              setCapabilityPlots={setCapabilityPlots}
              capabilityPlots={capabilityPlots}
              dpIncident={dpIncident}
              setDPIncident={setDPIncident}
              dpIncidentRemarks={dpIncidentRemarks}
              setDpIncidentRemarks={setDpIncidentRemarks}
              dpTraningPersonal={dpTraningPersonal}
              setDPTrainingPersonal={setDPTrainingPersonal}
              dpTraningPersonalRemarks={dpTraningPersonalRemarks}
              setDpTraningPersonalRemarks={setDpTraningPersonalRemarks}
              preTrialCheck={preTrialCheck}
              setPreTrialCheck={setPreTrialCheck}
              setSwitchboardMaintenance={setSwitchboardMaintenance}
              switchboardMaintenance={switchboardMaintenance}
              preTrialCheckremarks={preTrialCheckremarks}
              setPreTrialCheckRemarks={setPreTrialCheckRemarks}
              mainEnginesRows={mainEnginesRows}
              setMainEnginesRows={setMainEnginesRows}
              mainEnginesColumns={mainEnginesColumns}
              setMainEnginesColumns={setMainEnginesColumns}
              mainEnginesRemarks={mainEnginesRemarks}
              setMainEnginesRemarks={setMainEnginesRemarks}
              machineryMaintenanceRows={machineryMaintenanceRows}
              setMachineryMaintenanceRows={setMachineryMaintenanceRows}
              machineryMaintenanceColumns={machineryMaintenanceColumns}
              setMachineryMaintenanceColumns={setMachineryMaintenanceColumns}
              machineryMaintainenceRemarks={machineryMaintainenceRemarks}
              setMachineryMaintainenceRemarks={setMachineryMaintainenceRemarks}
              content={content}
              setContent={setContent}
              editorValueDpTrialTestSchedule={editorValueDpTrialTestSchedule}
              setEditorValueDpTrialTestSchedule={
                setEditorValueDpTrialTestSchedule
              }
              vesselGeneralTableData={vesselGeneralTableData}
              setVesselGeneralTableData={setVesselGeneralTableData}
              summaryDataState={summaryDataState}
              setSummaryDataState={setSummaryDataState}
              instructionDataState={instructionDataState}
              setInstructionDataState={setInstructionDataState}
              orderOfTrialState={orderOfTrialState}
              setOrderOfTrialState={setOrderOfTrialState}
              setVesselGeneralState={setVesselGeneralState}
              VesselGeneralState={VesselGeneralState}
              testDetails={testDetails}
              setTestDetails={setTestDetails}
              testsNavigation={testsNavigation}
              editorValueObservationsCloseOut={editorValueObservationsCloseOut}
              setEditorValueObservationsCloseOut={
                setEditorValueObservationsCloseOut
              }
              tableData={tableData}
              setTableData={setTableData}
              findings={findings}
              setFindings={setFindings}
              editorValueA={editorValueA}
              setEditorValueA={setEditorValueA}
              editorValueB={editorValueB}
              setEditorValueB={setEditorValueB}
              editorValueC={editorValueC}
              setEditorValueC={setEditorValueC}
              vesselLayoutImages={vesselLayoutImages}
              setVesselLayoutImages={setVesselLayoutImages}
            />
          </div>
        </div>
      </div>
      {finalModalSubmit.isModalOpen && (
        <Modal
          id={finalModalSubmit.modalId}
          isActive={finalModalSubmit.isModalOpen}
          onClose={closeFinalSubmitModal}
          title="Submit Survey"
          width="500px"
        >
          <div className="labelWrap">
            <div className="header">Are you sure you want to submit? </div>
            <div className="header">
              Once submitted, the survey cannot be modified.
            </div>
          </div>
          <div className="d-flex w-100 mt-3">
            <CustomButton
              text="No"
              handleClick={() => {
                closeFinalSubmitModal();
              }}
              style={{ flex: "1 0 45%" }}
              iconRequired={false}
            />
            {/* Adjust the style for the "Confirm" button */}
            <button
              className="btn btn-primary mt-0"
              onClick={() => {
                handleConfirmModal();
              }}
              style={{ flex: "1 0 45%", marginLeft: "10px" }}
              disabled={isSubmitting}
            >
              Confirm
              {isSubmitting && (
                <span
                  className="spinner-border spinner-border-sm ms-2 text-light"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </Modal>
      )}
      {isSurveyNameModal.isModalOpen && (
        <Modal
          // id={isSurveyNameModal.modalId}
          isActive={isSurveyNameModal.isModalOpen}
          onClose={() => {
            setIsSurveyNameModal(() => ({
              isModalOpen: false,
            }));
          }}
          title="Add survey name"
          width="500px"
        >
          <div className="labelWrap">
            <div className="header">
              A survey name is required to add the survey.
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default AddEmployee;
