const Tree = ({ className = "", width = "16", height = "16" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="18"
      height="18"
    >
      <path d="M22,18.184V16a3,3,0,0,0-3-3H13V9.9a5,5,0,1,0-2,0V13H5a3,3,0,0,0-3,3v2.184a3,3,0,1,0,2,0V16a1,1,0,0,1,1-1h6v3.184a3,3,0,1,0,2,0V15h6a1,1,0,0,1,1,1v2.184a3,3,0,1,0,2,0Z" />
    </svg>
  );
};

export default Tree;
