import React from "react";
import SimpleAccordion from "../../../../molecules/MyAccordion";
import MaintenanceTable from "./mainMachineries";
import DPSensor from "./dpSensors";
import MainSwitchboard from "./mainSwitchboard";
import VesselControlSystem from "./vesselControlSystem";
import HardwareModificationForm from "./hardwareModifications";
import CapabilityAndFootprints from "./capabilityFootprints";
import DPStation from "./dpStation";
import KeyDpPersonnel from "./keyDpPersonnel";
import FMEATrials from "./fmeaTrials";
import OperationalDocumentation from "./operationalDocumentation";

const PersonnelEquipment = ({
  machineMachineriesRows,
  setMachineMachineriesRows,
  machineMachineriesRemarks,
  setMachineMachineriesRemarks,
}) => {
  const PersonnelData = [
    {
      title: "Main Machineries",
      content: (
        <MaintenanceTable
          machineMachineriesRows={machineMachineriesRows}
          setMachineMachineriesRows={setMachineMachineriesRows}
          machineMachineriesRemarks={machineMachineriesRemarks}
          setMachineMachineriesRemarks={setMachineMachineriesRemarks}
        />
      ),
      count: 10,
    },
    {
      title: "Main Switchboard",
      content: <MainSwitchboard />,
    },
    {
      title: "DP Sensors",
      content: <DPSensor />,
      count: 14,
    },
    {
      title: "DP and other Vessel Control Systems",
      content: <VesselControlSystem />,
      count: 3,
    },
    {
      title: "Hardware Modifications",
      content: <HardwareModificationForm />,
    },
    {
      title: "Capability and Footprint Plots",
      content: <CapabilityAndFootprints />,
    },
    {
      title: "DP Station Keeping Events",
      content: <DPStation />,
    },
    {
      title: "Key DP Personnel",
      content: <KeyDpPersonnel />,
      count: 8,
    },
    {
      title: "FMEA and Trials",
      content: <FMEATrials />,
    },
    {
      title: "Operational Documentation",
      content: <OperationalDocumentation />,
    },
  ];

  return (
    <div>
      <SimpleAccordion items={PersonnelData} />
    </div>
  );
};

export default PersonnelEquipment;
