// import React, { useState } from "react";
// import "./styles.scss";
// import ArrowDown from "../../icons/ArrowDown";

// const AccordionItem = ({
//   title,
//   icon,
//   children,
//   isOpen,
//   toggle,
//   removePadding,
// }) => {
//   return (
//     <div className="accordion-item">
//       <div
//         className="accordion-header"
//         onClick={toggle}
//         style={{
//           cursor: "pointer",
//           display: "flex",
//           justifyContent: "space-between",
//         }}
//       >
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <span style={{ marginRight: "15px" }}>{icon}</span>
//           <span>{title}</span>
//         </div>
//         <span className={isOpen ? "rotate" : ""}>
//           {isOpen ? <ArrowDown /> : <ArrowDown />}
//         </span>
//       </div>
//       {isOpen && (
//         <div
//           className="accordion-content"
//           style={{ padding: removePadding ? "0" : "15px" }}
//         >
//           {children}
//         </div>
//       )}
//     </div>
//   );
// };

// // Accordion Component
// const Accordion = ({ items }) => {
//   const [openIndex, setOpenIndex] = useState(null);

//   // Function to toggle accordion item
//   const toggleAccordion = (index) => {
//     setOpenIndex(openIndex === index ? null : index);
//   };

//   return (
//     <div className="accordion">
//       {items.map((item, index) => (
//         <AccordionItem
//           key={index}
//           title={item.title}
//           icon={item.icon} // Pass the dynamic icon
//           isOpen={openIndex === index}
//           toggle={() => toggleAccordion(index)}
//           removePadding={item.removePadding} // Use item-specific prop
//         >
//           {item.content}
//         </AccordionItem>
//       ))}
//     </div>
//   );
// };

// export default Accordion;

import React, { useState } from "react";
import "./styles.scss";
import ArrowDown from "../../icons/ArrowDown";

const AccordionItem = ({
  title,
  icon,
  children,
  isOpen,
  toggle,
  removePadding,
}) => {
  return (
    <div className="accordion-item">
      <div
        className="accordion-header"
        onClick={toggle}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "15px" }}>{icon}</span>
          <span>{title}</span>
        </div>
        <span className={isOpen ? "rotate" : ""}>
          <ArrowDown />
        </span>
      </div>
      {isOpen && (
        <div
          className="accordion-content"
          style={{ padding: removePadding ? "0" : "15px" }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

// Accordion Component that allows multiple items to be open at once
const Accordion = ({ items }) => {
  const [openItems, setOpenItems] = useState([]); // Array to track open items

  // Function to toggle accordion item
  const toggleAccordion = (index) => {
    if (openItems.includes(index)) {
      // If item is already open, remove it from the array (close it)
      setOpenItems(openItems.filter((i) => i !== index));
    } else {
      // If item is closed, add it to the array (open it)
      setOpenItems([...openItems, index]);
    }
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          icon={item.icon} // Pass the dynamic icon
          isOpen={openItems.includes(index)} // Check if the item is open
          toggle={() => toggleAccordion(index)} // Toggle individual items
          removePadding={item.removePadding} // Use item-specific prop
        >
          {item.content}
        </AccordionItem>
      ))}
    </div>
  );
};

export default Accordion;
