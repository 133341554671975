import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { patchData, postData } from "../../../../../../services";
import { statusData, userRoles } from "../../../../../../helpers/staticData";
import SelectDropdown from "../../../../../molecules/Dropdown";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/toast";

const UserForm = ({ onClose, initialValues, id, refetchData }) => {
  const [cookies] = useCookies(["t", "un"]);

  const initialData = {
    name: initialValues?.name || null,
    email: initialValues?.email || null,
    role: initialValues?.role || null,
    password: null,
  };

  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    role: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values) => {
    setIsLoading(true); // Set loading to true when API call starts

    const res = await postData({
      endpoint: "adminAuth/createUser",
      token: cookies.t,
      data: {
        ...values,
        added_by: [
          { name: cookies.un ? atob(cookies.un) : null, date: new Date() },
        ],
        type: "User",
      },
    });

    if (res?.data?.statusCode === 200) {
      toast.success("User Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
      onClose();
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
    setIsLoading(false); // Set loading to false when API call completes
  };

  const handleEdit = async (values) => {
    setIsLoading(true); // Set loading to true when API call completes
    const UpdateUser = {
      name: values.name,
      email: values.email,
      role: values.role,
      status: values.is_active,
    };
    try {
      const res = await patchData({
        endpoint: "adminAuth/updateUser",
        token: cookies.t,
        data: {
          ...UpdateUser,
          updated_by: [
            { name: cookies.un ? atob(cookies.un) : null, date: new Date() },
          ],
          type: "User updated",
        },
        params: { id: id },
      });

      if (res?.response === 200) {
        toast.success("User Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        refetchData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {
    } finally {
      setIsLoading(false); // Set loading to false when API call completes
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <Field type="text" id="name" name="name" placeholder="Enter Name" />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="email">Email</label>
            <Field
              type="email"
              id="email"
              name="email"
              placeholder="Enter Email"
            />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="role">Role</label>
            <SelectDropdown
              data={userRoles}
              placeholder="Select Role"
              onChange={(option) => {
                setFieldValue("role", option);
              }}
              selectedValue={initialValues?.role}
            />
          </div>

          {initialValues && (
            <div className="form-group mt-3">
              <label htmlFor="role">Status</label>
              <SelectDropdown
                data={statusData}
                placeholder="Select Status"
                onChange={(option) => {
                  setFieldValue("is_active", option);
                }}
                selectedValue={initialValues?.status}
              />
            </div>
          )}

          <div className="d-flex justify-content-end mt-3">
            {initialValues ? (
              <button
                type="button"
                className="btn btn-primary"
                disabled={!isValid || isLoading}
                onClick={() => handleEdit(values)}
              >
                Update
                {isLoading && (
                  <div
                    className="spinner-border spinner-border-sm text-light ms-3"
                    role="status"
                  ></div>
                )}
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary w-20"
                disabled={!isValid || !dirty || isLoading}
              >
                Add
                {isLoading && (
                  <div
                    className="spinner-border spinner-border-sm text-light ms-3"
                    role="status"
                  ></div>
                )}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
