// import { useEffect, useState } from "react";
// import InfoCard from "../../../atoms/InfoCard";
// import AdminIcon from "../../../icons/admin";
// import Business from "../../../icons/Business";
// import { getData } from "../../../../services";
// import { useCookies } from "react-cookie";
// import PageCard from "../../../atoms/PageCard";
// import Search from "../../../molecules/search";
// import FilterComponent from "../../../molecules/filters/FilterComponent";
// import CustomButton from "../../../atoms/CustomButton";
// import { useNavigate } from "react-router-dom";
// import NoContentIcon from "../../../icons/NocontentIcon";
// import CustomDatagrid from "../../../molecules/CustomDatagrid";

// const Vessels = () => {
//   const [infoCardData, setInfoCardData] = useState();
//   const [cookies] = useCookies(["t", "role"]);
//   const navigate = useNavigate();

//   const getDashBoardInfoCardData = async () => {
//     try {
//       const res = await getData({
//         endpoint: "getAllDashboard",
//         token: cookies.t,
//       });
//       setInfoCardData(res?.data);
//     } catch (err) {
//       console.log("err", err);
//     }
//   };
//   useEffect(() => {
//     getDashBoardInfoCardData();
//   }, []);

//   return (
//     <>
//       <div className="header-infoCardWrap info_card_mbr">
//         <div className="infoCardWrap">
//           <InfoCard
//             color="#B1E5FC"
//             title="Total Surveys"
//             data={infoCardData ? infoCardData?.surveyData : 0}
//             svgIcon={<Business />}
//             isSvg
//             // data={data?.total_company || 0}
//           />
//           <InfoCard
//             color="#B1E5FC"
//             title="Total Users"
//             data={infoCardData ? infoCardData?.userData : 0}
//             svgIcon={<AdminIcon />}
//             isSvg
//             // data={data?.total_company || 0}
//           />
//         </div>
//       </div>
//       <PageCard>
//         <div className="d-flex space-between gap-3-mbr">
//           <Search
//             onSearchEmit={(value) => {
//               //   setSearchedValue(value);
//               //   if (value.length > 2) {
//               //     if (currentPage !== 1) setCurrentPage(1);
//               //   }
//             }}
//           />
//           <div className="filter-add">
//             <FilterComponent
//               filterData={[]}
//               isDateRange
//               dateRangeNames={["Survey Date", "Create Date"]}
//               onFilterChange={(filter) => {
//                 // setFilters(filter);
//               }}
//             />
//             {atob(cookies.role) === "Admin" && (
//               <div className="d-flex gap-3">
//                 <CustomButton
//                   type="btn-primary"
//                   text="Add Trial"
//                   handleClick={() => navigate("add")}
//                   classes={"survery-btn-none-mbr"}
//                 />
//               </div>
//             )}
//           </div>
//         </div>
//         {/* {tableData?.[0]?.trialData && tableData?.[0]?.trialData?.length > 0 ? ( */}
//         <>
//           <CustomDatagrid
//             getRowId={(row) => row._id}
//             rows={[]}
//             columns={[]}
//             tHeight="50vh"
//           />

//           {/* {tableData?.[0]?.pageDetails?.count > 10 && (
//               <Pagination
//                 itemsPerPage={itemsPerPage}
//                 totalItems={tableData?.[0]?.pageDetails?.count}
//                 currentPage={currentPage}
//                 onPageChange={handlePageChange}
//                 onItemsPerPageChange={handleItemsPerPageChange}
//               />
//             )} */}
//         </>
//         {/* ) : ( */}
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "50vh",
//           }}
//         >
//           <div>
//             <NoContentIcon />
//             <div
//               className="graph-heading"
//               style={{
//                 fontSize: "18px",
//                 marginTop: "20px",
//                 textAlign: "center",
//               }}
//             >
//               No Records Found
//             </div>
//           </div>
//         </div>
//         {/* )} */}
//       </PageCard>
//     </>
//   );
// };
// export default Vessels;

import { useEffect, useState } from "react";
import InfoCard from "../../../atoms/InfoCard";
import AdminIcon from "../../../icons/admin";
import Business from "../../../icons/Business";
import { getData } from "../../../../services";
import { useCookies } from "react-cookie";
import PageCard from "../../../atoms/PageCard";
import Search from "../../../molecules/search";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import CustomButton from "../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import NoContentIcon from "../../../icons/NocontentIcon";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import Modal from "../../../organisms/modals/Modal";
import TrialDetails from "./AddTrials/trialsDetails";

const Trials = () => {
  const [infoCardData, setInfoCardData] = useState();
  const [cookies] = useCookies(["t", "role"]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const [trialDetails, setTrialDetails] = useState({
    trials: "",
    vesselName: "",
    rollingScheme: false,
    type: "",
    startDate: null,
    endDate: null,
  });
  const getDashBoardInfoCardData = async () => {
    try {
      const res = await getData({
        endpoint: "getAllDashboard",
        token: cookies.t,
      });
      setInfoCardData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getDashBoardInfoCardData();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true); // Open modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <>
      <div className="header-infoCardWrap info_card_mbr">
        <div className="infoCardWrap">
          <InfoCard
            color="#B1E5FC"
            title="Total Surveys"
            data={infoCardData ? infoCardData?.surveyData : 0}
            svgIcon={<Business />}
            isSvg
          />
          <InfoCard
            color="#B1E5FC"
            title="Total Users"
            data={infoCardData ? infoCardData?.userData : 0}
            svgIcon={<AdminIcon />}
            isSvg
          />
        </div>
      </div>
      <PageCard>
        <div className="d-flex space-between gap-3-mbr">
          <Search onSearchEmit={(value) => {}} />
          <div className="filter-add">
            <FilterComponent
              filterData={[]}
              isDateRange
              dateRangeNames={["Survey Date", "Create Date"]}
              onFilterChange={(filter) => {}}
            />
            {atob(cookies.role) === "Admin" && (
              <div className="d-flex gap-3">
                <CustomButton
                  type="btn-primary"
                  text="Add Trial"
                  handleClick={handleOpenModal} // Open modal on button click
                  classes={"survery-btn-none-mbr"}
                />
              </div>
            )}
          </div>
        </div>

        <CustomDatagrid
          getRowId={(row) => row._id}
          rows={[]}
          columns={[]}
          tHeight="50vh"
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <div>
            <NoContentIcon />
            <div
              className="graph-heading"
              style={{
                fontSize: "18px",
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              No Records Found
            </div>
          </div>
        </div>
      </PageCard>

      {/* Modal for Adding Trials */}
      {isModalOpen && (
        <Modal
          id="trial-modal"
          title="Add Trial"
          isActive={isModalOpen}
          onClose={handleCloseModal}
          width="800px"
        >
          <TrialDetails
            modal={true}
            trialDetails={trialDetails}
            setTrialDetails={setTrialDetails}
          />
        </Modal>
      )}
    </>
  );
};

export default Trials;
