import React, { useState } from "react";
import AddBold from "../../../../icons/AddBold";
import Trash from "../../../../icons/Trash";
import Modal from "../../../../organisms/modals/DeleteModal";
import MyTextInput from "../../../../atoms/MyTextInput";

const MaintenanceTable = ({
  machineMachineriesRows,
  setMachineMachineriesRows,
  machineMachineriesRemarks,
  setMachineMachineriesRemarks,
}) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleAddRow = () => {
    setMachineMachineriesRows([
      ...machineMachineriesRows,
      {
        engine: "",
        maintenanceChecked: "",
        outstandingMaintenance: "",
        lastOilAnalysis: "",
        lastOilAnalysisResults: "",
        runningHours: "",
        lastMajorOverhaul: "",
      },
    ]);
  };

  console.log("machineMachineriesRows", machineMachineriesRows);
  console.log("machineMachineriesRemarks", machineMachineriesRemarks);

  const handleDeleteRow = (index) => {
    const newRows = machineMachineriesRows.filter(
      (_, rowIndex) => rowIndex !== index,
    );
    setMachineMachineriesRows(newRows);
    setIsModalActive(false); // Close modal after deletion
  };

  // Function to open the modal and set the selected row for deletion
  const confirmDeleteRow = (index) => {
    setSelectedRowIndex(index);
    setIsModalActive(true); // Show modal
  };

  const handleChange = (index, field, value) => {
    const newRows = [...machineMachineriesRows];
    newRows[index][field] = value;
    setMachineMachineriesRows(newRows);
  };

  return (
    <div style={{ overflowX: "scroll" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Engine/Thruster</th>
            <th>Maintenance Checked</th>
            <th>Outstanding Maintenance</th>
            <th>Last Oil Analysis</th>
            <th>Last Oil Analysis Results</th>
            <th>Running Hours at Present</th>
            <th>Last Major Overhaul</th>
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {machineMachineriesRows.map((row, index) => (
            <tr key={index}>
              <td>
                <b>{`# ${index + 1}`}</b>
              </td>
              <td>
                <input
                  type="text"
                  value={row.engine}
                  onChange={(e) =>
                    handleChange(index, "engine", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.maintenanceChecked}
                  onChange={(e) =>
                    handleChange(index, "maintenanceChecked", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.outstandingMaintenance}
                  onChange={(e) =>
                    handleChange(
                      index,
                      "outstandingMaintenance",
                      e.target.value,
                    )
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.lastOilAnalysis}
                  onChange={(e) =>
                    handleChange(index, "lastOilAnalysis", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.lastOilAnalysisResults}
                  onChange={(e) =>
                    handleChange(
                      index,
                      "lastOilAnalysisResults",
                      e.target.value,
                    )
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.runningHours}
                  onChange={(e) =>
                    handleChange(index, "runningHours", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.lastMajorOverhaul}
                  onChange={(e) =>
                    handleChange(index, "lastMajorOverhaul", e.target.value)
                  }
                />
              </td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => confirmDeleteRow(index)}
                >
                  <Trash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleAddRow}>
        <AddBold width="24" height="24" />
      </button>

      <div className="mt-3">
        <MyTextInput
          type="text"
          name="remarks"
          customholder="Main Machineries Remarks"
          value={machineMachineriesRemarks}
          onChange={(e) => setMachineMachineriesRemarks(e.target.value)}
        />
      </div>
      {isModalActive && (
        <Modal
          isActive={isModalActive}
          onClose={() => setIsModalActive(false)} // Close modal without deleting
          title="Row"
          name={`Row ${selectedRowIndex + 1}`}
          onClick={() => handleDeleteRow(selectedRowIndex)} // Delete row when Confirm is clicked
          description="Are you sure you want to delete this row?"
        />
      )}

      {/* CSS Styles */}
      <style jsx>{`
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th,
        td {
          padding: 10px;
          text-align: left;
          border: none;
        }
        input {
          border: 1px solid #ccc;
          padding: 5px;
          width: 100%;
          box-sizing: border-box;
        }
        button {
          display: flex; /* Enables flexbox */
          justify-content: center; /* Centers content horizontally */
          align-items: center; /* Centers content vertically */
          margin-top: 10px;
          //   padding: 10px 10px;
          //   background-color: #007bff;
          color: white;
          border: none;
          border-radius: 40px;
          cursor: pointer;
          background: none;
        }
        // button:hover {
        //   background-color: #0056b3;
        // }

        .delete-button {
          background: none;
          border: none;
          cursor: pointer;
          color: red; /* or your desired color */
          padding: 0;
          margin: 0;
        }

        .delete-button:hover {
          color: darkred; /* Change color on hover */
        }
      `}</style>
    </div>
  );
};

export default MaintenanceTable;
