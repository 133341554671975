import React, { useState } from "react";
import MyTextInput from "../../../../atoms/MyTextInput";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";
import MyDropdown from "../../../../atoms/MyDropdown";
import { DateSelector } from "../../../../molecules/MonthYearSelector";

const FMEATrials = () => {
  // State for form fields
  const [fmeaLastRevision, setFmeaLastRevision] = useState("");
  const [fmeaLastDate, setFmeaLastDate] = useState(null);
  const [fmeaFindingsCloseOut, setFmeaFindingsCloseOut] = useState("");
  const [lastDpAnnualTrialsDate, setLastDpAnnualTrialsDate] = useState("");
  const [findingsClosedOut, setFindingsClosedOut] = useState(null);
  const [fmeaTrialsRemarks, setFmeaTrialsRemarks] = useState("");

  console.log(
    "fmeaLastRevision,fmeaLastDate,fmeaFindingsCloseOut,lastDpAnnualTrialsDate,findingsClosedOut,fmeaTrialsRemarks,",
    fmeaLastRevision,
    fmeaLastDate,
    fmeaFindingsCloseOut,
    lastDpAnnualTrialsDate,
    findingsClosedOut,
    fmeaTrialsRemarks,
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    const formData = {};
    console.log(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Trials Input and Rolling Scheme Checkbox */}
      <div style={styles.row}>
        <div style={styles.inputContainer}>
          <MyTextInput
            type="text"
            name="fmeaLastRevision"
            customholder="FMEA - Last Revision"
            value={fmeaLastRevision}
            onChange={(e) => setFmeaLastRevision(e.target.value)}
          />
        </div>
        <div style={styles.dateSelectorContainer1}>
          <DateSelector
            placeholder="FMEA - Last Date"
            selectedDate={fmeaLastDate}
            onChange={setFmeaLastDate}
          />
        </div>

        <div style={styles.dropdownContainer1}>
          <MyDropdown
            placeholder="Have all FMEA findings been closed out ?"
            data={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
            format="all"
            menuOpen={true}
            name="type"
            width="100%"
            selectedValue={fmeaFindingsCloseOut}
            onChange={(options) => setFmeaFindingsCloseOut(options?.value)}
          />
        </div>
      </div>

      <div style={styles.row}>
        <div style={styles.dateSelectorContainer}>
          <DateSelector
            placeholder="Last DP annual trials - Date"
            selectedDate={lastDpAnnualTrialsDate}
            onChange={setLastDpAnnualTrialsDate}
          />
        </div>
        <div style={styles.dropdownContainer}>
          <MyDropdown
            placeholder="Have all FMEA findings been closed out ?"
            data={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
            format="all"
            menuOpen={true}
            name="type"
            width="100%"
            selectedValue={findingsClosedOut}
            onChange={(options) => setFindingsClosedOut(options?.value)}
          />
        </div>
      </div>

      <div style={styles.inputContainer1}>
        <MyTextInput
          type="text"
          name="fmeaLastRevision"
          customholder="FMEA and Trials Remarks"
          value={fmeaTrialsRemarks}
          onChange={(e) => setFmeaTrialsRemarks(e.target.value)}
        />
      </div>

      {/* Submit Button */}
      {/* <button type="submit" style={styles.submitButton}>
        Submit
      </button> */}
    </form>
  );
};

const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    gap: "16px",
  },
  inputContainer: {
    width: "51.2%",
  },
  inputContainer1: {
    width: "100%",
  },
  checkboxContainer: {
    width: "145px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  dropdownContainer: {
    width: "50%",
  },
  dropdownContainer1: {
    width: "30%",
  },
  dateSelectorContainer: {
    width: "50%",
    position: "relative",
  },
  dateSelectorContainer1: {
    width: "20%",
    position: "relative",
  },
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default FMEATrials;
