import React, { useState } from "react";
import PageHeader from "../../../../atoms/PageHeader";
import { Cookies, useCookies } from "react-cookie";
import { patchData, postData } from "../../../../../services";
import { Form, Formik, Field } from "formik";
import { confimationStyles } from "../../../../../assets/styles/toast";

import PasswordField from "../../../../atoms/PasswordField";
import PasswordStrengthIndicator from "../../../../organisms/passwordStrengthIndicator";
import Error from "../../../../atoms/Error";

import * as yup from "yup";
import toast from "react-hot-toast";
import PageCard from "../../../../atoms/PageCard";
import { useNavigate } from "react-router-dom";
import Edit from "../../../dashboard/edit-profile/index";

const ChangePassword = ({ mode }) => {
  const navigate = useNavigate();

  const [cookies] = useCookies(["t", "uid"]);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Please enter the correct format",
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), ""], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const dataToSend = {
      password: values.password,
      user_id: cookies.uid,
    };

    const endpoint = "adminAuth/changePassword";

    try {
      const res = await postData({
        endpoint: endpoint,
        params: dataToSend,
        token: cookies.t,
      });

      if (res.data) {
        toast.success("Password Reset Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        resetForm();
      }
    } catch (error) {
      // console.log(dataToSend)
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="mb-4">
        <Edit />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={false}
        validateOnMount={false}
      >
        {({
          handleSubmit,
          touched,
          errors,
          values,
          isValid,
          dirty,
          handleBlur,
          isSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <>
            <PageHeader title="Change Password" />

            <PageCard classes="w-70">
              <Form onSubmit={handleSubmit}>
                <div
                  className="d-flex w-100 flex-column-mbr"
                  style={{ gap: "10%" }}
                >
                  <div className="w-50 w-100-mbr">
                    <div className="form-group mt-0">
                      <PasswordField
                        label="New Password"
                        id="password"
                        name="password"
                        value={values.password}
                        onChange={(e) =>
                          setFieldValue("password", e.target.value)
                        }
                        onBlur={handleBlur}
                        placeholder="Enter your Password"
                        className={`${
                          (touched.password || isSubmitting) && errors.password
                            ? "error-input"
                            : ""
                        }`}
                      />
                      {(touched.password || isSubmitting) &&
                        errors.password && <Error error={errors.password} />}
                    </div>

                    <div className="form-group mt-4">
                      <PasswordField
                        label="Confirm New Password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={(e) =>
                          setFieldValue("confirmPassword", e.target.value)
                        }
                        onBlur={handleBlur}
                        placeholder="Enter your Password"
                        className={`${
                          (touched.confirmPassword || isSubmitting) &&
                          errors.confirmPassword
                            ? "error-input"
                            : ""
                        }`}
                      />
                      {(touched.confirmPassword || isSubmitting) &&
                        errors.confirmPassword && (
                          <Error error={errors.confirmPassword} />
                        )}
                    </div>

                    <div className="d-flex justify-content-end gap-3">
                      <button
                        className="btn btn-primary w-20 mt-4"
                        disabled={!isValid || !dirty || isSubmitting}
                        type="submit"
                      >
                        Update
                        {isSubmitting && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>

                      <button
                        className="btn btn-outline-dark w-20 mt-4"
                        type="button"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                  <div className="w-40">
                    <PasswordStrengthIndicator
                      password={values.password}
                      isPasswordValid={isPasswordValid}
                      touched={touched.password}
                    />
                  </div>
                </div>
              </Form>
            </PageCard>
          </>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
