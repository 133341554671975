import React, { useEffect, useState } from "react";
import { useField } from "formik";
import Select from "react-select";
import {
  customDropdownStyles,
  errorStyles,
  multiSelectStyles,
  filterDropdownStyles,
} from "../../../assets/styles/dropdown";
import "./styles.scss";
// import "../Dropdown/styles.scss";
import Error from "../Error";
import Info from "../Info";

const MyDropdown = ({
  id,
  multiSelect = false,
  selectedValue,
  onChange,
  classes,
  placeholder,
  data,
  format,
  isDisabled,
  type,
  isClearable,
  onClear,
  label,
  menuOpen,
  isError,
  handleBlur = () => {},
  touched,
  errors,
  info,
  width = "100%",
}) => {
  const options = data || [];
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [isFocused, setIsFocused] = useState(selectedValue ? true : false);

  useEffect(() => {
    if (multiSelect && selectedValue) {
      const initialSelectedOptions = options?.filter((option) =>
        selectedValue?.includes(option.value),
      );
      setSelectedOptions(initialSelectedOptions);
    } else if (!multiSelect && selectedValue != null) {
      const initialSelectedOption =
        options?.find((option) => option?.value === selectedValue) || null;
      setSelectedOptions(initialSelectedOption);
    } else {
      const defaultSelectedOptions = multiSelect ? [] : null;
      setSelectedOptions(defaultSelectedOptions);
    }

    if (selectedValue) setIsFocused(true);
    if (!selectedValue || selectedValue.length === 0) {
      setIsFocused(false);
    }
  }, [selectedValue, multiSelect, options]);

  const handleSelectChange = (selectedOptions) => {
    if (
      selectedOptions[0]?.value !== "Add new signer" &&
      selectedOptions[0]?.value !== "Add new broker" &&
      selectedOptions[0]?.value !== "Add new Insurance"
    ) {
      setSelectedOptions(selectedOptions);
    }

    if (multiSelect) {
      let selectedValues;
      if (format === "all") {
        selectedValues = selectedOptions?.map((option) => option);
      } else {
        selectedValues = selectedOptions?.map((option) => option?.value);
      }

      onChange(selectedValues);
    } else {
      if (!selectedOptions) {
        onClear && onClear();
      }
      if (format === "all") {
        onChange(selectedOptions);
      } else {
        onChange(selectedOptions ? selectedOptions?.value : null);
      }
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handlePlaceBlur = () => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setIsFocused(false);
    }
  };

  const styles =
    touched && errors
      ? { ...customDropdownStyles, ...errorStyles }
      : multiSelect
      ? { ...customDropdownStyles, ...multiSelectStyles }
      : customDropdownStyles;

  return (
    <>
      <div style={{ position: "relative", width: width }}>
        <Select
          className={`basic-single ${classes}`}
          classNamePrefix="select"
          options={options}
          value={selectedOptions}
          isMulti={multiSelect}
          onChange={handleSelectChange}
          styles={
            type === "filter"
              ? filterDropdownStyles
              : type === "dashboard"
              ? customDropdownStyles
              : styles
          }
          key={id}
          placeholder=""
          isDisabled={isDisabled}
          isClearable={isClearable}
          onFocus={handleFocus}
          onBlur={(event) => {
            handlePlaceBlur();
            handleBlur(event);
          }}
          //   onBlur={((handlePlaceBlur, handleBlur)}
        />
        <label
          className={`input-placeholder-small ${
            isFocused || (selectedOptions && selectedOptions.length > 0)
              ? "active"
              : ""
          }`}
        >
          {placeholder}
        </label>
        {info && !isError ? <Info info={info} /> : null}
        {touched && errors ? <Error error={errors} type="small" /> : null}
      </div>
    </>
  );
};

export default MyDropdown;
