import React from "react";
import PageCard from "../../atoms/PageCard";
import Accordion from "../../molecules/accordion";
import Info from "../../icons/Info";
import Gear from "../../icons/Gear";
import Calendar from "../../icons/Calendar";
import User from "../../icons/User";
import Map from "../../icons/Map";
import Exclamation from "../../icons/Exclamation";
import { DateSelector } from "../../molecules/MonthYearSelector";
import MyTextInput from "../../atoms/MyTextInput";

const None = () => {
  const accordionData = [
    {
      title: "Trials Details",
      icon: <Info />,
      content: (
        <>
          <div>
            <MyTextInput type="text" name="Trials" customholder="Trials" />
            <MyTextInput type="text" name="Type " customholder="Type" />
            <div style={{ position: "relative" }}>
              <DateSelector
                placeholder="Start Date"
                // onChange={(option) =>
                //   handleDateChange(option, testType, index, "date2")
                // }
                // selectedDate={testDetail?.date2}
              />
            </div>
            <div style={{ position: "relative" }}>
              <DateSelector
                placeholder="End Date"
                // onChange={(option) =>
                //   handleDateChange(option, testType, index, "date2")
                // }
                // selectedDate={testDetail?.date2}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Trials Conditions and Synopsis",
      icon: <Map />,
      content: (
        <form>
          <label>Field 1:</label>
          <input type="text" />
          <br />
          <label>Field 2:</label>
          <input type="text" />
        </form>
      ),
    },
    {
      title: "Personnel, Equipment and Documentation",
      icon: <User />,
      content: <p>Another section with just text content.</p>,
    },
    {
      title: "General Setup",
      icon: <Gear />,
      content: <p>Another section with just text content.</p>,
    },
    {
      title: "Recommendations",
      icon: <Exclamation />,
      content: <p>Another section with just text content.</p>,
    },
    {
      title: "Trials History",
      icon: <Calendar />,
      content: <p>Another section with just text content.</p>,
    },
  ];

  return (
    <PageCard>
      <Accordion items={accordionData} />
    </PageCard>
  );
};

export default None;
