import React, { useState } from "react";
import { useAuthenticated } from "../hooks/useAuthenticated";
import { useLocation, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";

const AuthGuard = (props) => {
  const [cookies] = useCookies("role");

  const { children, allowed } = props;
  const auth = useAuthenticated();
  const location = useLocation();

  const [requestedLocation, setRequestedLocation] = useState();

  if (!auth) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to="/auth" />;
  }
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
