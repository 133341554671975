import React from "react";
import SimpleAccordion from "../../../../molecules/MyAccordion";

const RedundancyData = [
  {
    title: "Single Line Diagram",
    content: <p>Hiii</p>,
    // count: 10,
  },
  {
    title: "Thrusters Redundancy Concept",
    content: <p>Hiii</p>,
  },
  {
    title: "Redundant Groups",
    content: <p>Hiii</p>,
  },
  {
    title: "Worst Case Failure Design Intent",
    content: <p>Hiii</p>,
  },
];

const RedundancyConcept = () => {
  return (
    <div>
      <SimpleAccordion items={RedundancyData} />
    </div>
  );
};

export default RedundancyConcept;
