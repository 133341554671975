import React from "react";
import MyTextInput from "../../../../atoms/MyTextInput";

const TrialConditions = ({ conditions, setConditions }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    const formData = {
      conditions,
    };
    console.log(formData);
  };
  console.log("conditions---->", conditions);

  return (
    <form onSubmit={handleSubmit}>
      {/* Trial Conditions */}
      <div style={styles.conditionsContainer}>
        {/* First Line: Location and Weather */}
        <div style={styles.row}>
          <div style={styles.inputContainer}>
            <MyTextInput
              type="text"
              name="location"
              customholder="Location"
              value={conditions.location}
              onChange={(e) =>
                setConditions({ ...conditions, location: e.target.value })
              }
            />
          </div>
          <div style={styles.inputContainer}>
            <MyTextInput
              type="text"
              name="weather"
              customholder="Weather"
              value={conditions.weather}
              onChange={(e) =>
                setConditions({ ...conditions, weather: e.target.value })
              }
            />
          </div>
        </div>

        {/* Second Line: Sea State and Water Depth */}
        <div style={styles.row}>
          <div style={styles.inputContainer}>
            <MyTextInput
              type="text"
              name="seaState"
              customholder="Sea State"
              value={conditions.seaState}
              onChange={(e) =>
                setConditions({ ...conditions, seaState: e.target.value })
              }
            />
          </div>
          <div style={styles.inputContainer}>
            <MyTextInput
              type="text"
              name="waterDepth"
              customholder="Water Depth"
              value={conditions.waterDepth}
              onChange={(e) =>
                setConditions({ ...conditions, waterDepth: e.target.value })
              }
            />
          </div>
        </div>

        {/* Third Line: Sea Current */}
        <div style={styles.row}>
          <div style={styles.inputContainer}>
            <MyTextInput
              type="text"
              name="seaCurrent"
              customholder="Sea Current"
              value={conditions.seaCurrent}
              onChange={(e) =>
                setConditions({ ...conditions, seaCurrent: e.target.value })
              }
            />
          </div>
        </div>

        {/* Fourth Line: Limitations */}
        <div style={styles.row}>
          <div style={{ width: "100%" }}>
            <MyTextInput
              type="text"
              name="limitations"
              customholder="Limitations"
              value={conditions.limitations}
              onChange={(e) =>
                setConditions({ ...conditions, limitations: e.target.value })
              }
            />
          </div>
        </div>

        {/* Fifth Line: Remarks */}
        <div style={styles.row}>
          <div style={{ width: "100%" }}>
            <MyTextInput
              type="text"
              name="remarks"
              customholder="Remarks"
              value={conditions.remarks}
              onChange={(e) =>
                setConditions({ ...conditions, remarks: e.target.value })
              }
            />
          </div>
        </div>
      </div>

      {/* Submit Button */}
      {/* <button type="submit" style={styles.submitButton}>
        Submit
      </button> */}
    </form>
  );
};

const styles = {
  conditionsContainer: {
    width: "100%", // Ensures container takes 100% width of parent
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    gap: "16px",
  },
  inputContainer: {
    width: "49%", // Adjusted to fit two inputs in a row
  },
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default TrialConditions;
