import React, { useState } from "react";
import AddBold from "../../../../icons/AddBold";
import Trash from "../../../../icons/Trash";
import Modal from "../../../../organisms/modals/DeleteModal";

const VesselMachinery = ({ vesselMachineryRows, setVesselMachineryRows }) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleAddRow = () => {
    setVesselMachineryRows([
      ...vesselMachineryRows,
      {
        machinery: "",
        power: "",
      },
    ]);
  };

  console.log("rows", vesselMachineryRows);

  const handleDeleteRow = (index) => {
    const newRows = vesselMachineryRows.filter(
      (_, rowIndex) => rowIndex !== index,
    );
    setVesselMachineryRows(newRows);
    setIsModalActive(false); // Close modal after deletion
  };

  // Function to open the modal and set the selected row for deletion
  const confirmDeleteRow = (index) => {
    setSelectedRowIndex(index);
    setIsModalActive(true); // Show modal
  };

  const handleChange = (index, field, value) => {
    const newRows = [...vesselMachineryRows];
    newRows[index][field] = value;
    setVesselMachineryRows(newRows);
  };

  return (
    <div style={{ overflowX: "scroll" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "5%" }}>No.</th>
            <th style={{ width: "45%" }}>Machinery</th>
            <th style={{ width: "45%" }}>Power</th>
          </tr>
        </thead>
        <tbody>
          {vesselMachineryRows.map((row, index) => (
            <tr key={index}>
              <td>
                <b>{`# ${index + 1}`}</b>
              </td>
              <td>
                <input
                  type="text"
                  value={row.machinery}
                  onChange={(e) =>
                    handleChange(index, "machinery", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.power}
                  onChange={(e) => handleChange(index, "power", e.target.value)}
                />
              </td>

              <td>
                <button
                  className="delete-button"
                  onClick={() => confirmDeleteRow(index)}
                >
                  <Trash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleAddRow}>
        <AddBold width="24" height="24" />
      </button>

      {isModalActive && (
        <Modal
          isActive={isModalActive}
          onClose={() => setIsModalActive(false)} // Close modal without deleting
          title="Row"
          name={`Row ${selectedRowIndex + 1}`}
          onClick={() => handleDeleteRow(selectedRowIndex)} // Delete row when Confirm is clicked
          description="Are you sure you want to delete this row?"
        />
      )}

      {/* CSS Styles */}
      <style jsx>{`
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th,
        td {
          padding: 10px;
          text-align: left;
          border: none;
        }
        input {
          border: 1px solid #ccc;
          padding: 5px;
          width: 100%;
          box-sizing: border-box;
        }
        button {
          display: flex; /* Enables flexbox */
          justify-content: center; /* Centers content horizontally */
          align-items: center; /* Centers content vertically */
          margin-top: 10px;
          //   padding: 10px 10px;
          //   background-color: #007bff;
          color: white;
          border: none;
          border-radius: 40px;
          cursor: pointer;
          background: none;
        }

        .delete-button {
          background: none;
          border: none;
          cursor: pointer;
          color: red; /* or your desired color */
          padding: 0;
          margin: 0;
        }

        .delete-button:hover {
          color: darkred; /* Change color on hover */
        }
      `}</style>
    </div>
  );
};

export default VesselMachinery;
