import React, { useState } from "react";
import MyTextArea from "../../../../atoms/MyTextArea";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";

const OperationalDocumentation = () => {
  // State for the form fields
  const [operationalDocumentation, setOperationalDocumentation] = useState({
    vesselSpecificManual: false,
    fieldArrivalsTrials: false,
    dpLogBook: false,
    keyDpPersonnelAccess: false,
    vendorVisitsRecords: false,
  });

  const [operationalDocumentationRemarks, setOperationalDocumentationRemarks] =
    useState("");
  console.log("operationalDocumentation------>", operationalDocumentation);
  console.log(
    "operationalDocumentationRemarks ------>",
    operationalDocumentationRemarks,
  );

  const handleCheckboxChange = (field) => {
    setOperationalDocumentation((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", operationalDocumentation);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={styles.checkboxContainer}>
        <span style={styles.text}>
          Is there a vessel specific DP operations manual as described in IMCA M
          section 3.3 ?
        </span>
        <CheckboxWrapper
          checked={operationalDocumentation.vesselSpecificManual}
          onChange={() => handleCheckboxChange("vesselSpecificManual")}
        />
      </div>
      <hr />

      <div style={styles.checkboxContainer}>
        <span style={styles.text}>
          Are there appropriate checklists covering field arrivals trials,
          location checklists, periodic checks as appropriate for bridge and
          engine room ?
        </span>
        <CheckboxWrapper
          checked={operationalDocumentation.fieldArrivalsTrials}
          onChange={() => handleCheckboxChange("fieldArrivalsTrials")}
        />
      </div>
      <hr />

      <div style={styles.checkboxContainer}>
        <span style={styles.text}>Is a DP log book kept up to date ?</span>
        <CheckboxWrapper
          checked={operationalDocumentation.dpLogBook}
          onChange={() => handleCheckboxChange("dpLogBook")}
        />
      </div>
      <hr />

      <div style={styles.checkboxContainer}>
        <span style={styles.text}>
          Do the key DP personnel have access to appropriate IMCA guidance
          documents either via Internet or hard copy ?
        </span>
        <CheckboxWrapper
          checked={operationalDocumentation.keyDpPersonnelAccess}
          onChange={() => handleCheckboxChange("keyDpPersonnelAccess")}
        />
      </div>
      <hr />

      <div style={styles.checkboxContainer}>
        <span style={styles.text}>
          Are records available for vendor's visits for repair or service of the
          system components ?
        </span>
        <CheckboxWrapper
          checked={operationalDocumentation.vendorVisitsRecords}
          onChange={() => handleCheckboxChange("vendorVisitsRecords")}
        />
      </div>
      <hr />

      <div style={styles.row}>
        <div style={styles.inputContainer}>
          <MyTextArea
            name="operationalDocumentationRemarks"
            customholder="Operational Documentation Remarks"
            value={operationalDocumentationRemarks}
            type="textarea"
            onChange={(e) => setOperationalDocumentationRemarks(e.target.value)}
          />
        </div>
      </div>
    </form>
  );
};

const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    gap: "16px",
  },
  text: {
    fontSize: "14px",
    marginRight: "5px",
  },
  inputContainer: {
    width: "100%",
  },
  checkboxContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginBottom: "16px",
  },
};

export default OperationalDocumentation;
