export const capitalize = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
export const capitalizeLetter = (str) => {
  return str?.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const isValidBase64 = (str) => {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

export const decodeCookieValue = (cookieValue) => {
  if (cookieValue && isValidBase64(cookieValue)) {
    return atob(cookieValue);
  }
  return null;
};
