import { Box } from "@mui/material";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";
import TabStrip from "../../../molecules/tabstrip";
import { useState } from "react";
import UserManagement from "./tabs/UserManagement";
import Prompt from "./tabs/Prompt";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("User Management");

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };
  const _getComponet = (tabs) => {
    return data
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };
  const data = [
    {
      id: 0,
      label: "User Management",
      route: "user-management",
      view: <UserManagement />,
    },
    {
      id: 1,
      label: "AMCA Logs",
      route: "amca-logs",
      view: <Prompt />,
    },
  ];

  return (
    <>
      <PageHeader title="Settings" />
      <PageCard classes="p-3 pt-0">
        <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ p: 2 }}>{_getComponet(activeTab)}</Box>
        </Box>
      </PageCard>
    </>
  );
};
export default Settings;
