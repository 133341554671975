import React from "react";
import { components } from "react-select";

const CustomOption = (props) => {
  const { data, removeCallback } = props;

  const handleRemove = (e) => {
    e.stopPropagation(); // Prevent the dropdown from closing
    if (removeCallback) {
      removeCallback(data.value); // Trigger the callback with the option's value
    }
  };

  return (
    <components.Option {...props}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>{props.label}</span>
        {removeCallback && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "whitesmoke",
              borderRadius: "50%",
              width: "24px",
              height: "24px",
              cursor: "pointer",
              transition: "background-color 0.3s",
              position: "relative",
            }}
            onClick={handleRemove}
          >
            <span
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "18px",
                alignItems: "center",
                marginTop: "-2px",
              }}
            >
              &times;
            </span>
          </div>
        )}
      </div>
    </components.Option>
  );
};

export default CustomOption;
