const SmallArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.9998 15.5C17.8682 15.5007 17.7377 15.4755 17.6159 15.4258C17.4941 15.376 17.3833 15.3027 17.2898 15.21L12.7098 10.62C12.6169 10.5263 12.5063 10.4519 12.3844 10.4011C12.2625 10.3503 12.1318 10.3242 11.9998 10.3242C11.8678 10.3242 11.7371 10.3503 11.6152 10.4011C11.4934 10.4519 11.3828 10.5263 11.2898 10.62L6.70982 15.21C6.52152 15.3983 6.26612 15.5041 5.99982 15.5041C5.73352 15.5041 5.47812 15.3983 5.28982 15.21C5.10152 15.0217 4.99573 14.7663 4.99573 14.5C4.99573 14.3681 5.0217 14.2376 5.07216 14.1157C5.12262 13.9939 5.19658 13.8832 5.28982 13.79L9.87982 9.21C10.4498 8.66243 11.2095 8.35663 11.9998 8.35663C12.7902 8.35663 13.5499 8.66243 14.1198 9.21L18.7098 13.79C18.8035 13.883 18.8779 13.9936 18.9287 14.1154C18.9795 14.2373 19.0056 14.368 19.0056 14.5C19.0056 14.632 18.9795 14.7627 18.9287 14.8846C18.8779 15.0064 18.8035 15.117 18.7098 15.21C18.6164 15.3027 18.5056 15.376 18.3837 15.4258C18.2619 15.4755 18.1314 15.5007 17.9998 15.5Z"
        fill="white"
      />
    </svg>
  );
};
export default SmallArrowUp;
