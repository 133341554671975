import { Field, Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import Error from "../../../../atoms/Error";
import { postData } from "../../../../../services";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { expitationTime } from "../../../../../helpers/expirationTime";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/toast";
import { errorStyles } from "../../../../molecules/Dropdown/dropdown";

const SurveyLoginForm = ({ surveyId }) => {
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies(["uid", "email", "role"]);
  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const lowercaseEmail = values.email.toLowerCase();
    const updatedValues = {
      ...values,
      email: lowercaseEmail,
      survey_id: surveyId,
    };
    try {
      const { data: resultData } = await postData({
        endpoint: "/shareSurvey/survey/login",
        data: {},
        params: updatedValues,
      });
      if (resultData.status && resultData.response === 200) {
        setCookie("survey_id", surveyId, {
          path: "/",
          expires: expitationTime(),
        });
        setCookie("email", values.email.toLowerCase(), {
          path: "/",
          expires: expitationTime(),
        });
        localStorage.setItem("countdownStartTime", "30");
        navigate(`/survey/auth/verifyotp/${surveyId}`);
        toast.success(resultData.message, {
          duration: 1000,
          style: confimationStyles,
        });
      } else {
        toast.error("Invalid Email or Password", {
          duration: 1000,
          style: errorStyles,
        });
      }
    } catch (err) {
      toast.error("please try again", {
        duration: 1000,
        style: errorStyles,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange
        validateOnBlur
      >
        {({
          isValid,
          dirty,
          handleSubmit,
          isSubmitting,
          touched,
          errors,
          values,
          setFieldValue,
          setFieldTouched,
          handleBlur,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email</label>

              <Field
                type="text"
                name="email"
                placeholder="Enter Email Address"
                className={`${
                  touched.email && errors.email ? "error-input" : ""
                }`}
                autoComplete="off"
                onInput={(e) => {
                  // Remove spaces while typing
                  e.target.value = e.target.value.replace(/\s/g, "");
                }}
              />
              {touched.email && errors.email && <Error error={errors.email} />}
            </div>
            <button
              className="btn btn-primary w-100 mt-4"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Loading" : "Login"}
              {isSubmitting && (
                <span
                  className="spinner-border spinner-border-sm ms-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};
export default SurveyLoginForm;
