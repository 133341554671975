const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6784 3.33333C10.3227 3.33333 10.0343 3.63181 10.0343 4C10.0343 4.36819 10.3227 4.66667 10.6784 4.66667C11.0342 4.66667 11.3226 4.36819 11.3226 4C11.3226 3.63181 11.0342 3.33333 10.6784 3.33333ZM8.74609 4C8.74609 2.89543 9.61123 2 10.6784 2C11.7456 2 12.6108 2.89543 12.6108 4C12.6108 5.10457 11.7456 6 10.6784 6C9.61123 6 8.74609 5.10457 8.74609 4Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.52513 7.33333C5.16939 7.33333 4.88101 7.6318 4.88101 8C4.88101 8.3682 5.16939 8.66667 5.52513 8.66667C5.88087 8.66667 6.16925 8.3682 6.16925 8C6.16925 7.6318 5.88087 7.33333 5.52513 7.33333ZM3.59277 8C3.59277 6.8954 4.45792 6 5.52513 6C6.59234 6 7.45745 6.8954 7.45745 8C7.45745 9.1046 6.59234 10 5.52513 10C4.45792 10 3.59277 9.1046 3.59277 8Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6784 11.3333C10.3227 11.3333 10.0343 11.6318 10.0343 12C10.0343 12.3682 10.3227 12.6667 10.6784 12.6667C11.0342 12.6667 11.3226 12.3682 11.3226 12C11.3226 11.6318 11.0342 11.3333 10.6784 11.3333ZM8.74609 12C8.74609 10.8954 9.61123 10 10.6784 10C11.7456 10 12.6108 10.8954 12.6108 12C12.6108 13.1046 11.7456 14 10.6784 14C9.61123 14 8.74609 13.1046 8.74609 12Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.30469 4.00065C2.30469 3.63246 2.59307 3.33398 2.94881 3.33398H9.39C9.74573 3.33398 10.0341 3.63246 10.0341 4.00065C10.0341 4.36884 9.74573 4.66732 9.39 4.66732H2.94881C2.59307 4.66732 2.30469 4.36884 2.30469 4.00065Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.16895 8.00065C6.16895 7.63245 6.45732 7.33398 6.81309 7.33398H13.2542C13.61 7.33398 13.8984 7.63245 13.8984 8.00065C13.8984 8.36885 13.61 8.66732 13.2542 8.66732H6.81309C6.45732 8.66732 6.16895 8.36885 6.16895 8.00065Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.30469 8.00065C2.30469 7.63245 2.59307 7.33398 2.94881 7.33398H4.23704C4.59277 7.33398 4.88116 7.63245 4.88116 8.00065C4.88116 8.36885 4.59277 8.66732 4.23704 8.66732H2.94881C2.59307 8.66732 2.30469 8.36885 2.30469 8.00065Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3223 4.00065C11.3223 3.63246 11.6107 3.33398 11.9664 3.33398H13.2546C13.6103 3.33398 13.8987 3.63246 13.8987 4.00065C13.8987 4.36884 13.6103 4.66732 13.2546 4.66732H11.9664C11.6107 4.66732 11.3223 4.36884 11.3223 4.00065Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3223 12.0007C11.3223 11.6325 11.6107 11.334 11.9664 11.334H13.2546C13.6103 11.334 13.8987 11.6325 13.8987 12.0007C13.8987 12.3689 13.6103 12.6673 13.2546 12.6673H11.9664C11.6107 12.6673 11.3223 12.3689 11.3223 12.0007Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.30469 12.0007C2.30469 11.6325 2.59307 11.334 2.94881 11.334H9.39C9.74573 11.334 10.0341 11.6325 10.0341 12.0007C10.0341 12.3689 9.74573 12.6673 9.39 12.6673H2.94881C2.59307 12.6673 2.30469 12.3689 2.30469 12.0007Z"
        fill="black"
      />
    </svg>
  );
};
export default FilterIcon;
