import { InputAdornment, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "./datepicker.scss";
import { useEffect, useRef, useState } from "react";

const datePickerStyle = {
  height: "52px", // Set your desired height
  backgroundColor: "lightblue", // Set your desired background color
  border: "2px solid darkblue", // Set your desired border
};

// ... (imports remain the same)

export function MonthYearSelector({
  onChange,
  placeholder = "Select",
  selectedDate,
  disableFuture = false,
  disablePast = false,
  minDateData,
  maxDateData,
  handleBlur,
  // IsOpen,
  // setIsOpen
}) {
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date.format("YYYY-MM"));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="calender-input-component" tabIndex={0}>
        <DatePicker
          disableFuture={disableFuture}
          disablePast={disablePast}
          openTo="month"
          views={["year", "month"]}
          style={datePickerStyle}
          onClose={handleBlur}
          onOpen={() => window.alert("heleo")}
          // open={IsOpen}
          minDate={minDateData ? dayjs(minDateData) : undefined} // Use minDate if available
          maxDate={maxDateData ? dayjs(maxDateData) : undefined} // Use minDate if available
          onChange={handleDateChange}
          value={selectedDate ? dayjs(selectedDate) : null} // Set the initially selected date
          slotProps={{
            textField: { placeholder: `${placeholder}`, onBlur: handleBlur },
            field: {
              readOnly: true,
              onBlur: handleBlur,
            },
          }}
          // sx={{
          //   "&. MuiTextField-root": {
          //     width: "100% !important",
          //   },
          //   "&. MuiFormControl-root-MuiTextField-root": {
          //     width: "100% !important",
          //   },
          // }}
          className="w-100"
        />
      </div>
    </LocalizationProvider>
  );
}

export function DateSelector({
  onChange,
  placeholder = "Select Date",
  disableFuture = false,
  disablePast = false,
  disabled = false,
  selectedDate,
  minDateData,
  maxDateData,
  handleBlur,
  touched,
  errors,
}) {
  const [isFocused, setIsFocused] = useState(selectedDate ? true : false);
  const datePickerRef = useRef(null);
  const calendarRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleDateChange = async (date) => {
    if (onChange) {
      const isoDate = date.format("YYYY-MM-DDTHH:mm:ss"); // Convert to ISO format
      onChange(isoDate + "Z");
      setIsFocused(true);
    }
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlurFocus = () => {
    if (!selectedDate) {
      setIsFocused(false);
    }
  };

  const handleOpen = () => {
    if (!disabled && !open) {
      setOpen((prev) => !prev);
    }
  };

  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target) &&
      calendarRef.current &&
      !calendarRef.current.contains(event.target)
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <div className="dateSelector" ref={datePickerRef}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          className={`calender-input-component ${
            touched && errors ? "error-container" : ""
          }`}
          tabIndex={0}
          style={{ position: "relative" }}
          onFocus={handleFocus}
          onBlur={handleBlurFocus}
          onClick={handleOpen}
        >
          <DatePicker
            disableFuture={disableFuture}
            disablePast={disablePast}
            // format="YYYY-MM-DD"
            format="DD-MM-YYYY" // Updated format to "DD MM YYYY"
            style={datePickerStyle}
            slotProps={{
              textField: { placeholder: `${""}`, onBlur: handleBlur },
              // field: {
              //   readOnly: true,
              // },
            }}
            onChange={handleDateChange}
            open={open}
            disabled={disabled}
            value={selectedDate ? dayjs(selectedDate) : null}
            minDate={minDateData ? dayjs(minDateData) : undefined}
            maxDate={maxDateData ? dayjs(maxDateData) : undefined}
            // closeOnSelect={true}
            // desktopModeMediaQuery="@media (pointer: fine)"
            // onAccept={() => setOpen(false)}
            onOpen={() => {
              setIsFocused(false);
              // handleOpen();
            }}
            onClose={handleClose}
            className="w-100"
            PopperProps={{
              ref: calendarRef,
            }}
          />
          <label
            className={`trydate ${
              isFocused || selectedDate ? "active" : "place-holder-center"
            }`}
          >
            {placeholder}
          </label>
        </div>
        {/* {touched && errors && (
          <div>
            <Error error={errors} type="small" />{" "}
          </div>
        )} */}
      </LocalizationProvider>
    </div>
  );
}
