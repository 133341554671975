import { useParams } from "react-router-dom";
import logo from "../../../../assets/branding/amcaLogo.png";
import SurveyLoginForm from "./Form";
import { useEffect, useState } from "react";
import { getData } from "../../../../services";
import moment from "moment/moment";
const SurveyLogin = () => {
  const { id } = useParams();
  const [shareSurveyData, setShareSurveyData] = useState();
  const [isLinkValid, setIsLinkValid] = useState();
  const [surveyData, setSurveyData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getShareSurveyDetails = async () => {
    try {
      setIsLoading(true);
      const res = await getData({
        endpoint: "shareSurvey/getShareSurveyBySurveyId",
        params: {
          survey_id: id,
        },
      });
      setShareSurveyData(res?.data);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getShareSurveyDetails();
  }, []);

  useEffect(() => {
    if (shareSurveyData?.survey_id) {
      getSurveyDetails();
    }
  }, [shareSurveyData]);

  const getSurveyDetails = async () => {
    try {
      setIsLoading(true);
      const res = await getData({
        endpoint: "survey/getSurveySpecificDataById",
        params: {
          id: shareSurveyData?.survey_id,
        },
      });
      setSurveyData(res?.data?.[0]);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (shareSurveyData?.createdAt) {
      const expirationDate = new Date(shareSurveyData?.link_expire);
      const expireOneDayAfter = new Date(
        expirationDate.getTime() + 24 * 60 * 60 * 1000,
      );

      const currentDate = new Date();
      const isExpired = currentDate > expireOneDayAfter;

      if (isExpired) {
        setIsLinkValid(false);
      } else if (surveyData?.status === "Submitted") {
        setIsLinkValid(false);
      } else if (surveyData?.status === "Submitted" && isExpired) {
        setIsLinkValid(false);
      } else {
        setIsLinkValid(true);
      }
    }
  }, [shareSurveyData]);

  return (
    <>
      {isLoading ? (
        <>
          <div
            class="spinner-border text-primary"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span class="visually-hidden"></span>
          </div>
        </>
      ) : (
        <>
          {isLinkValid ? (
            <>
              <div className="w-100 d-flex justify-content-left">
                <img
                  src={logo}
                  alt=""
                  style={{ width: "50%", marginBottom: "20px" }}
                />
              </div>
              <div className="h1-i mb-4">
                Login to AMCA Marine Checklist Platform
              </div>
              <SurveyLoginForm surveyId={id} />
            </>
          ) : (
            <>
              <div className="h1-i mb-4">Link has been expired</div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default SurveyLogin;
