const Tools = ({ className = "", width = "16", height = "16" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      height="18"
      width="18"
    >
      <path d="m10.54,15.49c.596.595.596,1.561,0,2.157l-5.508,5.508c-1.188,1.188-3.138,1.15-4.278-.115-1.068-1.186-.901-3.043.227-4.171l5.39-5.39c.595-.595,1.561-.596,2.156,0l2.012,2.012Zm5.412-4.487c1.263.002,2.544.501,3.487,1.445.561.552,1.313.446,1.561.275,1.819-1.259,3.018-3.343,3.018-5.723,0-.413-.037-.817-.106-1.21-.136-.768-1.107-1.041-1.659-.49l-1.647,1.647c-1.146,1.146-2.653,1.322-3.665.494-1.164-.952-1.228-2.672-.191-3.709l1.965-1.965c.551-.552.281-1.526-.487-1.662-.393-.07-.797-.106-1.21-.106-2.943,0-5.455,1.818-6.489,4.391-.207.514-.086,1.105.306,1.496l5.117,5.116Zm2.073,2.859c-.761-.761-1.831-1.007-2.804-.761l-7.222-7.221v-1.881c0-.752-.402-1.448-1.054-1.824L3.539.211C2.921-.146,2.14-.043,1.635.462L.463,1.634C-.043,2.139-.146,2.921.211,3.539l1.965,3.407c.376.652,1.071,1.053,1.823,1.053h1.878l7.224,7.223c-.245.972,0,2.042.761,2.803l5.057,5.003c1.147,1.147,3.128,1.256,4.274.108,1.148-1.15,1.003-2.867-.001-4.162l-5.166-5.112Z" />
    </svg>
  );
};

export default Tools;
