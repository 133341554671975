import React, { useState } from "react";
import MyDropdown from "../../../../atoms/MyDropdown"; // Reusing MyDropdown component
import MyTextInput from "../../../../atoms/MyTextInput";

const DPStation = () => {
  // State for form fields
  const [eventsRecorded, setEventsRecorded] = useState("");
  const [inputField, setInputField] = useState("");

  console.log("eventsRecorded", eventsRecorded);
  console.log("inputField", inputField);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    const formData = {
      eventsRecorded,
      inputField,
    };
    console.log(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* First Row: Hardware Modification Dropdown */}
      <div style={styles.row}>
        <div style={styles.fullWidth}>
          <MyDropdown
            placeholder="Have any DP station keeping events being recorded ?"
            data={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
            format="all"
            menuOpen={true}
            name="eventsRecorded"
            width="100%"
            selectedValue={eventsRecorded}
            onChange={(options) => setEventsRecorded(options?.value)}
          />
        </div>
      </div>
      <MyTextInput
        type="textarea"
        name="inputField"
        customholder="Is there a documented satisfactory explanation? Give brief details as appropriate"
        value={inputField}
        onChange={(e) => setInputField(e.target.value)}
      />

      {/* Submit Button */}
      {/* <button type="submit" style={styles.submitButton}>
        Submit
      </button> */}
    </form>
  );
};

// Styles
const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    gap: "16px",
  },
  fullWidth: {
    width: "100%",
  },
  halfWidth: {
    width: "50%",
  },
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default DPStation;
