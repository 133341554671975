const AddBold = ({ width = "16", height = "16" }) => {
  return (
    <svg
      id="Layer_1"
      height="28"
      viewBox="0 0 24 24"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      fill="#007bff"
    >
      <path d="m12 0a12 12 0 1 0 12 12 12.013 12.013 0 0 0 -12-12zm4 13h-3v3a1 1 0 0 1 -2 0v-3h-3a1 1 0 0 1 0-2h3v-3a1 1 0 0 1 2 0v3h3a1 1 0 0 1 0 2z" />
    </svg>
  );
};

export default AddBold;
