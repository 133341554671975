import React, { useState } from "react";
import "./style.scss";
import Error from "../../atoms/Error";
import Info from "../Info";

const MyTextInput = ({
  label,
  icon,
  InputHeight = "small",
  info,
  customBlur,
  blurError,
  width,
  customholder,
  value, // Use the value prop passed from the parent
  onChange, // Use the onChange prop passed from the parent
  ...props
}) => {
  const [touched, setTouched] = useState(false);
  const [placeholder, setPlaceholder] = useState(customholder);

  const handleFocus = () => {
    setPlaceholder(
      customholder.substring(0, 5) === "Enter"
        ? customholder.substring(5)
        : customholder,
    );
  };

  const handleBlur = (event) => {
    setTouched(true);
    if (event.target.value === "") {
      setPlaceholder(customholder);
    }
    if (customBlur) customBlur();
  };

  return (
    <>
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      <div
        className={`d-flex align-center input-container input_icon_wrap`}
        style={{ width: width }}
      >
        {icon && <div className={`input_icon`}>{icon}</div>}
        <input
          {...props}
          value={value} // Use the value prop directly
          onChange={(e) => {
            if (onChange) onChange(e); // Call the onChange function passed from the parent
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`${touched && !value && blurError ? "error-input " : ""}${
            InputHeight === "small" ? "primary-input-small" : "primary-input"
          } `}
          placeholder=""
        />
        <label
          className={`${
            InputHeight === "small"
              ? "input-placeholder-small"
              : "input-placeholder"
          }`}
        >
          {placeholder}
        </label>
      </div>

      {info && !blurError ? <Info info={info} /> : null}
      {touched && blurError ? (
        <Error error={blurError} type={InputHeight} />
      ) : null}
    </>
  );
};

export default MyTextInput;
