const ArrowRigth = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
    >
      <path
        d="M1 1L7 7.02037L1 13.0407"
        stroke="#4E5660"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default ArrowRigth;
