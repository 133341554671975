// export const renderInputOrText = (
//   placeholderText,
//   fieldValue,
//   roleType,
//   onChange,
// ) => {
//   return roleType === "Surveyor" ? (
//     <input
//       type="text"
//       placeholder={placeholderText}
//       className="dynamic-input"
//       onChange={(e) => {
//         onChange(e.target.value); // Pass only the new value
//       }}
//     />
//   ) : (
//     <span className="static-text">
//       <u>{placeholderText}</u>
//     </span>
//   );
// };
export const renderInputOrText = (
  placeholderText,
  fieldValue,
  roleType,
  onChange,
) => {
  return roleType === "Surveyor" ? (
    <input
      key={placeholderText}
      id={placeholderText}
      type="text"
      value={fieldValue} // Use the value from the state
      name={placeholderText}
      placeholder={placeholderText}
      className="dynamic-input"
      onChange={(e) => onChange(e.target.value)} // Pass the new value
    />
  ) : (
    <span className="static-text">
      <u>{placeholderText}</u>
    </span>
  );
};
