import React from "react";
import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <span>
        Copyright © 2024 |{" "}
        <a href="https://amcamarine.com/" target="_blank" class={styles.blue}>
          AMCA Marine
        </a>{" "}
        All rights reserved.{" "}
      </span>
    </footer>
  );
};

export default Footer;
