import React, { useState } from "react";
import Accordion from "../../../../molecules/accordion";
import Calendar from "../../../../icons/Calendar";
import Exclamation from "../../../../icons/Exclamation";
import Gear from "../../../../icons/Gear";
import User from "../../../../icons/User";
import Map from "../../../../icons/Map";
import Info from "../../../../icons/Info";
import TrialDetails from "./trialsDetails";
import TrialConditions from "./trialsConditions";
import PersonnelEquipment from "./personnelEquipment";
import Checklist from "../../../../icons/Checklist";

const AddTrials = () => {
  // State for Trial Details
  const [trialDetails, setTrialDetails] = useState({
    trials: "",
    vesselName: "",
    rollingScheme: false,
    type: "",
    startDate: null,
    endDate: null,
  });

  // State for Trials Conditions and Synopsis
  const [conditions, setConditions] = useState({
    location: "",
    weather: "",
    seaState: "",
    waterDepth: "",
    seaCurrent: "",
    limitations: "",
    remarks: "",
  });

  // Sate for Machine Machineries
  const [machineMachineriesRows, setMachineMachineriesRows] = useState([
    {
      engine: "Engine 1",
      maintenanceChecked: "Yes",
      outstandingMaintenance: "None",
      lastOilAnalysis: "2024-01-01",
      lastOilAnalysisResults: "Good",
      runningHours: "500",
      lastMajorOverhaul: "2023-01-01",
    },
    {
      engine: "Engine 2",
      maintenanceChecked: "No",
      outstandingMaintenance: "Check ASAP",
      lastOilAnalysis: "2024-02-01",
      lastOilAnalysisResults: "Needs Attention",
      runningHours: "300",
      lastMajorOverhaul: "2022-01-01",
    },
    {
      engine: "Engine 3",
      maintenanceChecked: "Yes",
      outstandingMaintenance: "None",
      lastOilAnalysis: "2024-01-01",
      lastOilAnalysisResults: "Good",
      runningHours: "500",
      lastMajorOverhaul: "2023-01-01",
    },
    {
      engine: "Engine 4",
      maintenanceChecked: "No",
      outstandingMaintenance: "Check ASAP",
      lastOilAnalysis: "2024-02-01",
      lastOilAnalysisResults: "Needs Attention",
      runningHours: "300",
      lastMajorOverhaul: "2022-01-01",
    },
    {
      engine: "Engine 5",
      maintenanceChecked: "Yes",
      outstandingMaintenance: "None",
      lastOilAnalysis: "2024-01-01",
      lastOilAnalysisResults: "Good",
      runningHours: "500",
      lastMajorOverhaul: "2023-01-01",
    },
    {
      engine: "Engine 6",
      maintenanceChecked: "No",
      outstandingMaintenance: "Check ASAP",
      lastOilAnalysis: "2024-02-01",
      lastOilAnalysisResults: "Needs Attention",
      runningHours: "300",
      lastMajorOverhaul: "2022-01-01",
    },
  ]);
  const [machineMachineriesRemarks, setMachineMachineriesRemarks] =
    useState(""); // State for input field

  const accordionData1 = [
    {
      title: "Trials Details",
      icon: <Info />,
      content: (
        <TrialDetails
          trialDetails={trialDetails}
          setTrialDetails={setTrialDetails}
        />
      ),
    },
    {
      title: "Trials Conditions and Synopsis",
      icon: <Map />,
      content: (
        <TrialConditions
          conditions={conditions}
          setConditions={setConditions}
        />
      ),
    },
    {
      title: "Personnel, Equipment and Documentation",
      icon: <User />,
      content: (
        <PersonnelEquipment
          machineMachineriesRows={machineMachineriesRows}
          setMachineMachineriesRows={setMachineMachineriesRows}
          machineMachineriesRemarks={machineMachineriesRemarks}
          setMachineMachineriesRemarks={setMachineMachineriesRemarks}
        />
      ),
      removePadding: true,
    },
    {
      title: "General Setup",
      icon: <Gear />,
      content: <p>Another section with just text content.</p>,
    },
    {
      title: "Recommendations",
      icon: <Exclamation />,
      content: <p>Another section with just text content.</p>,
    },
    {
      title: "Trials History",
      icon: <Calendar />,
      content: <p>Another section with just text content.</p>,
    },
  ];
  const accordionData2 = [
    {
      title: "Test Details",
      icon: <Info />,
      content: <TrialDetails />,
    },
    {
      title: "Test Procedure",
      icon: <Checklist />,
      content: <TrialConditions />,
    },
  ];

  return (
    <>
      <Accordion items={accordionData1} />
      <div style={{ marginTop: "20px" }}></div>
      {/* <Accordion items={accordionData2} /> */}
    </>
  );
};

export default AddTrials;
